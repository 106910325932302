import React, { memo, useState } from "react";
import { TableRow, TableCell, TableBody, FormControl, MenuItem, Select, Box } from "@mui/material";
import Chip from "../../../styled/Chip/Chip";

function SelectInput({ fileOptions, onChangeSelect, fieldName, currentValue, name }) {
  const [selected, setSelected] = useState(currentValue);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelected(value);
    onChangeSelect({
      id: name,
      target: value,
    })
  };

  return (
    <Box className={`input-container ${selected === currentValue ? "selected" : ""}`}>
      <FormControl>
        <Select
          name={fieldName}
          value={selected}
          displayEmpty
          fullWidth
          defaultValue=""
          onChange={handleChange}
          sx={{ width: 350 }}
        >
          {fileOptions && fileOptions.map(({ name }) => {
            const nameChange = name.replace(/([A-Z])/g, " $1").replace('_', ' ');
            const finalValue = nameChange.charAt(0).toUpperCase() + nameChange.slice(1).replace('_', ' ');

            return (
              <MenuItem
                key={name}
                name={`map-${fieldName}`}
                value={name}
              >
                {finalValue}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

function CustomRow({ row, modifiedData, onChangeSelect }) {  
  const nameChange = row.label.replace(/([A-Z])/g, " $1");
  let finalName = nameChange.charAt(0).toUpperCase() + nameChange.slice(1);

  if (finalName === "State" || finalName === "Loan Number") {
    finalName = finalName + "*";
  }

  return (
    <TableRow style={{height: 20}}>
      <TableCell align="left" style={{ width: 300 }}>
        {finalName}
      </TableCell>
      <TableCell align="left" style={{ width: 150 }}>
        <Chip label={row.example} />
      </TableCell>
      <TableCell align="left" style={{ width: 400 }}>
        <SelectInput
          fileOptions={modifiedData}
          name={row.label}
          onChangeSelect={onChangeSelect}
          fieldName={row.label}
          currentValue={row.value}
          key={row.label}
        />
      </TableCell>
    </TableRow>
  );
}

function TBody({ tableData, fieldOptions, onChangeSelect }) {
  const modifiedData = tableData.map((item) => ({...item})).filter((field) => field.name !== "hasApi" && field.name !== "bkstatus");
  return (
    <TableBody>
      {fieldOptions.filter((row) => row.label !== 'none').map((row, index) => (
        <CustomRow
          key={index}
          row={row}
          modifiedData={modifiedData}
          onChangeSelect={onChangeSelect}
          style={{height: '10px'}}
        />
      ))}
    </TableBody>
  );
}

export default memo(TBody);

import React from "react";
import styled from "@emotion/styled";

const StyledLabel = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 9px;
`;

export default function Label({ children }) {
  return <StyledLabel>{children}</StyledLabel>;
}

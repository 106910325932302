import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

export const GET_STRIPE_DETAILS = gql`
  query getStripeDetailsCurUser {
    getStripeDetailsCurUser{
      paymentMethods{
        id
        brand
        last4
        exp_month
        exp_year
      }
      paymentIntents{
        date
        amount
        status
        currency
      }
    }
  }
`;

const REMOVE_PAYMENT_METHOD = gql`
  mutation removePaymentMethod($paymentMethodID: ID!) {
    removePaymentMethod(paymentMethodID: $paymentMethodID) {
      id
      brand
      last4
      exp_month
      exp_year
    }
  }
`;

export const BILLINGS_QUERY = gql`
  query GetBillings {
    billings {
      maxLoans
      month
      year
      created_at
      assets {
        loanNumber
      }
    }
  }
`;

export default function Billing() {
  const { data } = useQuery(GET_STRIPE_DETAILS);
  const [removePaymentMethod] = useMutation(REMOVE_PAYMENT_METHOD);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentIntents, setPaymentIntents] = useState([]);
  const [billings, setBillings] = useState([]);
  const billingsQuery = useQuery(BILLINGS_QUERY);

  useEffect(() => {
    if (!data || !billingsQuery.data) return;
    setPaymentMethods(data.getStripeDetailsCurUser.paymentMethods)
    setPaymentIntents(data.getStripeDetailsCurUser.paymentIntents)
    setBillings(billingsQuery.data.billings)
  }, [data, billingsQuery.data]);

  async function deletePaymentMethod(paymentMethodID) {
    if (paymentMethods.length < 2){
      alert("You need to have at least 1 card left.")
      return;
    }
    const pms = await removePaymentMethod({
      variables: { paymentMethodID: paymentMethodID },
    });
    setPaymentMethods(pms.data.removePaymentMethod)
  }

  return (
    <div>
      <h2>Payment History</h2>
      <ul>
        {paymentIntents.map((pi) =>
          <li
            key={pi.amount}
          >{pi.status} {"=>"} {pi.amount/100}{pi.currency} - Payment Made: {new Date(pi.date * 1000).toDateString()}</li>
        )}
      </ul>

      <h2>Payment Methods</h2>
      <ul>
        {paymentMethods.map((pm) =>
          <li
            key={pm.id}
          >{pm.brand} {"=>"} {pm.last4} - Expires on: {pm.exp_month}{"/"}{pm.exp_year} <button onClick={() => deletePaymentMethod(pm.id)}>delete</button></li>
        )}
      </ul>

      <h2>Loans to be billed for</h2>
      <ul>
        {billings.map((billing) =>
          <li
            key={billing.created_at}
          >{`${billing.month}/${billing.year} => ${billing.maxLoans} Loans (${billing.assets.map(ba => ba.loanNumber)})`} </li>
        )}
      </ul>
    </div>
  )

}
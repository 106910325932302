import React, { useState, useContext, useRef } from "react";
import { Box, TextField, List, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { gql, useMutation } from "@apollo/client";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { REGISTER_PORTFOLIO_USER } from "../../apollo/mutations/registerPortfolioUser";
import Button from "../../styled/Button/Button";
import Drawer from "../../styled/Drawer/Drawer";
import Rating from "../../styled/Rating/Rating";
import { passwordStrength } from 'check-password-strength';
import { containsNumber, containsLowerCase, containsUpperCase, containsSpecialChars } from "../../utilities";
import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { MessageContext } from "../../context/MessageContext";

export const GET_USER_INFO = gql`
  query GET_USER_INFO($id: ID!) {
    getUserInfo(id: $id) {
      id
      firstName
      lastName
      userType
      tncStatus
      paymentMethodAdded
    }
  }
`;

const INITIAL_FORM_STATE = {
  companyName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const INITIAL_ERROR_STATE = {
  companyName: false,
  firstName: false,
  lastName: false,
  email: false,
  password: false,
};


const errorMessages = {
  companyName: "Please provide a company name.",
  firstName: "Please provide a first name.",
  lastName: "Please provide a last name.",
  email: "Please provide a valid email address.",
  password: "Please provide a valid password.",
};

const ListIcon = styled(ListItemIcon)`
  min-width: 30px;
`
const Input = styled(TextField)`
  margin: 5% 0;
  height: 2em;
`

function RegisterForm() {
  const captchaRef = useRef(null);
  const { addMessage } = useContext(MessageContext);
  
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [captchaError, setCaptchaError] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaHasValue, setCaptchaHasValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const [confirmEmailMessage, setConfirmEmailMessage] = useState("");
  const [signupSucceeded, setSignupSucceeded] = useState(false);

  const [registerUserAndCreatePortfolio, { error, loading }] = useMutation(
    REGISTER_PORTFOLIO_USER
  );

  function formValidation() {
    let hasError = false;

    if (formData.companyName.length === 0) {
      setFormError((prevState) => ({ ...prevState, companyName: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, companyName: false }));
    }

    if (formData.firstName.length === 0) {
      setFormError((prevState) => ({ ...prevState, firstName: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, firstName: false }));
    }

    if (formData.lastName.length === 0) {
      setFormError((prevState) => ({ ...prevState, lastName: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, lastName: false }));
    }

    if (formData.email.length === 0) {
      setFormError((prevState) => ({ ...prevState, email: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, email: false }));
    }

    if (formData.password.length < 5) {
      setFormError((prevState) => ({ ...prevState, password: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, password: false }));
    }

    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // First, check reCAPTCHA
    if (process.env.NODE_ENV !== 'development' 
    && process.env.NODE_ENV !== 'test') {
      setLoadingCaptcha(true);
      const token = captchaRef.current.getValue();

      if (!token) {
        setCaptchaError("You need to complete the reCAPTCHA check before continuing.");
        addMessage({message: "You need to complete the reCAPTCHA check before continuing.", type: "error"});
        setLoadingCaptcha(false);
        return;
      } else {
        setCaptchaError("");
      }
      
      await axios.post(`${process.env.REACT_APP_NEXT_IMPORT_API_URL}/api/captcha`, {token})
        .then(res => {
          if (!res.data.success) {
            setCaptchaError("Your human verification is invalid. Try again!");
            addMessage({message: "Your human verification is invalid. Try again!", type: "error"});
            setLoadingCaptcha(false);
            return;
          }
        })
        .catch(() => {
          setCaptchaError("It was not possible to verify that you are a human. Try again!");
          addMessage({message: "It was not possible to verify that you are a human. Try again!", type: "error"});
          setLoadingCaptcha(false);
          return;
        });
    }

    if (!formValidation()) {
      try {
        const response = await registerUserAndCreatePortfolio({
          variables: {
            input: {
              email: formData.email,
              password: formData.password,
              firstName: formData.firstName,
              lastName: formData.lastName,
              companyName: formData.companyName,
            },
          },
        });
        
        if (response) {
          if (response.data.registerPortfolioUser) {
            setSignupSucceeded(true);
            setLoadingCaptcha(false);
            setConfirmEmailMessage(`A confirmation link was sent to the following email: ${formData.email}. Please, confirm your email before continuing.`);
          }
        }
      } catch (error) {
        setFormError({ loginError: error });
        addMessage({ message: "The server has encountered an error. Please try again or come back later.", type: "error" })
      } finally {
        if (captchaRef && captchaRef.current) captchaRef.current.reset();
        setLoadingCaptcha(false);
      }
    } else {
      if (captchaRef && captchaRef.current) captchaRef.current.reset();
      console.log('reset captch');
    }
  }

  function handleChange(event) {
    const {name, value} = event.target

    if (name == "password") {
      if (value == "") setPasswordStrengthValue(0)
      else {
        const newStrength = passwordStrength(value).id + 1;
        setPasswordStrengthValue(newStrength);
        if (newStrength === 4) {
          setShowCaptcha(true);
        } else {
          setShowCaptcha(false);
        }
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaHasValue(true);
    } else {
      setCaptchaHasValue(false);
    }
  }

  const { email, password, firstName, lastName, companyName } = formError;

  return (
    <Drawer open={true} width="23rem" height="89vh" hideBackdrop={true}>
      <div className="signup">
        <form id="drawer-form" onSubmit={handleSubmit}>
          <h2 style={{marginBottom: '.5em'}}>Sign Up</h2>

          {confirmEmailMessage && <Alert severity="success">{confirmEmailMessage}</Alert>}
          {!signupSucceeded &&
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <Input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              label="Company Name"
              variant="outlined"
              fullWidth
              error={companyName}
              helperText={companyName ? errorMessages.companyName : ''}
              id="companyName"
            />

            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              label="First Name"
              variant="outlined"
              fullWidth
              error={firstName}
              helperText={firstName ? errorMessages.firstName : ''}
              id="firstName"
            />

            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              label="Last Name"
              variant="outlined"
              fullWidth
              error={lastName}
              helperText={lastName ? errorMessages.lastName : ''}
              id="lastName"
            />

            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="E-mail"
              variant="outlined"
              fullWidth
              error={email}
              helperText={email ? errorMessages.email : ''}
              id="email"
            />

            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              label="Password"
              variant="outlined"
              fullWidth
              error={password}
              helperText={password ? errorMessages.password : ''}
              id="password"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />

            <div className="password-strength">
              <span>Password Strength</span>
              <span> <Rating value={passwordStrengthValue} max={4}/> </span>
            </div>
          </Box> 
          }

          {!error /*&& !captchaError*/ && !signupSucceeded && !showCaptcha && (
            <div>
              <List dense={true}>
                <ListItem>
                  <ListIcon sx={{minWidth: '30px'}}>
                    <FaCheck sx={{width: "80%"}}/>
                  </ListIcon>
                  { formData.password.length > 8 ? 
                    <s><ListItemText
                      primary="Longer than 8 characters"
                    /></s>:
                    <ListItemText
                      primary="Longer than 8 characters"
                    />
                  }
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  { (containsLowerCase(formData.password) && containsUpperCase(formData.password)) ? 
                    <s><ListItemText
                      primary="Use lowercase and uppercase letters"
                    /></s> : 
                    <ListItemText
                      primary="Use lowercase and uppercase letters"
                    /> 
                  }
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  { containsSpecialChars(formData.password) ? 
                    <s><ListItemText
                      primary="Use special symbols"
                    /></s> : 
                    <ListItemText
                      primary="Use special symbols"
                    /> 
                  }
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  { containsNumber(formData.password) ?
                    <s><ListItemText
                      primary="Use numbers"
                    /></s>: 
                    <ListItemText
                      primary="Use numbers"
                    />
                  }
                </ListItem>
              </List>
            </div>
          )}

          <div className="bottom-container">
            {error && (
              <ErrorMessage
                error={"This email address is already taken or in use."}
              />
            )}
            {captchaError && (
              <ErrorMessage
                error={captchaError}
              />
            )}
            {!signupSucceeded && showCaptcha &&
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                ref={captchaRef}
                onChange={onCaptchaChange}
              />
            }
            {!signupSucceeded && 
              <Button 
                disabled={loading || passwordStrengthValue < 4 || (process.env.NODE_ENV !== "development" && (!captchaRef.current || !captchaHasValue))}
                loading={loading || loadingCaptcha}
                type="submit"
                sx={{ width: 150, mt: 1 }}
              >
              Sign Up
              </Button>
            }
          </div>
        </form>
      </div>
    </Drawer>
  );
}

export default RegisterForm;

import { gql } from "@apollo/client";

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($activity: activityInput) {
    updateActivity(input: $activity) {
      id
      name
      description
      startDate
    }
  }
`;

import React, { useRef, useContext, useEffect, useState } from 'react';
import { gql, useMutation } from "@apollo/client";
import { Box } from '@mui/material';
import UserListDropdown from './UserListDropdown';
import CellWrapper from '../GridInputCell/CellWrapper';
import { UserContext } from '../../context/UserContext';
import { MessageContext } from "../../context/MessageContext";
import { GET_PORTFOLIO_USERS_QUERY } from "../../apollo/queries/getPortfolioUsers";
import { FIND_TEAM_USERS } from "../../apollo/queries/findTeamUsers";
import StandardGrid from '../Grid/Grid';
import styled from '@emotion/styled';
import TableDateTime from "../TableDateTime/TableDateTime";
import { useTheme } from '@emotion/react';
import TableSkeleton from '../Skeleton/TableSkeleton';

export const CREATE_MEMBER_INVITE = gql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      id
      user {
        firstName
        lastName
        memberStatus
        portfolio {
          id
          name
        }
      }
    }
  }
`;
const GridContainer = styled(Box)`
  height: 95%;
  width: 98%;
  display: flex;
  border: ${({theme}) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: 8px;
`

const columnDefs = [
  {
    headerName: "First Name",
    field: "firstName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'First Name'
    }
  },
  {
    headerName: "Last Name",
    field: "lastName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'Last Name'
    }
  },
  {
    headerName: "E-mail",
    field: "recipientEmail",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'E-mail'
    }
  },
  {
    headerName: "Joined",
    field: "dateCreated",
    sortable: true,
    filter: true,
    cellRenderer: "TableDateTime",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Status",
    field: "memberStatus",
    sortable: true,
    filter: true,
    cellRenderer: "UserListDropdown",
    cellClass: "ag-show-dropdown-modal",
  }
];

const frameworkComponents = {
  UserListDropdown,
  CellWrapper,
  TableDateTime
};

const gridOptions = {
  suppressPropertyNamesCheck: true,
  pagination: true,
  rowSelection: 'single',
  columnDefs,
  editType: 'fullRow',
  animateRows: true,
  suppressClickEdit: true,
  pinnedTopRowData: [{
    firstName: 'fr-',
    lastName: 'fr-',
    memberStatus: 'Invite',
    dateCreated: '',
    recipientEmail: 'fr-',
  }]
};

export default function UserList({ users, title, portfolioID, loading, setTeamUsers }) {
  const [items, setItems] = useState([]);
  const { user, teamID } = useContext(UserContext);
  const { email: senderEmail, userType } = user;
  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();
  const gridApiRef = useRef(null);

  const [createMemberInvite] = useMutation(CREATE_MEMBER_INVITE);

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  function getUserMemberType(userType) {
    if (userType === "PORTFOLIO_MANAGER") return "PORTFOLIO_MEMBER";
    if (userType === "TEAM_MANAGER") return "TEAM_MEMBER";
  }

  function getRefetchQuery(userType, portfolioID) {
    if (userType === "PORTFOLIO_MANAGER")
      return [{ query: GET_PORTFOLIO_USERS_QUERY, variables: { portfolioID } }];
    if (userType === "TEAM_MANAGER")
      return [{ query: FIND_TEAM_USERS, variables: { teamID } }];
    return null;
  }

  const userMemberType = getUserMemberType(userType);
  const refetchQuery = getRefetchQuery(userType, portfolioID);

  const inviteFormValidation = (formData) => {
    if (formData.firstName.length === 0) {
      addMessage({type: 'error', message: 'Please provide a first name.'})
      return true;
    }

    if (formData.lastName.length === 0) {
      addMessage({type: 'error', message: 'Please provide a last name.'})
      return true;
    }

    if (formData.email.length === 0) {
      addMessage({type: 'error', message: 'Please provide a valid email address.'})
      return true;
    }

    return false;
  }

  const onSubmitInvite = async (event) => {
    event.preventDefault();

    const data = gridApiRef.current.getPinnedTopRow(0).data
      || gridOptions.api.getPinnedTopRow(0).data;
    const formData = {
      firstName: data.firstName.replace('fr-', ''),
      lastName: data.lastName.replace('fr-', ''),
      email: data.recipientEmail.replace('fr-', ''),
    }

    gridApiRef.current.setPinnedTopRowData([{
      firstName: 'fr-',
      lastName: 'fr-',
      memberStatus: 'Invite',
      dateCreated: '',
      recipientEmail: 'fr-',
    }])

    if (!inviteFormValidation(formData) && userMemberType) {
      const input = {
        type: "MEMBER",
        id: teamID,
        UserInput: {
          username: formData.email,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          memberStatus: "PENDING",
          confirmed: true,
        },
      };

      try {
        const response = await createMemberInvite({
          variables: { input },
          refetchQueries: refetchQuery,
          awaitRefetchQueries: true
        });

        if (response.data.createMembership) {
          await gridApiRef.current.refreshCells();
          addMessage({ message: "User invitation sent successfully." });
        } else if (response.errors) {
          await gridApiRef.current.refreshCells();
          addMessage({ message: response.errors[0].message, type: "error"});
        }
        setTeamUsers((prev) => [ ...prev, { ...input.UserInput, blocked: false, userType: 'TEAM_MEMBER' } ])
      } catch (error) {
        addMessage({ message: error, type: "error" })
      }
    }
    if (gridApiRef.current) gridApiRef.current.sizeColumnsToFit();
  }

  useEffect(() => {
    if (users) {
      const transformedUsers = transformUserData(users);
      setItems(transformedUsers);
    }
  }, [users])

  if (loading) return (
    <TableSkeleton columnDefs={columnDefs} />
  )

  function transformUserData(data) {
    const notBlockedOrUnassigned = data.filter((item) => 
      (item.memberStatus !== "UNASSIGNED" && item.blocked !== true)
      && item.userID !== user.id
    )

    const transformedUsers = notBlockedOrUnassigned.map((item) => {
      return ({
        username: `${item.firstName} ${item.lastName}`,
        dateCreated: item.dateAccepted ? new Date(+item.dateAccepted) : undefined,
        memberStatus: item.memberStatus,
        recipientEmail: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        id: item.id,
        teamName: title,
        senderEmail,
        teamID,
        userID: item.userID
      })
    });

    return transformedUsers;
  }

  return (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <GridContainer theme={theme}>
        <form 
          onSubmit={onSubmitInvite}
          style={{width: '100%'}}
        >
          <StandardGrid 
            tableName="userList"
            onGridReady={onGridReady}
            rowData={items}
            gridOptions={gridOptions}
            onGridSizeChanged={onGridSizeChanged}
            components={frameworkComponents}
          />
        </form>
      </GridContainer>
    </div>
  )
}
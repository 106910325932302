import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import LawfirmsList from '../components/LawfirmsList/LawfirmsList';
import Typography from '../styled/Typography/Typography';

export default function Lawfirms() {
  const { user } = useContext(UserContext);

  if (user?.accDeleted) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      Your account has been deleted.
    </Typography>
  )
  return <div className="lawfirms-list py-3">
    <LawfirmsList />
  </div>;
}

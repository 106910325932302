export const parseDecimal = function (serialized, prefix, postfix) {
  if (prefix == null) {
    prefix = "$";
  }
  if (postfix == null) {
    postfix = "";
  }
  if (serialized != null && !isNaN(serialized)) {
    return `${prefix}${parseFloat(serialized)
      .toFixed(0)
      .toLocaleString()}${postfix}`;
  } else {
    return "";
  }
};

export const parseCurrency = function (value = 0, decimal = 0) {
  if (value === null) {
    return value;
  }
  return value.toLocaleString("en", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
};

export const parseNumber = function (value = 0) {
  if (value === null) {
    return value;
  }
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const containsNumber = function (str) {
  return /\d/.test(str);
}

export const containsLowerCase = function (str) {
  return /[a-z]/.test(str);
}

export const containsUpperCase = function (str) {
  return /[A-Z]/.test(str);
}

/* eslint-disable */
export const containsSpecialChars = function (str) {
  // eslint-disable-next-line no-useless-escape
  const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return special.test(str)
}

export const formatProcessType = (type) => {
  if (type === "stateDefault" || type === "foreclosure")
    return "Foreclosure: State Default";
  if (type === "judicial")
    return "Foreclosure: Judicial";
  if (type === "nonJudicial")
    return "Foreclosure: Non-Judicial";
  if (type === "chapter7")
    return "Bankruptcy: Chapter 7"
  if (type === "chapter13")
    return "Bankruptcy: Chapter 13"
  if (type === "chapter11")
    return "Bankruptcy: Chapter 11"
};

export const isForeclosure = (process) => {
  if (!process || !process?.processType) return null;
  const types = ["stateDefault", "judicial", "nonJudicial"];
  return types.some(i => process.processType.includes(i));
}

export const isBankruptcy = (process) => {
  if (!process || !process?.processType) return null;
  const types = ["chapter7", "chapter13", "chapter11"];
  return types.some(i => process.processType.includes(i));
}

export const areFiltersEqual = (obj1, obj2) => {
  // Helper function to compare two arrays
  function arraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) return;
    if (arr1?.length !== arr2?.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  }

  // Helper function to get keys of an object
  function getKeys(obj) {
    let keys = [];
    for (let key in obj) {
      if (obj[key] !== undefined) {
        keys.push(key);
      }
    }
    return keys;
  }
  if (!obj1 || !obj2) return false;
  // Compare the keys of the two objects
  const keys1 = getKeys(obj1);
  const keys2 = getKeys(obj2);
  if (keys1.length !== keys2.length) return false;

  // Compare the values of each key
  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    const val1 = obj1[key];
    const val2 = obj2[key];
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (val1.filterType !== val2.filterType) {
        return false;
      }
      if (val1.filterType === "number") {
        if (val1.filter !== val2.filter) return false;
        if (val1.type !== val2.type) return false;
      }
      if (val1.values && val2.values) {
        if (!arraysEqual(val1?.values, val2?.values))
          return false;
      }
    } else {
      if (val1 !== val2) return false;
    }
  }

  return true;
};

export const capitalize = (str) =>{
  str = str.toLowerCase()
  const words = str.split(" ")
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ")
}

export const capitalValueFormatter = (params) => {
  return capitalize(params.value)
}


export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

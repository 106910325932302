import { gql } from "@apollo/client";

export const UPDATE_SUBTASK_AND_EVENTS_MUTATION = gql`
  mutation UpdateSubtaskAndLog(
    $subtask: updateSubtaskInput!
    $event: createEventInput!
  ) {
    updateSubtask(input: $subtask) {
      id
    }

    createEvent(input: $event) {
      id
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_PORTFOLIO_USERS_QUERY = gql`
  query GetPortfolioUsersByID($portfolioID: ID!) {
    findPortfolioUsers(id: $portfolioID) {
      id
      users {
        id
        firstName
        lastName
        memberStatus
        created_at
        email
        dateAccepted
        blocked
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const CREATE_TASK_MUTATION = gql`
  mutation CreateTaskAndLog(
    $task: createTaskInput!
  ) {
    createTask(input: $task) {
      id
    }
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($event: createEventInput!) {
    createEvent(input: $event) {
      id
    }
  }
`;

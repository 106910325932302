import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import Modal from "../../styled/Modal/Modal";
import { useHistory } from "react-router-dom";
import Button from '../../styled/Button/Button';
import { Box, TextField } from "@mui/material";

const APPLY_COUPON = gql`
  mutation applyCoupon($coupon: String!) {
    applyCoupon(coupon: $coupon)
  }
`;

export default function ApplyCoupon() {

  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [applyCoupon] = useMutation(APPLY_COUPON);
  const [message, setMessage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setIsReady(true);
  }, []);

  function handleChange(event) {
    const {value} = event.target
    setCouponCode(value);
  }

  const onApply = async () => {
    try {
      const res = await applyCoupon({
        variables: { coupon: couponCode },
      });
      
      if (res.errors && res.errors.length > 0) {
        setMessage("Invalid coupon.");
      } else if(res.data && res.data.applyCoupon) {
        setMessage("Coupon discount applied")
      }
    } catch (err) {
      console.error(err);
      setMessage("Invalid coupon.");
    }

  }

  const onModalClose = () => {
    setShowModal(false); 
    history.push("/");
  }

  if (!isReady) return (<></>)

  return (
    <>
      <Modal open={showModal} width="25rem">

        <h2>Enter your coupon code</h2>

        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <TextField
            type="text"
            name="couponcode"
            value={couponCode}
            onChange={handleChange}
            label="Coupon code"
            variant="outlined"
            fullWidth
            sx={{ m: 1 }}
            id="couponCode"
          />

          <Button 
            type="submit"
            onClick={onApply}
            sx={{width: '3rem'}}
          >
            Apply
          </Button>
        </Box>
        
        {message && 
        <>
          {message}
        </>}

        <Box sx={{marginTop: '50px'}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Button
              type="submit"
              sx={{
                mr: 1,
              }}
              onClick={onModalClose}
            >
              Continue
            </Button>

            <Button
              variant="secondary"
              onClick={onModalClose}
            >
              Skip
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

import { gql } from "@apollo/client";

export const GET_USERS_BY_PORTFOLIO_AND_TEAM = gql`
  query GetUsersByPortfolioAndTeam($portfolioID: ID, $teamID: ID, $assetID: ID) {
    getUsersByPortfolioAndTeam(portfolioID: $portfolioID, teamID: $teamID, assetID: $assetID) {
      id
      firstName
      lastName
      userType
      username
      portfolio {
        id
        name
      }
    }
  }
`;

import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useMutation } from "@apollo/client";
import { CREATE_COMMENT_AND_EVENTS_MUTATION } from "../../../apollo/mutations/createCommentAndEventsMutation";
import { EVENTS_QUERY } from "../../../apollo/queries/eventsQuery";
import { MessageContext } from '../../../context/MessageContext';
import { useTheme } from '@emotion/react';

const StyledInput = styled(TextField)`
  width: 99.9%;
  border-top: 2px solid ${({ theme }) => theme.themeColor.bodySecondary };

  .MuiInputBase-input {
    color: ${({ theme }) => theme.themeColor.bodySecondary };
    background-color: ${({ theme }) => theme.themeColor.sectionFill};
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    padding: 10px 15px;
    border-radius: 0px;
  }
`
const AdornmentIcon = styled(KeyboardReturnIcon)`
  cursor: pointer;
  background-color: blue;
  height: 100%;
  width: 3%;
  padding-right: 5px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background-color: ${({ theme }) => theme.themeColor.sectionFill};
`

export default function CreateComment({ milestoneID, processID }) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [createComment] = useMutation(CREATE_COMMENT_AND_EVENTS_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  const keyPress = (e) => {
    if(e.keyCode == 13) handleSubmit();
  }

  function handleChange(event) {
    setDescription(event.target.value);
    if (description.length > 0) setError(null);
  }

  async function handleSubmit() {
    if (description.length === 0) {
      setError("Text area cannot be blank.");
    } else {
      const comment = {
        process: processID,
        milestoneID: milestoneID,
        description,
      };

      const event = {
        type: "comment",
        description,
        process: processID,
        milestoneID: milestoneID,
        createdDate: new Date(),
      };

      try {
        await createComment({
          variables: {
            comment,
            event,
          },
        });
      } catch (error) {
        addMessage({ message: "Unable to create comment. Please try again or come back later.", type: "error" })
      }
      setError(null);
      setDescription("");
    }
  }

  useEffect(() => {
    if (error)
      addMessage({ message: error, type: "error"})
  }, [error])

  return (
    <StyledInput
      theme={theme}
      placeholder="Add a comment"
      variant="standard"
      value={description}
      onChange={handleChange}
      onKeyDown={keyPress}
      InputProps={{
        endAdornment: 
          <AdornmentIcon onClick={handleSubmit} />,
        disableUnderline: true,
      }}
    />
  )
}
import { useMutation } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACCEPT_INVITATION } from "../../apollo/mutations/acceptLaywerInvite";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MessageContext } from "../../context/MessageContext";

const AcceptLawyerInvite = () => {
  const { inviteEmail, invitationID } = useParams();
  const { value: user } = useLocalStorage("user");
  const history = useHistory();
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);
  const { addMessage } = useContext(MessageContext);

  const acceptLaywerInvitation = async () => {
    const invitationInput = {
      where: {id: invitationID},
      data: {
        status: 'ACCEPTED',
      }
    }

    const acceptResponse = await acceptInvitation({
      variables: { 
        invitation: invitationInput,
      },
    });

    if (acceptResponse.errors) {
      addMessage({ message: "Something went wrong while accepting the invitation. Please try again", type: "error" })
    } else {
      addMessage({ message: "Invitation has been accepted!" })
      history.push('/');
    }
  }

  useEffect(() => {
    // this check is because there is an issue with special chars:
    // https://webmasters.stackexchange.com/questions/498/which-special-characters-are-safe-to-use-in-url
    if (invitationID && (decodeURI(inviteEmail) === user.email)) {
      acceptLaywerInvitation();
    }
  }, [invitationID]);
  
  return (
    <Box sx={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  );
}

export default AcceptLawyerInvite;
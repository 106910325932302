import React from 'react';
import "./assigned-tasks.scss";

const CellLinethrough = ({value}) => {
  if (!value) return <span/>;

  const isCompleted = value?.toString().startsWith('cpl-');
  
  return (
    <span className={isCompleted ? "assigned-task-complete" : ""}>
      {value.replace('cpl-', '')}
    </span>
  )
}

export default CellLinethrough;
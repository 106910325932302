import React, { useState, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { TextField, Stack, Box, Alert, InputAdornment, IconButton } from '@mui/material';
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { UserContext } from "../../context/UserContext";
import Button from "../../styled/Button/Button";
import newLogo from "../../images/newlogo.svg";
import styled from "@emotion/styled";

import { ASSIGN_REFRESH_TOKEN } from "../../apollo/mutations/assignRefreshToken";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LOGIN = gql`
  mutation loginMutation($input: UsersPermissionsLoginInput!) {
    loginMutation(input: $input) {
      jwt
      user {
        username
        email
        confirmed
        blocked
        id
      }
    }
  }
`;

const GET_USER_INFO = gql`
  query GET_USER_INFO {
    getUserInfo {
      id
      firstName
      lastName
      userType
      tncStatus
      paymentMethodAdded
      email
      stripeInvoiceURL
      verifiedEmail
      staff
      watchlist
    }
  }
`;

const INITIAL_FORM_STATE = {
  identifier: "",
  password: "",
};

const INITIAL_ERROR_STATE = {
  identifier: false,
  password: false,
  loginError: false,
};

const BottomBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255);
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  border-radius: 0 0 8px 8px;
`

export default function LoginForm() {
  const history = useHistory();
  const { setValue: setUserLocal } = useLocalStorage("user");

  const { user, setUser } = useContext(UserContext);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [showPassword, setShowPassword] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState("");
  const [ unverifiedEmail, setUnverifiedEmail ] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const next = query.get("next") || '/';
  const confirmedEmail = query.get("confirmedEmail");

  const [login, { error, loading }] = useMutation(LOGIN);
  const [assignRefreshToken] = useMutation(ASSIGN_REFRESH_TOKEN);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);

  function formValidation() {
    let hasError = false;

    if (formData.identifier.length === 0) {
      setFormError((prevState) => ({ ...prevState, identifier: true }));
      setErrorMessage("Please enter your email address");
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, identifier: false }));
      setErrorMessage("");
    }

    if (formData.password.length === 0) {
      setFormError((prevState) => ({ ...prevState, password: true }));
      setErrorMessage("Please enter your password");
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, password: false }));
      setErrorMessage("");
    }

    return hasError;
  }

  function getResponseError(response) {
    return response.errors[0].extensions.exception.data.message[0].messages[0];
  }

  async function handleSubmit(event) {
    // TODO: Utilize try catch to handle errors
    event.preventDefault();
    if (!formValidation()) {
      try {
        const loginResponse = await login({
          variables: {
            input: {
              identifier: formData.identifier,
              password: formData.password,
            }
          }
        })
        if (loginResponse) {
          const { data } = loginResponse;
          if (data) {
            setUserLocal({
              token: data.loginMutation.jwt,
              userID: data.loginMutation.user.id
            });
            const userInfo = await getUserInfo();

            if (!userInfo.data.getUserInfo.verifiedEmail) {
              setUnverifiedEmail("You need to verify your email address.");
              window.localStorage.removeItem("user");
              return;
            }

            const refreshToken = await assignRefreshToken();

            if (refreshToken.data) {
              sessionStorage.setItem('refresh', refreshToken.data.assignRefreshToken.refresh)
            } else {
              setErrorMessage("Could not assign token, please refresh or logout and try again.")
            }

            if (userInfo.data.getUserInfo) {
              setUser({
                token: data.loginMutation.jwt,
                userID: data.loginMutation.user.id,
                ...userInfo.data.getUserInfo,
              });
            }

            // if (confirmedEmail) {
            //   history.push('/payment')
            // }
          } else {
            const { id, message } = getResponseError(loginResponse);
            localStorage.removeItem('user');
            if (id === "Auth.form.error.blocked") history.push("/auth/blocked-account");
            if (id === "Auth.form.error.invalid") {
              setFormError((prevState) => ({
                ...prevState,
                loginError: true,
              }));
              setErrorMessage(message);
            }
          }

        }

      } catch (error) {
        localStorage.removeItem('user')
        sessionStorage.removeItem('refresh')
        setFormError((prevState) => ({ ...prevState, loginError: true }));
      }

    }
  }

  function handleChange(event) {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  if (user !== null) return <Redirect to={next} />;

  const { identifier, password } = formError;

  return (
    <div className="login">
      <img src={newLogo} height="80px" style={{display: 'block', margin: '30px auto'}}/>
      <form onSubmit={handleSubmit} style={{width: '100%'}}>
        {process.env.NODE_ENV === "development" && (
          <center>
            <small>
              You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
              mode.
            </small>
          </center>
        )}
        {confirmedEmail && (
          <Alert severity="success">Your email was confirmed. Please sign in to continue.</Alert>
        )}
        <Stack
          spacing={2}
          sx={{marginTop: '30px'}}  
        >
          <TextField
            label="E-mail"
            variant="outlined"
            value={formData.indetifier}
            onChange={handleChange}
            type="email"
            name="identifier"
            sx={{width: '92%', margin: '0 auto'}}
          />

          <TextField
            type={showPassword ? "text" : "password"}
            label="Password"
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            name="password"
            sx={{width: '92%', margin: '20px auto !important'}}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
        </Stack>

        <div className="align-self-center">
          {unverifiedEmail && (
            <ErrorMessage error={unverifiedEmail} />
          )}
          {error && (
            <ErrorMessage error={errorMessage} />
          )}
          {formError.loginError &&
            <ErrorMessage error={"Invalid Credentials."} />
          }
          {(identifier || password) && (
            <ErrorMessage error={"Please complete all fields."} />
          )}
        </div>
        <input type="submit" style={{display: 'none'}} />
      </form>
      <BottomBox>
        <Button 
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
          disabled={loading}
          sx={{
            width: 150
          }}
        >Sign In</Button>
        <Button 
          loading={loading}
          variant="secondary"
          sx={{
            width: 'auto',
            background: 'transparent !important',
            boxShadow: 'none',
            marginLeft: '30px',
            fontSize: '12px',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
          onClick={() => history.push(`/auth/forgot-password/${formData.identifier}`)}
        >
            Forgot account login?
        </Button>
      </BottomBox>
    </div>
  );
}

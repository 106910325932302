import React from 'react';
import SplitButton from '../../styled/SplitButton/SplitButton';

const StatusDropdown = ({ value }) => {
  return (
    <SplitButton 
      label={value}
      color='gray'
      items={[
        { label: 'Review', onClick: () => console.log("Review")},
        { label: 'Download', onClick: () => console.log("Download")},
        { label: 'Edit', onClick: () => console.log("Edit")},
        { label: 'Delete', onClick: () => console.log("Withdraw"), color: 'red'},
      ]}
    />
  )
}

export default StatusDropdown;
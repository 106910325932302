import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, Stack } from '@mui/material';
import Chip from "../../styled/Chip/Chip";

const BILLINGS_QUERY = gql`
  query GetBillings {
    billings {
      maxLoans
      month
      year
      created_at
      assets {
        loanNumber
      }
    }
  }
`;

const Billing = () => {
  const billingsQuery = useQuery(BILLINGS_QUERY);
  const [billings, setBillings] = useState([]);

  useEffect(() => {
    if (!billingsQuery.data) return;
    setBillings(billingsQuery.data.billings);
  }, [billingsQuery.data]);

  return (
    <Paper elevation={3} sx={{p: 2}}>
      {billings.length > 0 && billings.map((billing) =>
        <Box key={billing.created_at}>
          <Typography>{`${billing.month}/${billing.year}`} - {`${billing.maxLoans} Loans`}</Typography>
          <Stack spacing={1} direction='row'>
            {billing.assets.map(ba => <Chip key={ba.loanNumber} label={`${ba.loanNumber}`} /> )}
          </Stack>
        </Box>
      )}

      {billings.length === 0 && (
        <span>You don&apos;t have a billing.</span>
      )}
    </Paper>
  )
}

export default Billing;
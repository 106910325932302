export const lightColors = {
  backgroundBody: "#FFFFFF",
  oppositeBody: "#080810",
  bodyMain: "#1F2020",
  oppositeBodyMain: "#CDCDD5",
  bodySecondary: "#75757B",
  bodyTetriary: "#CDCDD5",
  sectionStroke: "#E4E4E4",
  sectionFill: "#F9F9F9",
  brandPrimaryRed: "#F11010",
  brandPrimaryBlue: "#106AF1",
  brandPrimaryOrange: "#F99704",
  brandSecondaryOrange: "#FEF5E6",
  brandPrimaryGreen: "#45B85E",
  borderColor: "#C4C4C4",
  lightBlue: "#CFE1FC",
};

export const darkColors = {
  backgroundBody: "#080810",
  oppositeBody: "#FFFFFF",
  bodyMain: "#CDCDD5",
  oppositeBodyMain: "#1F2020",
  bodySecondary: "#75757B",
  bodyTetriary: "#494950",
  sectionStroke: "#181820",
  sectionFill: "#111118",
  brandPrimaryRed: "#A73946",
  brandPrimaryBlue: "#3965A7",
  brandPrimaryOrange: "#A77B39",
  brandSecondaryOrange: "#FEF5E6",
  brandPrimaryGreen: "#348637",
  borderColor: "#494950",
  lightBlue: "#111A2E",
};

// export const colors = {
//   bodyBg: "#FFFFFF",
//   brandPrimaryBlue: "#106AF1",
//   brandPrimaryGray: "#d2d2d2",
//   brandPrimaryBlack: "#1f2020",
//   brandPrimaryRed: "#F11010",
//   grayText: "#676768",
//   brandOceanBlue: "#106AF1",
//   lightGray: "#e9e9e9",
//   veryLightGray: "#f4f4f4",
//   brandPrimaryDarkBlue: "#135BC7",
//   darkGrey: "#141414",
//   darkMedGrey: "#1f2020",
//   medGrey: "#333",
//   lighterGrey: "#cecece",
//   lightMedGrey: "#777",
//   textColor: "#ffffff",
//   brandCarbon: "#1F2020",
//   brancCarbonSecondary: "#75757B",
//   orange: "#F99704",
//   brandGreen: "#45B85E",
// };
import { gql } from "@apollo/client";

export const ASSET_QUERY = gql`
  query FindAsset($assetID: ID!) {
    asset(assetID: $assetID) {
      id
      deleted
      loanNumber
      state
      borough
      upb
      currentValue
      budgetDaysToSale
      borrowerFirstName
      borrowerLastName
      assetAddress
      city
      state
      postal
      county
      daysInDlq
      billingStatus
      nodDate
      paidThroughDate
      lienPosition
      interestRate
      servicer
      asOf
      ltv
      hampObligation
      statusValue
      saleScheduledDate
      activities {
        id
        startDate
        closeDate
        name
        description
      }
      assigned {
        id
        firstName
        lastName
      }
      portfolio {
        id
        name
      }
      loan_status {
        cumulativeBudget
        totalDaysInFc
        totalDaysInBk
        activeMilestoneID
        totalHoldsDuration
        stepDaysOnHold
        daysSinceHold
        stepGrossFcDays
        totalDaysOverdue
        stepBudgetDays
        stepGrossFcDays
        totalDaysOverdue
        netDuration
        netInFC
        netVsBudget
        grossVsBudget
        budgetDaysToSale
        daysInDlq
      }
      processes {
        id
        deleted
        stepID
        processType
        processSteps
        status
        startDate

        referral {
          id
          referralStatus
          deleted
          team {
            id
            name
          }
        }

        holds {
          id
          deleted
          active
          closeDate
          holdDescription
          holdType
        }
      }
      activeProcess {
        id
        processSteps
        processType
        stepID
        deleted
        status
        startDate

        referral {
          id
          referralStatus
          deleted
          team {
            id
            name
          }
        }

        holds {
          id
          startDate
          closeDate
          expectedCloseDate
          createdInMilestone
          holdDescription
          holdType
        }

        events {
          id
          type
          createdDate
          milestoneID
        }

        tasks {
          id
          blocking
          deleted
          completedAt
        }

        task_flows {
          id
          blocking
          completedAt
        }
      }
    }
  }
`;

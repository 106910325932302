import React, { useState } from "react";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { useQuery } from "@apollo/client";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { GET_ACTIVITY_EVENTS } from "../../apollo/queries/getActivityEvents";
import generateEventsCardType from "./generateEventsCardType";
import {
  fullName,
  formatDate,
  calculateHoldRange,
} from "../../helpers";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { useTheme } from "@emotion/react";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { rgba } from "emotion-rgba";
import Typography from "../../styled/Typography/Typography";
import TimelineSkeleton from "../Skeleton/TimelineSkeleton";
import TaskComments from "../TaskComments/TaskComments";
import HoldComments from "../MilestoneHolds/HoldComments";
import { useEffect } from "react";

const formatColor = (type, theme) => {
  if (type === 'milestone-closed')
    return theme.themeColor.brandPrimaryRed;
  else if (type[0] === 't')
    return theme.themeColor.brandPrimaryGreen;
  else if (type[0] === 'h')
    return theme.themeColor.brandPrimaryOrange;
  else if (type === 'date')
    return theme.themeColor.bodySecondary;
  else if (type === 'document-upload')
    return theme.themeColor.bodyMain;
  else if (type === 'form-update')
    return theme.themeColor.lightBlue;
  else 
    return theme.themeColor.brandPrimaryBlue;
}

const filterTimeline = (events, filters) => {
  if (!filters || !filters.length) return events;
  let result = [];
  result = events.filter(event => {
    return filters.find(filter => {
      return filter.value === event.type && filter.checked;
    });
  });
  return result;
}

const AddMore = ({ setOpenHold, setOpenTask, setAddTaskFlow, theme }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent
        align="right"
        variant="body2"
        style={{ maxWidth: '81px' }}
      />
      <TimelineSeparator>
        <AddCircleIcon 
          sx={{width: '50px', height: '50px', color: theme.themeColor.brandPrimaryBlue, cursor: 'pointer'}}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem value={'task'} onClick={() => {setOpenTask(true); handleClose()}}>Add Task</MenuItem>
          <MenuItem value={'hold'} onClick={() => {setOpenHold(true); handleClose()}}>Open Hold</MenuItem>
          <MenuItem value={'taskFlow'} onClick={() => {setAddTaskFlow(true); handleClose()}}>Add Task Flow</MenuItem>
        </Menu>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent/>
    </TimelineItem>
  )
}

const DateChange = ({date, theme}) => {
  return (

    <TimelineItem sx={{ maxWidth: 'calc(100% - 200px)' }}>
      <TimelineOppositeContent
        align="right"
        variant="body2"
        style={{ maxWidth: '100px' }}
      />

      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          sx={{
            color: formatColor('date', theme)
          }}
        />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent sx={{ py: '10px' }}>
        <Box>
          <Typography number
            sx={{
              width: '100%',
              color: theme.themeColor.bodyMain,
              fontWeight: '500',
              mt: 1,
              fontSize: "24px" 
            }}
          >
            {new Date(+date).toLocaleDateString([], {day: 'numeric', month: 'numeric'})}
          </Typography>
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
}

export default function Events({ 
  milestoneID,
  processID,
  setOpenHold,
  setOpenTask,
  activeStep,
  startDate,
  currentMilestone,
  setAddTaskFlow,
  readOnly,
  timelineFilters,
  activity
}) {
  const theme = useTheme();
  if (!milestoneID && !activity?.id) return <span />;

  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [showTaskComments, setShowTaskComments] = useState(false);
  const [currentTask, setCurrentTask] = useState(undefined);
  const [showHoldComments, setShowHoldComments] = useState(false);
  const [currentHold, setCurrentHold] = useState(undefined);
  const [isActivity] = useState(activity?.id ? true : false);

  const EVENT_QUERY = isActivity ? GET_ACTIVITY_EVENTS : EVENTS_QUERY;
  const EVENT_VARS = isActivity ? { activityID: activity.id } : { milestoneID, processID };

  const { loading, data } = useQuery(EVENT_QUERY, {
    variables: EVENT_VARS,
    pollInterval: parseInt(process.env.REACT_APP_SMALL_POLL_INTERVAL),
  });

  useEffect(() => {
    if (data) {
      const events = [...data.events];
      const sortedEvents = events.sort((a, b) => a.id - b.id);
      setEvents(sortedEvents);
      setAllEvents(sortedEvents);
    }
    if (data && currentTask) {
      const newCurrentTask = data.events.find((event) => event.task?.id === currentTask.id);
      if (newCurrentTask) setCurrentTask(newCurrentTask.task);
    }
    if (data && currentHold) {
      const newCurrentHold = data.events.find((event) => event.hold?.id === currentHold.id);
      if (newCurrentHold) setCurrentHold(newCurrentHold.hold);
    }
  }, [data, setCurrentTask, setCurrentHold]);

  useEffect(() => {
    if (allEvents.length) {
      const copy = [...allEvents];
      const filteredEvents = filterTimeline(copy, timelineFilters);
      setEvents(filteredEvents);
    }
  }, [timelineFilters])
  
  if (loading) return <TimelineSkeleton />;
  if (!milestoneID && !activity?.id) return null;
  
  const handleOpenTaskComments = (task) => {
    setCurrentTask(task);
    setShowTaskComments(true);
  };
  const handleOpenHoldComments = (hold) => {
    setCurrentHold(hold);
    setShowHoldComments(true);
  }

  const firstDate = events.length > 0 ? events[0].createdDate || events[0].completedDate : '';

  return (
    <>
      <Timeline sx={{ color: 'black' }}>
        {
          (milestoneID === activeStep && !readOnly) && 
          <AddMore setOpenHold={setOpenHold} setOpenTask={setOpenTask} setAddTaskFlow={setAddTaskFlow} theme={theme}/>
        }

        {events && events.length >= 1 && (
          <DateChange date={firstDate} theme={theme} />
        )}
      
        {
          events.map((event, i) => {
            if (event === undefined) return null;

            const { type } = event;
        
            const eventData = { ...event };

        
            if (event.createdBy) {
              eventData.createdBy = fullName(
                event.createdBy.firstName,
                event.createdBy.lastName
              );
              eventData.createdByType = event.createdBy.userType
            }
        
            if (event.createdFor) {
              eventData.createdFor = fullName(
                event.createdFor.firstName,
                event.createdFor.lastName
              );
            }
        
            if (event.createdDate) {
              eventData.originalCreatedDate = event.createdDate;
              eventData.createdDate = formatDate(event.createdDate);
            }
        
            if (event.completedDate) {
              eventData.completedDate = formatDate(event.completedDate);
            }
        
            if (event.dueBy) {
              eventData.originalDueBy = event.dueBy;
              eventData.dueBy = formatDate(event.dueBy);
            }
        
            if (event.createdDate && event.dueBy) {
              eventData.holdRange = calculateHoldRange(event.createdDate, event.dueBy)
            }

            if (event.createdDate && event.completedDate) {
              eventData.holdRange = calculateHoldRange(event.createdDate, event.completedDate)
            }

            const eventDate = new Date(+(event.createdDate || event.completedDate));
            const lastDate = i > 0 ? new Date(+(events[i-1].createdDate || events[i-1].completedDate)) : eventDate;

            return (
              <>
                {i > 0 && (eventDate.toLocaleDateString() !== lastDate.toLocaleDateString()) && (
                  <DateChange date={eventDate} theme={theme} />
                )}
                <TimelineItem key={event.createdDate} sx={{minHeight: 'auto', width: '100%', paddingBottom: i === events.length-1 && '75px' }}>
                  <TimelineOppositeContent
                    style={{ maxWidth: '100px', color: rgba(theme.themeColor.bodyMain, 0.4), marginTop: '3px' }}
                  >
                    <Tooltip placement="top" title={eventDate.toLocaleDateString()}>
                      <Typography number fontSize='12px'>{eventDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</Typography>
                    </Tooltip>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    {type === 'comment' && (
                      <ChatBubbleIcon
                        sx={{ width: '13px', color: theme.themeColor.oppositeBody, marginTop: '6px' }}
                      />
                    )}

                    {(type === 'task-comment' || type === 'hold-comment') && (
                      <TimelineConnector 
                        sx={{
                          background: theme.themeColor.brandPrimaryGreen,
                          width: '4px',
                          ml: '4px'
                        }} 
                      />
                    )}

                    {type !== 'comment' && type !== 'task-comment' && type !== 'hold-comment' && (
                      <TimelineDot
                        variant="outlined"
                        sx={{
                          borderColor: formatColor(type, theme)
                        }}
                      />
                    )}

                    {i !== events.length - 1 && type !== 'task-comment' && type !== 'hold-comment' && <TimelineConnector />}
                  </TimelineSeparator>

                  {
                    generateEventsCardType(type, eventData, theme, handleOpenTaskComments, handleOpenHoldComments)
                  }
                </TimelineItem>
              </>
            )
          })
        }

        {/* Blue dot to connect to + button if the event log is empty */}
        {
          (events.length === 0 && milestoneID === activeStep) && (
            <TimelineItem>
              <TimelineOppositeContent
                style={{ maxWidth: '100px', color: theme.themeColor.bodyMain }}
              >
                <Tooltip placement="top" title={new Date(+startDate).toLocaleDateString()}>
                  <Typography data-testid="timeline-date" number fontSize='12px'>{new Date(+startDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</Typography>
                </Tooltip>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    background: theme.themeColor.brandPrimaryBlue
                  }}
                />
              </TimelineSeparator>
            
              {currentMilestone?.bk
                ? (
                  <TimelineContent
                    sx={{fontWeight: '800', color: theme.themeColor.bodyMain}}
                  >
                    {`${currentMilestone.bk.stepID} - ${currentMilestone.bk.label}`}
                  </TimelineContent>
                )
                : (
                  <TimelineContent
                    sx={{fontWeight: '800', color: theme.themeColor.bodyMain}}
                  >
                    {`${currentMilestone.fc.stepID} - ${currentMilestone.fc.label}`}
                  </TimelineContent>
                )
              }
            </TimelineItem>
          )
        }
      </Timeline>
      {currentTask && (
        <TaskComments
          open={showTaskComments}
          comments={currentTask.task_comments}
          taskName={currentTask.title}
          onClose={() => setShowTaskComments(false)}
          milestoneID={currentTask?.milestone}
          processID={currentTask?.process?.id}
          taskId={currentTask.id}
          taskDescription={currentTask.description}
          dueByMilestone={currentTask.dueByMilestone}
          deadlineAt={currentTask.deadlineAt}
          assignments={currentTask.assignments}
          taskActivity={activity}
        />
      )}
      {currentHold && (
        <HoldComments
          open={showHoldComments}
          hold={currentHold}
          onClose={() => setShowHoldComments(false)}
          milestoneID={currentHold.createdInMilestone}
          processID={currentHold.process.id}
          comments={currentHold.hold_comments}
        />
      )}
    </>
  )
}

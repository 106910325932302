import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react';
import {BsChat} from 'react-icons/bs';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`

const CustomIcon = styled(BsChat)`
  color: ${({theme, value}) => value ? theme.themeColor.brandPrimaryBlue : theme.themeColor.bodyTetriary};
  font-size: 30px;
`;

const CustomLabel = styled('span')`
  position: absolute;
  font-size: ${({value}) => value ? '10px' : '16px'};
  color: ${({theme, value}) => value ? theme.themeColor.brandPrimaryBlue : theme.themeColor.bodyTetriary};
  font-size: ${({value}) => value ? '700' : '500'};
`


const CommentIcon = ({value, onClick, className}) => {
  const theme = useTheme();
  return (
    <Container onClick={onClick}>
      <CustomIcon className={className} theme={theme} value={value} />
      <CustomLabel theme={theme} value={value}>
        {value || '+'}
      </CustomLabel>
    </Container>
  )
}

export default CommentIcon;
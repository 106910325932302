import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const FormFieldSelect = ({ field, value, handleChange, className, disabled }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (field.options) {
      const options = field.options.split(",");
      setOptions(options)
    }
  }, [field]);
  
  return (
    <FormControl fullWidth>
      <InputLabel>{field.label}</InputLabel>
      <Select
        className={className}
        value={value}
        label={field.label}
        onChange={(event) => handleChange(event, field)}
        required={field.required}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FormFieldSelect;
import React, { useContext } from "react";
import { Drawer as MUIDrawer } from "@mui/material";
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { UserContext } from "../../context/UserContext";

const StyledDrawer = styled(MUIDrawer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export default function Drawer(props) {
  const { children, open, variant, hideBackdrop, anchor, onClose, styling, readOnly } = props;
  const theme = useTheme();
  const { user } = useContext(UserContext);

  if (user?.timeoutOpen)
    return <></>

  return (
    <StyledDrawer
      {...props}
      theme={theme}
      anchor={anchor || "right"}
      open={open}
      onClose={onClose}
      variant={variant || 'temporary'}
      hideBackdrop={hideBackdrop || false}
      sx={{zIndex: readOnly ? "2000" : "1300"}}
      PaperProps= {{
        sx: {
          overflow: 'visible',
          margin: '5vh 20px 5vh 20px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '6px',
          height: props.height || '90vh',
          width: props.width || '20vw',
          background: theme.themeColor.backgroundBody,
          padding: '10px 25px',
          ...styling,
        },
        'data-testid': 'drawer-container'
      }}
    >
      { children }
    </StyledDrawer>
  )
}

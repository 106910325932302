import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Checkbox from "../../styled/Checkbox/Checkbox";

const FormFieldCheckbox = ({ field, value, handleChange, disabled }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value === 'true');
  }, [value]);

  const onClickCheckbox = () => {
    handleChange(field, !checked);
    setChecked(!checked);
  }

  return (
    <Checkbox label={field.label} checked={checked} onClick={onClickCheckbox} disabled={disabled} />
  )
}

export default FormFieldCheckbox;
import React, { useState, useRef } from 'react';
import Button from '../../styled/Button/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SettingsIcon from '@mui/icons-material/Settings';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

const colorConversion = {
  blue: 'brandPrimaryBlue',
  gray: 'bodyTetriary',
  black: 'bodyMain',
  red: 'brandPrimaryRed',
  ltGray: 'bodyTetriary',
  green: 'brandPrimaryGreen',
}

const StyledButtonGroup = styled(ButtonGroup)({
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderColor: 'white'
  },
  display: 'flex',
  width: '150px',
  boxShadow: 'none',
});

const Label = styled(Button)`
  background: ${(props) => props.color === 'ltGray' 
  ? rgba(props.theme.themeColor.bodyTetriary, .6)
  : props.theme.themeColor[colorConversion[props.colored]]};
  height: 35px;
  color: ${(props) => {
    if (props.colored === 'gray') return props.theme.themeColor.bodyMain;
    else if (props.colored === 'ltGray') return props.theme.themeColor.bodySecondary;
  }};
`
const DropdownButton = styled(Button)`
  width: 10%;
  background: ${(props) => props.color === 'ltGray' 
  ? rgba(props.theme.themeColor.bodyTetriary, .6)
  : props.theme.themeColor[colorConversion[props.colored]]};

  &:hover {
    background: ${(props) => rgba(props.theme.themeColor[colorConversion[props.colored]], .9)};
  };
  height: 35px;
`
const StyledMenuItem = styled(MenuItem)`
  text-decoration: underline;
  color: ${(props) => props.color === 'red' ? props.theme.themeColor.brandPrimaryRed : props.theme.themeColor.bodyMain};
  &:hover {
    text-decoration: underline;
  };
`;
const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${(props) => {
  if (props.colored === 'gray') return props.theme.themeColor.bodyMain;
  else if (props.colored === 'ltGray') return props.theme.themeColor.bodySecondary;
}};
`

export default function SplitButton(props) {
  const { color, label, items, placement } = props;
  const anchorRef = useRef(null);
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <StyledButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        <Label
          sx={{
            pointerEvent: props.onClick ? "pointer important" : "none !important",
            cursor: props.onClick ? "pointer !important" : "default !important",
            "&:hover": {
              background: props.onClick
                ? rgba(theme.themeColor.bodyTetriary, .7)
                : rgba(theme.themeColor.bodyTetriary, 1)
            }
          }}
          onClick={props.onClick}
          colored={color}
          theme={theme}
        >
          {label}
        </Label>
        <DropdownButton
          colored={color}
          theme={theme}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
        >
          <StyledSettingsIcon colored={color}/>
        </DropdownButton>
      </StyledButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement ? placement : "bottom-end"}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem >
                  {
                    items.map((item, i) => (
                      <StyledMenuItem 
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                          item.onClick();
                        }}
                        color={item.color}
                        theme={theme}
                        key={i}
                      >
                        {item.label}
                      </StyledMenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

import { gql } from "@apollo/client";

export const REFER_PREVIOUS_PROCESS = gql`
  mutation referPreviousProcess($input: referPrevProcessInput) {
    referPreviousProcess(input: $input) {
      id
      processSteps
      processType
      stepID
      deleted
      status
      startDate

      referral {
        id
        referralStatus
        deleted
        team {
          id
          name
        }
      }

      holds {
        id
        startDate
        closeDate
        expectedCloseDate
        createdInMilestone
        holdDescription
        holdType
      }

      events {
        id
        type
        createdDate
        milestoneID
      }

      tasks {
        id
        blocking
        deleted
        completedAt
      }

      task_flows {
        id
        blocking
        completedAt
      }
    }
  }
`;
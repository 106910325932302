import differenceInDays from 'date-fns/differenceInDays'


export const fullName = (f, l) => {
  const fullName = `${f} ${l}`.replace("null", "");
  return fullName.length === 0 ? "N/A" : fullName;
};
export const delimitNumbers = (value) => {
  if (typeof value === "number") {
    value = Math.round(value * 100) / 100;
    value = value.toFixed(0);
  }
  return (value + "").replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
    return (
      (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
        ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
        : b) + c
    );
  });
};

//todo: refactor if performance concern
export const getInitials = (name) => {
  if (!name) return "";
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const formatBooleanToString = (boolArgs) => (boolArgs ? "YES" : "NO");

export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(+dateString).toLocaleDateString(undefined, options)
    || new Date(dateString).toLocaleDateString(undefined, options); // In case we have the old date format
};

export const calculateHoldRange = (start, end) => {
  const date1 = new Date(+start) || new Date(start);
  const date2 = new Date(+end) || new Date(end);
  return differenceInDays(date2, date1)
}

export function generateNameFromFieldName(fieldName) {
  const name = fieldName.replace(/([A-Z])/g, " $1").trim();
  return name.charAt(0).toUpperCase() + name.slice(1);
}

const notNumbers = [
  'borough', 'assetAddress', 'attorneyName', 'attorneyOffice', 'borrowerFirstName', 'borrowerLastName', 'city', 'county',
  'holdStatus', 'isOverdue', 'lastStep', 'seller', 'statusName', 
]

// TODO: IF REFACTOR IMPORT TABLE TO AG_GRID USE THIS TO CREATE COLUMNS DEFINITION
export function createColumsDef(columns, fileTypeErrors) {
  let header = [];
  columns.forEach((column) => {
    const headerColumn = {
      headerName: generateNameFromFieldName(column.fieldName),
      field: column.fieldName,
      sortable: true,
      filter: true,
    };
    if (column.fieldName === 'id') {
      headerColumn.pinned = 'left';
      headerColumn.lockPinned = true;
      headerColumn.cellClass = 'lock-pinned';
      headerColumn.headerClass = 'lock-pinned-header';
    }
    if (!notNumbers.includes(column.fieldName)) {
      headerColumn.cellStyle = { fontFamily: 'IBM Plex Mono' }
    }

    const columnHasErrors = fileTypeErrors.filter(({columnName}) => columnName == headerColumn.field);

    // if column has errors make header red and field with error also red
    if (columnHasErrors.length > 0) {
      headerColumn.headerClass = 'header-error';
      headerColumn.cellStyle = function(params) {
        if (columnHasErrors.find(({value}) => value === params.value )) {
          return {color: 'red'};
        }
        return null;
      }
    }

    header.push(headerColumn);
  });
  return header;
}

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function formatDocumentName(name) {
  const words = name.split('_')
  words[0] = words[0][0].toUpperCase() + words[0].slice(1).toLowerCase()
  return words.map(word => `${word} `)
}

export function currencyToNumber(value) {
  return parseFloat(value.replace(/\$|,/g, ""))
}

export function checkPath(path) {
  if (!path) return false;
  const ignorePaths = ['loginMutation', 'forgotPassword', 'registerPortfolioUser', 'resetPassword', 'importLoans', 'getImportConflicts'];

  return !ignorePaths.some(ignorePath => path.includes(ignorePath))
}
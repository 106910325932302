import { gql } from "@apollo/client";

export const FORECLOSURE_COMPLETE_MUTATION = gql`
  mutation ForeclosureComplete(
    $asset: updateAssetInput!
    $closeMilestoneEvent: createEventInput!
    $process: updateProcessInput!
  ) {
    updateAsset(input: $asset) {
      id
    }

    updateProcess(input: $process) {
      id
    }

    closeMilestone: createEvent(input: $closeMilestoneEvent) {
      id
    }
  }
`;

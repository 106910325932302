import React, { useRef, useContext, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_DOCUMENTS_FROM_PORTFOLIO } from '../../apollo/queries/getAllDocumentsFromPortfolio';
import { DOCUMENT_DOWNLOAD_LINK } from "../../apollo/queries/downloadLink"
import { UserContext } from '../../context/UserContext';
import { MessageContext } from '../../context/MessageContext';
import StatusDropdown from './StatusDropdown';
import UploadedBy from './UploadedBy';
import StandardGrid from '../Grid/Grid';
import TableSkeleton from '../Skeleton/TableSkeleton';
// import Button from '../../styled/Button/Button';
import DocumentExtension from '../DocumentTable/DocumentExtension';
import TableDateTime from '../TableDateTime/TableDateTime';
import DocumentCreator from '../DocumentTable/DocumentCreator';

const myColsDef = [
  {
    headerName: "Type",
    field: "type",
    sortable: true,
    filter: true,
    width: 250,
  },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    filter: true,
    width: 250,
  },
  {
    headerName: "Loan #",
    field: "loan",
    sortable: true,
    filter: true,
    width: 200,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Law Firm",
    field: "lawFirm",
    sortable: true,
    filter: true,
    width: 175,
  },
  {
    headerName: "Ext",
    field: "ext",
    sortable: true,
    filter: true,
    width: 125,
    cellRenderer: DocumentExtension,
  },
  {
    headerName: "Process",
    field: "process",
    sortable: true,
    filter: true,
    width: 200,
    cellRenderer: DocumentExtension,
  },
  {
    headerName: 'Uploaded',
    field: 'uploaded',
    sortable: true,
    filter: true,
    width: 250,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    cellRenderer: TableDateTime,
  },
  {
    headerName: "By",
    field: "uploadedBy",
    sortable: true,
    filter: true,
    width: 100,
    cellRenderer: DocumentCreator,
  },
  {
    // TODO: Custom component for this column
    headerName: "",
    // field: "status",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: false,
    width: 200,
    // cellRenderer: 'StatusDropdown',
    // hide: true,
  },
];

const TableContainer = styled(Box)`
  margin: 0 auto;
  height: 98%;
  width: 98%;
  border: ${({theme}) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({theme}) => theme.themeSizing.borderRadiusLarge};
`

const components = {
  StatusDropdown,
  UploadedBy,
  DocumentExtension,
  TableDateTime,
  DocumentCreator,
}

const ls = window.localStorage;

const DocumentList = () => {
  const gridApiRef = useRef(null);
  const theme = useTheme();

  const { portfolioID } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const [items, setItems] = useState([]);
  const [columnDefs, setColumnDefs] = useState(myColsDef);

  const { loading } = useQuery(GET_DOCUMENTS_FROM_PORTFOLIO, {
    variables: { portfolioID },
    onCompleted: (data) => {
      setItems(transformDocumentsData(data));
    }
  });

  useEffect(() => {
    const visibleColumns = ls.getItem('portfolioDocuments') ? JSON.parse(ls.getItem('portfolioDocuments')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, []);

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    columnDefs,
  };
  
  const [
    getDownloadLink, { error: downloadError }
  ] = useLazyQuery(DOCUMENT_DOWNLOAD_LINK);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  }

  const onGridSizeChanged = () => {
    gridApiRef.current.sizeColumnsToFit();
  }

  function transformDocumentsData(data) {
    if (!data) return [];
    return data?.getAllDocumentsFromPortfolio.map((item) => ({
      type: item.documentType,
      name: item.name,
      loan: item.asset?.loanNumber,
      lawFirm: item.asset?.activeProcess?.referral?.team?.name,
      process: item.process,
      uploaded: new Date(+item.uploadedAt),
      uploadedBy: `${item.uploadedBy.firstName} ${item.uploadedBy.lastName}`,
      status: 'Not in use',
      ext: item.extension,
      id: item.id
    }))
  }
  
  function onCellClicked(e) {
    if (e.column.colId === 'settings') return;
    
    const document = gridApiRef.current.getSelectedRows()[0];

    if (document) {
      getDownloadLink({ variables: {id: document.id}}).then(result => {
        const win = window.open(result.data.downloadDocumentLink.downloadLink, "_blank");
        win.focus();
      })
    }
  }

  if (loading) return (
    <TableSkeleton columnDefs={myColsDef} />
  );
  if (downloadError) {
    addMessage({ message: "The server has encountered an error. Please try again or come back later.", type: "error" })
  }
  return (
    <TableContainer sx={{ height: '100%', border: `1px solid ${theme.themeColor.sectionStroke}` }}>
      {/* <Button sx={{mb: 5, width: 200}}>Upload</Button> */}
      <StandardGrid
        tableName="portfolioDocuments"
        onGridReady={onGridReady}
        rowData={items}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={components}
        onCellClicked={onCellClicked}
      />
    </TableContainer>
  )
}

export default DocumentList;
import { gql } from "@apollo/client";

export const GET_STAFF_TEMPLATES = gql`
  query FindStaffTemplates {
    findStaffTemplates {
      id
      name
      description
      subtasks
      deleted
      createOnMilestone
      createOnMilestoneID
      states
      formTemplate
      showToEveryone
      global
      subcategory
      processType
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`
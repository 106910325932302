import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Container, Box, useTheme, Grid } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PORTFOLIO_API_TOKENS } from "../../apollo/queries/getApiTokens";
import { rgba } from "emotion-rgba";
import { GENERATE_PORTFOLIO_TOKEN } from "../../apollo/mutations/generatePortfolioToken";
import { MessageContext } from "../../context/MessageContext";
import SplitButton from "../../styled/SplitButton/SplitButton";
import { UPDATE_PORTFOLIO_TOKEN } from "../../apollo/mutations/updatePortfolioToken";
import Modal from "../../styled/Modal/Modal";
import ExternalApiDocumentation from "./ExternalApiDocumentation";

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  width: 92vw !important;
  padding: 40px;
  margin-bottom: 20px;
`
const StyledContainer = styled(Container)`
  width: 100% !important;
  height: 75vh;
  margin: 0;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`
const DateSpan = styled('span')`
  color: ${({theme}) => theme.themeColor.bodyMain};
`

const Time = styled('span')`
  color: ${({theme}) => rgba(theme.themeColor.bodyMain, 0.4)};
`

export default function APIKeys() {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const [showModal, setShowModal] = useState(false);

  const { loading, data } = useQuery(GET_PORTFOLIO_API_TOKENS);
  const [createApiToken, { loading: loadingApiToken }] = useMutation(GENERATE_PORTFOLIO_TOKEN, {
    refetchQueries: [
      { query: GET_PORTFOLIO_API_TOKENS },
    ],
  });
  const [updateApiToken, { loading: loadingUpdateApiToken }] = useMutation(UPDATE_PORTFOLIO_TOKEN, {
    refetchQueries: [
      { query: GET_PORTFOLIO_API_TOKENS },
    ],
  });

  const onCreateApiToken = async () => {
    try {
      const newToken = await createApiToken();

      if (!newToken.data)
        addMessage({type: 'error', message: 'Something went wrong.'});
    } catch (e) {
      console.error('Error creating API Token', e);
      addMessage({type: 'error', message: 'Something went wrong.'});
    }
  }

  const handleClick = async (id, action) => {
    try {
      await updateApiToken({ variables: { updatePortfolioTokenId: id, enabled: action === 'Enable' } });
    } catch (e) {
      console.error('Error updating API Token', e);
      addMessage({type: 'error', message: 'Something went wrong.'});
    }
  }

  const getItems = (id, enabled) => {
    if (enabled)
      return [{ label: "Disable", color: "red", onClick: () => handleClick(id, 'Disable') }];
    
    return [{ label: "Enable", color: "green", onClick: () => handleClick(id, 'Enable') }];
  }

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <StyledContainer theme={theme}>
      <StyledBox spacing={1}>
        <Title type="h4">API Keys</Title>
        <Grid container justifyContent="flex-end" sx={{mb: 2, width: '100%'}}>
          <Button
            variant="contained"
            color="primary"
            sx={{width: '250px'}}
            loading={loadingApiToken}
            disabled={loadingApiToken}
            onClick={onCreateApiToken}
          >
            Create API Key
          </Button>
        </Grid >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} loading={loading}>
            <TableHead>
              <TableRow>
                <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Token</TableCell>
                <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Created At</TableCell>
                <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Last used at</TableCell>
                <TableCell sx={{backgroundColor: 'black', color: 'white'}} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getPortfolioApiTokens.length == 0 && <Typography sx={{mb: 2, mt: 2}}>No API Tokens created.</Typography>}
              {data?.getPortfolioApiTokens.map((apiToken) => {
                const createdAt = new Date(+apiToken.createdAt);
                const createdAtDatetime = createdAt.toLocaleString();
                const [createdDate, createdTime] = createdAtDatetime.split(', ');
                
                let lastUsedAtDatetime;
                let lastUsedDate;
                let lastUsedTime;
                
                if (apiToken.lastUsedAt) {
                  const lastUsedAt = new Date(+apiToken.lastUsedAt);
                  lastUsedAtDatetime = lastUsedAt.toLocaleString();
                  [lastUsedDate, lastUsedTime] = lastUsedAtDatetime.split(', ');
                }
              
                return (
                  <TableRow
                    key={apiToken.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{
                      maxWidth: '250px',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word'
                    }}>
                      {apiToken.token}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{maxWidth: '100px'}}>
                      <DateSpan theme={theme}>{createdDate}</DateSpan><Time> @ {createdTime}</Time>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{maxWidth: '100px'}}>
                      {apiToken.lastUsedAt ? <><DateSpan theme={theme}>{lastUsedDate}</DateSpan><Time> @ {lastUsedTime}</Time></> : 'Never used'}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{maxWidth: '100px'}} align="right">
                      <SplitButton 
                        disabled={loadingUpdateApiToken}
                        label={apiToken.enabled ? 'Enabled' : 'Disabled'}
                        color={apiToken.enabled ? 'green' : 'red'}
                        items={getItems(apiToken.id, apiToken.enabled)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={{mt: 4, width: '100%'}}>
          <Button onClick={handleOpen} variant="contained" color="primary" sx={{width: '150px'}}>Documentation</Button>
          <Modal open={showModal} onClose={handleClose} width="90%">
            <ExternalApiDocumentation />
          </Modal>
        </Grid >
      </StyledBox>
    </StyledContainer>
  );
}

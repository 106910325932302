import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { gql, useLazyQuery } from "@apollo/client";

const GET_USER_INFO = gql`
  query GET_USER_INFO($id: ID!) {
    getUserInfo(id: $id) {
      id
      firstName
      lastName
      userType
      tncStatus
      email
      stripeInvoiceURL
    }
  }
`;

export default function InvoicePayment() {
  const { user, setUser } = useContext(UserContext);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);

  async function handleRefresh(){
    const userInfoResponse = await getUserInfo({
      variables: {
        id: user.userID,
      },
    });

    if (userInfoResponse.data) {
      setUser({
        token: user.token,
        userID: user.userID,
        ...userInfoResponse.data.getUserInfo,
      });
    }
  }

  return (
    <div>
      <h1><a href={user.stripeInvoiceURL}>Please click here to pay the pending invoice to continue using the app</a></h1>
      <h3><a onClick={() => handleRefresh()}>If you paid, click here to refresh</a></h3>
    </div>
  )
}
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import React from "react";

const DateSpan = styled('span')`
  color: ${({theme}) => theme.themeColor.bodyMain};
`

const Time = styled('span')`
  color: ${({theme}) => rgba(theme.themeColor.bodyMain, 0.4)};
`

const TableDateTime = ({ value, ...props }) => {
  if (!value) return <span />;
  const theme = useTheme();

  const d = new Date(+value);
  const datetime = d.toLocaleString();

  const [date, time] = datetime.split(', ');

  return (
    <span {...props}><DateSpan theme={theme}>{date}</DateSpan><Time> @ {time}</Time></span>
  );
}

export default TableDateTime;
import { Box, Tooltip } from "@mui/material";
import React from "react";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const TaskDescription = ({ value }) => {
  if (!value) return <span />;

  return (
    <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
      <Tooltip
        title={value}
        placement="top"
      >
        <ChatBubbleIcon fontSize="12px" />
      </Tooltip>
    </Box>
  )
}

export default TaskDescription;
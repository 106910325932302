import { gql } from "@apollo/client";

export const GET_DOCUMENTS_FROM_PORTFOLIO = gql`
  query GetAllDocumentsFromPortfolio($portfolioID: ID!) {
    getAllDocumentsFromPortfolio(portfolioID: $portfolioID) {
      id
      documentType
      name
      extension
      process
      uploadedAt
      uploadedBy {
        firstName
        lastName
        id
      }
      asset {
        id
        loanNumber
        activeProcess {
          id
          referral {
            team {
              name
            }
          }
        }
        processes {
          referral {
            team {
              name
            }
          }
        }
      }
    }
  }
`
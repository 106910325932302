import { gql } from "@apollo/client";

export const IMPORT_LOAN_LIST_MUTATION = gql`
  mutation ImportLoanList($portfolioID: ID!) {
    importLoanList(portfolioID: $portfolioID) 
  }
`;

export const IMPORT_LOANS_DETAILS_MUTATION = gql`
  mutation ImportLoanListDetails($portfolioId: ID!, $loansList: [JSONObject]) {
    importLoanListDetails(portfolioID: $portfolioId, loansList: $loansList)
  }
`;

export const IMPORT_LOANS_PROPERTIES_MUTATION = gql`
  mutation ImportLoanListWithProperties($portfolioId: ID!, $loanListWithDetails: [JSONObject]) {
    importLoanListWithProperties(portfolioID: $portfolioId, loanListWithDetails: $loanListWithDetails)
  }
`;

export const IMPORT_LOANS_TO_JONAH = gql`
  mutation ImportLoanListToJonah($portfolioId: ID!, $loanListWithProperties: [JSONObject]) {
    importLoanListToJonah(portfolioID: $portfolioId, loanListWithProperties: $loanListWithProperties) {
      data
    }
  }
`;
import React, { useContext } from "react";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import { REFERRALS_BY_TEAM_QUERY } from "../../apollo/queries/referralsByTeamQuery";
import { REJECT_REFERRAL_MUTATATION } from "../../apollo/mutations/rejectReferral";
import { ACCEPT_REFERRAL_MUTATATION } from '../../apollo/mutations/acceptReferral';
import { REFERRALS_BY_PORTFOLIO_QUERY } from "../../apollo/queries/referralsByPortfolioQuery";
import { ASSETS_QUERY } from "../../apollo/queries/assetsQuery";
import { WITHDRAW_REFERRAL } from "../../apollo/mutations/withdrawReferral";
import { GET_TEAMS } from "../../apollo/queries/getTeams";
import { MY_TASKS_QUERY } from "../../apollo/queries/myTasksQuery";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from '../../context/UserContext';
import SplitButton from "../../styled/SplitButton/SplitButton";
import Button from '../../styled/Button/Button';
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { TEAM_ASSETS_QUERY } from "../../apollo/queries/teamAssetsQuery";

export default function AssetReferralStatus({ ...rest }) {
  const theme = useTheme();

  const referralID = rest.data.id;
  const { assetID, processIDs } = rest.data;
  const { addMessage } = useContext(MessageContext);
  const { user, teamID, portfolioID } = useContext(UserContext);
  const { userType } = user;
  const { referralStatus, seenByCreator } = rest.data;
  const [rejectReferral, { loading }] = useMutation(REJECT_REFERRAL_MUTATATION);
  const [acceptReferral, { loading: acceptLoading }] = useMutation(ACCEPT_REFERRAL_MUTATATION);
  const [withdrawReferral] = useMutation(WITHDRAW_REFERRAL);

  async function handleAccept() {
    try {
      const resp = await acceptReferral({
        variables: {
          teamID,
          referralID,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: REFERRALS_BY_TEAM_QUERY,
            variables: { teamID: teamID },
          },
          {
            query: GET_TEAMS
          },
          {
            query: MY_TASKS_QUERY
          },
          {
            query: TEAM_ASSETS_QUERY,
            variables: { teamID }
          }
        ],
      })
      if (!resp || resp.errors) {
        addMessage({ message: "Something went wrong while accepting the referral.", type: "error"})
      } else {
        addMessage({ message: "Referral accepted." })
      }
    } catch (error) {
      addMessage({ message: 'The server has encountered a problem while accepting this referral. Please try again or come back later.', type: "error" })
    }
  }

  async function handleDecline() {
    try {
      const resp = await rejectReferral({
        variables: {
          referralID,
        },
        refetchQueries: [
          {
            query: REFERRALS_BY_TEAM_QUERY,
            variables: { teamID },
          },
        ],
      });

      if (!resp || resp.errors) {
        addMessage({ message: "Something went wrong while rejecting the referral.", type: "error" })
      } else {
        addMessage({ message: "Referral rejected." })
      }
    } catch (error) {
      addMessage({ message: 'The server has encountered a problem while rejecting this referral. Please try again or come back later.', type: "error" })
    }
  }

  async function handleWithdraw() {
    try {
      await withdrawReferral({
        variables: {
          referralID,
          assetID,
          processIDs,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ASSETS_QUERY,
            variables: {
              portfolioID,
              limit: 100,
              start: 0
            }
          },
          {
            query: REFERRALS_BY_PORTFOLIO_QUERY,
            variables: { portfolioID }
          }
        ]
      })

      addMessage({ message: "Referral successfully withdrawn" })
    } catch (error) {
      addMessage({ message: 'The server has encountered a problem while withdrawing this referral. Please try again or come back later.', type: "error" })
    }
  }

  const goToAsset = () => {
    if (rest?.data) {
      const win = window.open(`/assets/${assetID}`, "_blank");
      win.focus();
    } 
  }

  const buttonGroup = (value) => {
    if (value === "REJECTED" || value === "ACCEPTED") {
      return '';
    }

    if (value === "REJECTED") {
      <SplitButton 
        label={'Rejected'}
        color='red'
        items={[]}
      />
    } 
    else if (value === "ACCEPTED") {
      <SplitButton 
        label={'Accepted'}
        color='black'
        items={[
          { label: 'Withdraw', onClick: () => console.log("Withdraw"), color: 'red'}
        ]}
      />
    }

    return (
      <div className="asset-referral-status">
        <Box>
          <Button sx={{ width: 100 }} onClick={handleAccept}>
            Accept
          </Button>
          <Button sx={{ ml: 2, width: 100 }} variant="secondary" onClick={handleDecline}>
            Decline
          </Button>
        </Box>
      </div>
    );
  };

  const statusGroup = (value, seenByCreator) => {
    if (value === "REJECTED") {
      return (
        <Button
          sx={{
            backgroundColor: `${theme.themeColor.brandPrimaryRed} !important`,
            width: '125px',
            height: '35px !important',
            display: 'flex',
            justifySelf: 'center',
            marginLeft: '10px',
          }}
          onClick={goToAsset}
        >
          Rejected
        </Button>
      );
    }

    else if (value === "PENDING") {
      return (
        <SplitButton 
          label={'Pending'}
          color='ltGray'
          items={[
            { label: 'Withdraw', onClick: handleWithdraw, color: 'red'}
          ]}
        />
      );
    }
    
    return (
      <SplitButton 
        label={'Accepted'}
        color={seenByCreator === false ? 'blue' : 'black'}
        items={[
          { label: 'Withdraw', onClick: handleWithdraw, color: 'red'}
        ]}
      />
    );
    // return value;
  };

  if (loading || acceptLoading) return <CircularProgress />;
  if (userType === "PORTFOLIO_MANAGER") return statusGroup(referralStatus, seenByCreator);
  if (userType === "TEAM_MANAGER") return buttonGroup(referralStatus, teamID);

  return null;
}
import { useLazyQuery } from "@apollo/client";
import { Box, Card, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DOCUMENT_DOWNLOAD_LINK } from "../../apollo/queries/downloadLink";
import Button from "../../styled/Button/Button";
import Typography from "../../styled/Typography/Typography";
import DocumentUpload from "../Modals/DocumentUpload";

const FormFieldFile = ({ assetID, processID, milestoneID, field, file }) => {
  const [showUpload, setShowUpload] = useState(false);
  const [eventTitle, setEventTitle] = useState('');

  useEffect(() => {
    if (field) setEventTitle(`Upload file for ${field.label}`);
  }, [field]);

  const [
    getDownloadLink
  ] = useLazyQuery(DOCUMENT_DOWNLOAD_LINK);

  const onClickDownload = (id) => {
    getDownloadLink({ variables: {id: id}}).then(result => {
      const win = window.open(result.data.downloadDocumentLink.downloadLink, "_blank");
      win.focus();
    })
  }

  const onReupload = () => {
    setEventTitle(`Re-upload file for ${field.label}`);
    setShowUpload(true)
  }

  return (
    <>
      <DocumentUpload
        open={showUpload}
        assetID={assetID}
        processID={processID}
        milestoneID={milestoneID}
        onClose={() => setShowUpload(false)}
        fieldID={field.id}
        eventTitle={eventTitle}
      />
      {!file && (
        <>
          <InputLabel>{field.label}</InputLabel>
          <Button variant="secondary" onClick={() => setShowUpload(true)}>Upload file</Button>
        </>
      )}
      {file && (
        <Card sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>
            <Typography><strong>{file.name}</strong></Typography>
            <Typography>{file.description}</Typography>
          </p>
          <Box sx={{ width: 300, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button sx={{ mr: 1 }} onClick={() => onClickDownload(file.id)}>Download</Button>
            <Button onClick={onReupload}>Change file</Button>
          </Box>
        </Card>
      )}
    </>
  )
}

export default FormFieldFile;
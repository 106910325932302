import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import SquareIcon from '@mui/icons-material/Square';
import { blue } from '@mui/material/colors';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function CustomRating(props) {
  return (
    <Box
      sx={{
        '& > legend': { mt: 4 },
      }}
    >
      <StyledRating
        {...props}
        name="customized-color"
        defaultValue={0}
        getLabelText={(value) => `${value} Strength${value !== 1 ? 's' : ''}`}
        precision={1}
        readOnly
        icon={<SquareIcon sx={{ color: blue[500], fontSize: '12px' }}/>}
        emptyIcon={<SquareIcon sx={{ color: blue[100], fontSize: '12px' }}/>}
      />
    </Box>
  );
}
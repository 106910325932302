import { ToastContainer, toast } from "react-toastify";
import { Slide } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./messages.scss";

import React from "react";
import { useEffect } from "react";

const configure = (message) => {
  let config = {
    transition: Slide
  }
  if ('time' in message) {
    config["autoClose"] = message.time
  }
  return config;
}

export default function Messages({
  messages = [],
  time = 1500,
  removeMessage
}) {
  useEffect(() => {
    if (messages) {
      messages.forEach((message) => {
        if (message.type) {
          toast[message.type](message.message, configure(message));
        } else {
          toast(message.message, configure(message));
        }
        removeMessage(message)
      });
    }
  });

  return (
    <ToastContainer className="messages"
      theme="dark"
      position="top-right"
      autoClose={time}
      hideProgressBar={true}
      newestOnTop={false}
      rtl={false}
    />
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, 
  Box, 
  FormControl, 
  OutlinedInput, 
  CircularProgress, 
  Typography, 
  Stack,
  InputAdornment,
  IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '../../styled/Button/Button'
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { GET_PORTFOLIOS } from '../../apollo/queries/getPortfolios';
import { GET_TEAMS } from "../../apollo/queries/getTeams";
import { useLazyQuery, useMutation } from '@apollo/client';
import { passwordStrength } from 'check-password-strength';
import CustomRating from '../../styled/Rating/Rating';
import { UPDATE_ACOUNT_INFO_MUTATION } from '../../apollo/mutations/updateAccountInfoMutation';
import { MessageContext } from '../../context/MessageContext';
import { useLocalStorage } from "../../hooks/useLocalStorage";

const StyledContainer = styled(Container)`
  width: 100%;
  margin: 0;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`
const StyledBox = styled(Box)`
  width: 50%;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  width: 92vw;
  padding: 40px;
  gap: 16px;
  margin-bottom: 20px;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  width: 30%;
`
const StyledInput = styled(OutlinedInput)`
  width: 30ch;
  border-radius: 4px;
  height: 40px;

  fieldset {
    border-color: ${({ theme }) => theme.themeColor.bodySecondary};
  }
`

const Label = styled('span')`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
`

const INITIAL_FORM_STATE = {
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  oldPassword: '',
  password: '',
  companyId: '',
};
const INITIAL_ERROR_STATE = {
  company: false,
  firstName: false,
  lastName: false,
  email: false,
  oldPassword: false,
  password: false,
};

export default function UserSettings({ user }) {
  const userType = user.userType;
  const theme = useTheme();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const [isTeam, setIsTeam] = useState(false);
  const [updateAccountInfo, { loading: updateLoading }] = useMutation(UPDATE_ACOUNT_INFO_MUTATION);

  const { addMessage } = useContext(MessageContext);
  const { setValue: setUserLocal } = useLocalStorage("user");

  const [getPortfolioInfo, { loading: portfolioLoading }] =
    useLazyQuery(GET_PORTFOLIOS);
  const [getTeamInfo, { loading: teamLoading }] = 
    useLazyQuery(GET_TEAMS);

  useEffect(() => {
    if (user) {
      const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      };

      setFormData((prev) => ({ ...prev, ...data }))
    }
  }, [user])

  // Gets Team data based on userType
  useEffect(() => {
    const getCompanyOrTeamData = async () => {
      
      if (userType === 'TEAM_MANAGER') {
        const teamData = await getTeamInfo();
        
        if (teamData.data) {
          setIsTeam(true);
          // Set the team the user belongs to if it's a team manager
          const team = teamData.data.findTeams.find(team => team.memberships.some(member => member.user.id === user.id));
          setFormData((prev) => ({ ...prev, company: team.name, companyId: team.id }));
        } else {
          addMessage({type: 'error', message: 'Something went wrong. Try again later.'});
        }
      } else {
        setIsTeam(false);
      }
    }

    getCompanyOrTeamData();
  }, [userType, getPortfolioInfo, getTeamInfo])

  function formValidation() {
    let hasError = false;

    if (isTeam && formData.company.length === 0) {
      setFormError((prev) => ({ ...prev, company: true }));
      userType[0] === "P" ? setErrorMessage("Please enter a company name") : setErrorMessage("Please enter a team name")
      hasError = true;
    } else {
      setFormError((prev) => ({ ...prev, company: false }));
      setErrorMessage("");
    }

    if (formData.firstName.length === 0) {
      setFormError((prev) => ({ ...prev, firstName: true }));
      setErrorMessage("Please enter your first name");
      hasError = true;
    } else {
      setFormError((prev) => ({ ...prev, firstName: false }));
    }

    if (formData.lastName.length === 0) {
      setFormError((prev) => ({ ...prev, lastName: true }));
      setErrorMessage("Please enter your last name");
      hasError = true;
    } else {
      setFormError((prev) => ({ ...prev, lastName: false }));
    }

    if (formData.email.length === 0) {
      setFormError((prev) => ({ ...prev, email: true }));
      setErrorMessage("Please enter a valid email address");
      hasError = true;
    } else {
      setFormError((prev) => ({ ...prev, email: false }));
    }

    if (formData.password && !formData.oldPassword) { 
      setFormError((prev) => ({ ...prev, password: true }));
      setErrorMessage("You need to inform your current password to change it");
      hasError = true;
    } else {
      setFormError((prev) => ({ ...prev, sessionTimeout: false }));
    }

    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    
    if (!formValidation()) {
      try {
        const updatedUser = await updateAccountInfo({
          variables: {
            input: {
              id: user.id,
              ...formData
            },
          },
        });
        if (updatedUser.data.updateAccountInfo) {
          const newUser = {
            ...user,
            firstName: updatedUser.data.updateAccountInfo.firstName,
            lastName: updatedUser.data.updateAccountInfo.lastName,
          }

          setUserLocal(newUser);
          setIsSubmitted(true);
        } else {
          addMessage({type: 'error', message: updatedUser.errors[0].message});
        }
      } catch (error) {
        addMessage({type: 'error', message: 'Something went wrong. Try again later.'});
      }
    }
  }

  function handleChange(event) {
    const {name, value} = event.target

    if (name == "password") {
      if (value == "") setPasswordStrengthValue(0)
      else {
        const newStrength = passwordStrength(value).id + 1;
        setPasswordStrengthValue(newStrength);
      }
    }


    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  if (teamLoading || portfolioLoading) return <CircularProgress />

  return (
    <StyledContainer theme={theme}>
      <StyledBox spacing={2} theme={theme}>
        <Title type="h4">Personal</Title>
        <Stack spacing={2}>
          {isTeam && (
            <StyledFormControl>
              <Label>{userType[0] === 'P' ? 'Company ' : 'Team '}</Label>
              <StyledInput placeholder='Company Name' name="company" value={formData.company} onChange={(e) => handleChange(e)}
                helperText={formError.company ? errorMessage.company : ''}
              />
            </StyledFormControl>
          )}

          <StyledFormControl>
            <Label>First Name </Label>
            <StyledInput placeholder='First Name' name="firstName" value={formData.firstName} onChange={(e) => handleChange(e)}
              helperText={formError.firstName ? errorMessage.firstName : ''}
            />
          </StyledFormControl>

          <StyledFormControl>
            <Label>Last Name </Label>
            <StyledInput placeholder='Last Name' name="lastName" value={formData.lastName} onChange={(e) => handleChange(e)}
              helperText={formError.lastName ? errorMessage.lastName : ''}
            />
          </StyledFormControl>

          <StyledFormControl>
            <Label>E-mail </Label>
            <StyledInput placeholder='E-mail' disabled name="email" value={formData.email} onChange={(e) => handleChange(e)}
              helperText={formError.lastName ? errorMessage.lastName : ''}
            />
          </StyledFormControl>
        </Stack>
      </StyledBox>
      
      <StyledBox spacing={2}>
        <Title type="h4">Security</Title>

        <Stack spacing={2}>
          <StyledFormControl>
            <Label>Current Password </Label>
            <StyledInput
              placeholder='Your current password'
              type={showCurrentPassword ? "text" : "password"}
              name="oldPassword" onChange={(e) => handleChange(e)}
              helperText={formError.oldPassword ? errorMessage.oldPassword : ''}
              endAdornment={
                <InputAdornment position="end" sx={{ marginRight: "-10px" }}>
                  <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledFormControl>

          <StyledFormControl>
            <Label>New Password </Label>
            <StyledInput
              placeholder='New password you want to use'
              type={showNewPassword ? "text" : "password"}
              name="password" onChange={(e) => handleChange(e)}
              helperText={formError.password ? errorMessage.password : ''}
              endAdornment={
                <InputAdornment position="end" sx={{ marginRight: "-10px" }}>
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledFormControl>
          <div style={{width: '30%', display: 'flex', justifyContent: 'flex-end', marginTop: '-4px'}}>
            <CustomRating value={passwordStrengthValue} max={4}/>
          </div>
        </Stack>

      </StyledBox>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Button
          sx={{ width: '20ch', marginRight: '25px' }}
          disabled={(formData.password && passwordStrengthValue < 4) || updateLoading}
          loading={updateLoading}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save
        </Button>
        {isSubmitted && <span style={{color: 'gray', fontSize: '.75em'}}>Saved!</span>}
      </div>
    </StyledContainer>
  )
}
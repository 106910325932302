import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { HOLDS_QUERY } from "../../apollo/queries/holdsQuery";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import "./milestone-holds.scss";
import { UPDATE_HOLD_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateHoldAndEventsMutation";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import { Accordion, AccordionSummary, AccordionDetails, CircularProgress, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from "@emotion/styled";
import Button from '../../styled/Button/Button';
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";
import { rgba } from "emotion-rgba";
import CustomProgress from "../MilestoneTasks/CustomProgress";
import CommentIcon from "../CommentIcon/CommentIcon";
import HoldComments from "./HoldComments";

const POLL_INTERVAL = parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL);

const HoldButton = styled(Button)`
  width: auto;
  height: 25px;
  margin-left: 10px;
`

function MilestoneHold({ hold, milestoneID, process, asset, className, readOnly }) {
  const theme = useTheme();
  const [showHoldComments, setShowHoldComments] = useState(false);

  const { addMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);
  const { id, holdType, holdDescription, closeDate, expectedCloseDate, hold_comments } = hold;

  const [updateHold] = useMutation(UPDATE_HOLD_AND_EVENTS_MUTATION, {
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID: process.id } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID: process.id } },
      { query: ASSET_QUERY, variables: { assetID: asset.id, userID: user.userID } }
    ],
  });

  const comments = hold_comments ? hold_comments.length : 0;

  async function handleCloseHold() {
    const hold = {
      active: false,
      closeDate: new Date(),
    };

    const event = {
      type: "hold-closed",
      holdType,
      createdDate: new Date(),
      description: holdDescription,
      completedDate: new Date(),
      process: process.id,
      milestoneID,
    };
    try {
      await updateHold({
        variables: {
          hold: { where: { id: id }, data: hold },
          event,
        },
      });
    } catch (error) {
      addMessage({ message: "Unable to close current hold. Please refresh and try again.", type: "error" })
    }

  }

  async function handleReopenHold() {
    const hold = {
      active: true,
      closeDate: null
    };

    const event = {
      type: "hold-reopened",
      holdType,
      description: holdDescription,
      dueBy: new Date(+expectedCloseDate),
      createdDate: new Date(),
      milestoneID,
      process: process.id,
    }

    try {
      await updateHold({
        variables: {
          hold: { where: { id: id }, data: hold },
          event,
        },
      });
    } catch (error) {
      addMessage({ message: "Unable to reopen current hold. Please refresh and try again.", type: "error" })
    }
  }

  const disableClose = (e) => e.stopPropagation();

  // Need to revisit with David
  // function isClosed() {
  //   return hold.closeDate
  //     ? formatDate(hold.closeDate)
  //     : formatDate(hold.expectedCloseDate);
  // }

  const closeHoldDisabled = readOnly
    || hold.holdType === "BK";

  return (
    <AccordionDetails
      className={className}
      onClick={disableClose}
      sx={{display: 'flex', justifyContent: 'space-between', height: '50px'}}
    >
      <div
        style={{height: '50% !important', width: '50%', alignSelf: 'center'}}
      >
        {/* <span style={{ marginRight: '10px' }}>#</span> */}
        <Typography
          noWrap
          sx={{
            textDecoration: closeDate ? `line-through solid 1px ${theme.themeColor.bodySecondary}` : 'none'
          }}
        >{hold.holdType}</Typography>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {/* <Tooltip
          title={
            closeDate ? `Completed on ${formatDate(closeDate)} days` :
            `Due by ${formatDate(expectedCloseDate)}`
          }
          placement="top"
        >
          <CalendarIcon fontSize="sm"/>
        </Tooltip> */}
        <CommentIcon className={`${className}-comment`} value={comments} onClick={() => setShowHoldComments(true)} />
        
        <HoldComments 
          open={showHoldComments}
          hold={hold}
          milestoneID={milestoneID}
          processID={process?.id}
          comments={hold_comments}
          onClose={() => setShowHoldComments(false)}
          readOnly={readOnly}
        />

        {closeDate ? (
          <div>
            <HoldButton
              variant="contained"
              onClick={handleReopenHold}
              sx={{backgroundColor: 'gray !important'}}
              disabled={readOnly || closeHoldDisabled}
            >
              Reopen
            </HoldButton>
          </div>
        ) : (
          <HoldButton
            className="close-hold-button"
            variant="contained"
            onClick={handleCloseHold}
            type="submit"
            size="sm"
            disabled={closeHoldDisabled}
          >
            Close
          </HoldButton>
        )}
      </div>
    </AccordionDetails>
  )
}

const orderEvents = (data) => {
  if (!data || (!data?.holds || data?.holds.length === 0)) return false;
  const ongoing = [];
  const completed = [];

  data.holds.forEach((item) => {
    if (item.active === false) completed.push(item);
    else ongoing.push(item);
  })

  return { ongoing, completed }
}

export default function MilestoneHolds({ asset, milestoneID, process, readOnly }) {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);
  const [holds, setHolds] = useState([]);

  const { loading, data } = useQuery(HOLDS_QUERY, {
    variables: { processID: process?.id },
    pollInterval: POLL_INTERVAL
  });

  useEffect(() => {
    if (data) {
      setHolds(data)
    }
  }, [data])

  if (loading)
    return (
      <Accordion
        expanded={true}
        sx={{
          height: '40vh',
          backgroundColor: theme.themeColor.sectionFill,
          border: `1px solid ${theme.themeColor.sectionStroke}`,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme.themeColor.backgroundBody,
            borderBottom: `1px solid ${theme.themeColor.sectionStroke}`,
          }}
        />
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress sx={{ marginTop: "35%" }} />
        </Box>
      </Accordion>
    );

  const { ongoing, completed } = orderEvents(holds);

  return (
    <Accordion
      expanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      sx={{
        height: isExpanded ? '40vh' : 'auto',
        backgroundColor: theme.themeColor.sectionFill,
        border: `1px solid ${theme.themeColor.sectionStroke}`,
        overflowY: isExpanded ? 'auto' : 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: theme.themeColor.backgroundBody,
          borderBottom: `1px solid ${theme.themeColor.sectionStroke}`,
        }}
      >
        {/* <Typography>
          Holds:<Typography component="span" number> {ongoing?.length || 0} / {data.holds?.length}</Typography>
        </Typography> */}
        <Box sx={{width: '90%', display: 'flex', justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <CustomProgress value={(1 - ((ongoing?.length || 0) / holds.holds?.length)) * 100} isClosed={!isExpanded} />
            <Typography fontWeight="500" color={theme.themeColor.bodyMain}>Holds</Typography>
          </Box>
          <Typography component="span" number> <span style={{
            fontWeight: 500,
            color: theme.themeColor.bodyMain
          }}>{ongoing?.length || 0}</span> <span style={{
            fontWeight: 500,
            color: rgba(theme.themeColor.bodyMain, 0.4),
          }}>/ {holds.holds?.length}</span></Typography>
        </Box>
      </AccordionSummary>
      {
        ongoing && ongoing.map((hold, i) => (
          <MilestoneHold
            className={`ongoinghold-${i}`}
            key={hold.id}
            hold={hold}
            asset={asset}
            milestoneID={milestoneID}
            holdComment={hold.holdComment}
            process={process}
            readOnly={readOnly}
          />
        ))
      }
      {
        completed && completed.map((hold, i) => (
          <MilestoneHold
            className={`completedhold-${i}`}
            key={hold.id}
            hold={hold}
            asset={asset}
            milestoneID={milestoneID}
            holdComment={hold.holdComment}
            process={process}
            readOnly={readOnly}
          />
        ))
      }
    </Accordion>
  )
}


import { gql } from "@apollo/client";

export const HOLDS_QUERY = gql`
  query GetHolds($processID: ID!) {
    holds(sort: "created_at:desc", where: { process: { id: $processID } }) {
      id
      holdDescription
      holdType
      holdComment
      startDate
      closeDate
      expectedCloseDate
      createdInMilestone
      active
      createdBy {
        firstName
        lastName
      }
      hold_comments {
        createdAt
        comment
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

import React, { useContext } from 'react';
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import { Tooltip, Box } from '@mui/material';
import Button from '../../styled/Button/Button';
import styled from '@emotion/styled';
import { UPDATE_ASSET_WATCHLIST } from "../../apollo/mutations/updateAssetWatchlist";
import { useMutation } from '@apollo/client';
import { UserContext } from '../../context/UserContext';
import { GET_USER_WATCHLIST } from "../../apollo/queries/getUserWatchlist";

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`

export default function UpdateAssetWatchlist({ open, onClose, user, assets }) {
  const { setUser } = useContext(UserContext);

  const toAdd = assets.filter(asset => !user?.watchlist?.includes(asset.id));
  const toRemove = assets.filter(asset => user?.watchlist?.includes(asset.id));

  const [updateAssetWatchlist] = useMutation(UPDATE_ASSET_WATCHLIST, {
    refetchQueries: [GET_USER_WATCHLIST],
    awaitRefetchQueries: true
  });

  const updateWatchlist = async () => {
    const res = await updateAssetWatchlist({
      variables: {
        assetIDs: assets.map(asset => asset.id)
      }
    });

    setUser(prev => ({...prev, watchlist: res.data.updateAssetWatchlist.watchlist}))
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} width="33vw">
      <Typography variant="h4" component="h1">Update Asset Watchlist</Typography>
      {
        toAdd.length > 0 && (
          <Typography mt={3}>
            Adding <Tooltip placement="top" title={toAdd.map(asset => asset.loanNumber).join(', ')}><span>{ toAdd.length } { toAdd.length > 1 ? 'assets' : 'asset' }</span></Tooltip> to Watchlist
          </Typography>
        )
      }
      {
        toRemove.length > 0 && (
          <Typography mt={3}>
            Removing <Tooltip placement="top" title={toRemove.map(asset => asset.loanNumber).join(', ')}><span>{ toRemove.length } { toRemove.length > 1 ? 'assets' : 'asset' }</span></Tooltip> from Watchlist
          </Typography>
        )
      }
      
      <ButtonBox>
        <Button 
          onClick={updateWatchlist}
          sx={{width:130, marginRight: '25px'}}
        >
          Continue
        </Button>

        <Button
          variant="secondary"
          onClick={onClose}
          sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
        >
          Cancel
        </Button>
      </ButtonBox>
    </Modal>
  )
}

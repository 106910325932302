import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@mui/material';
import { rgba } from 'emotion-rgba';

const Circle = styled('div')`
  background: ${(props) => {
  if (props.disabled) return rgba(props.theme.themeColor.bodyTetriary);
  if (props.variant === 'gray') return rgba(props.theme.themeColor.bodyMain, 0.15);  
  return 'transparent'
}};
  border: ${(props) => {
  if (props.variant === 'light') return `1px solid ${props.theme.themeColor.backgroundBody}`;
  if (props.disabled) return `1px solid ${rgba(props.theme.themeColor.bodyMain, 0.3)}`;
  if (props.variant === 'gray') return 'none';
  return `1px solid ${props.theme.themeColor.brandPrimaryBlue}`;
}};
  color: ${(props) => {
  if (props.disabled) return rgba(props.theme.themeColor.bodyMain, 0.3);
  if (props.variant === 'gray') return rgba(props.theme.themeColor.bodyMain, 0.65);
  if (props.variant === 'light') return props.theme.themeColor.backgroundBody;
  return props.theme.themeColor.brandPrimaryBlue;
}};
  font-size: ${(props) => props.fontSize ? props.fontSize : '16px'};
  border-radius: 50%;
  height: ${(props) => {
  if (props.size) return props.size;
  return '35px'
}};
  width: ${(props) => {
  if (props.size) return props.size;
  return '35px'
}};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: ${(props) => props.theme.themeFonts.main};
`

export default function InitialsCircle (props) {
  const theme = useTheme();
  const { initials, fullName } = props;

  return (
    <Tooltip
      title={fullName}
      placement="top"
      data-testid="initials-tooltip"
    >
      <Circle
        data-testid="circle"
        { ...props }
        theme={theme}
      >
        <span data-testid="initials-circle">{ initials }</span>
      </Circle>
    </Tooltip>
  );
}
import { gql } from "@apollo/client";

export const GET_TEAMS = gql`
  query Query($input: findTeamsInput) {
    findTeams(input: $input) {
      id
      name
      createdAt
      type

      invitations {
        status
      }

      memberships {
        id
        user {
          id
          username
          firstName
          lastName
          userType
        }
        type
      }

      portfolio {
        id
        name
        deleted
        teamsInPortfolio {
          id
          type
          name
        }
      }

      assets {
        id
        loanNumber
        deleted
        activeProcess {
          id
        }
      }
    }
  }
`;
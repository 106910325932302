import React from 'react';
import { Link } from 'react-router-dom';
import { FaFileUpload, FaDatabase } from "react-icons/fa";
import CircleIcon from '../CirlcleIcon/CircleIcon';

import "./getting-started.scss"

export default function GettingStarted() {
  return (
    <div className='getting-started'>
      <div className='headers'>
        <h1>Get Started Now</h1>
        <h3>You do not have any assets in your portfolio.</h3>
      </div>

      <div className='icons'>
        <Link to='/import-assets'>
          <CircleIcon>
            <FaFileUpload />
          </CircleIcon>
          Import Assets
        </Link>
        <Link to='/add-api'>
          <CircleIcon>
            <FaDatabase />
          </CircleIcon>
          Link API
        </Link>
      </div>

    </div>
  )
}
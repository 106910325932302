import React from 'react';
import { Box } from '@mui/material';
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
`;
const ConfirmButton = styled(Button)`
  width: auto;
  margin-right: 25px;
`;
const CancelButton = styled(Button)`
  background-color: transparent !important;
  width: auto;
  border: none;
  box-shadow: none;
  margin-left: 15px;
`;

const FinalBillConfirmation = ({ open, onClose, completeFinalBill }) => {
  const theme = useTheme();

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="body1" mt={1} mb={5} color={theme.themeColor.bodyMain}>
        Completing this task will finalize the bill and complete this process. Are you sure you want to continue?
      </Typography>

      <ButtonBox>
        <ConfirmButton onClick={completeFinalBill}>Continue</ConfirmButton>
        <CancelButton
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </CancelButton>
      </ButtonBox>
    </Modal>
  )
};

export default FinalBillConfirmation;
import { gql } from "@apollo/client";

export const COMMENT_TASK_MUTATION = gql`
  mutation createTaskComment($input: createTaskCommentInput!) {
    createTaskComment(input: $input) {
      id
      createdAt
      comment
      createdBy {
        firstName
        lastName
      }
    }
  }
`;
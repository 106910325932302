import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($emailToken: String!) {
    confirmEmail(emailToken: $emailToken) {
      id
      email
      confirmed
    }
  }
`

const ConfirmEmail = () => {
  const { emailToken } = useParams();
  const history = useHistory();

  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    variables: { emailToken }
  });

  useEffect(() => {
    if (!emailToken)
      history.push("/auth/login");

    confirmEmail().then((result) => {
      if (result.data.confirmEmail.confirmed) {
        history.push("/auth/login?confirmedEmail=true");
      }
    });
  }, [confirmEmail]);

  return (
    <Box sx={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />;
    </Box>
  );
}

export default ConfirmEmail;
import React from "react";
import { LoadingButton as MUIButton } from '@mui/lab';
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { rgba } from 'emotion-rgba';

const StyledButton = styled(MUIButton)`
  border-radius: 4px;
  line-height: 20px;
  color: ${(props) => {
  if (props.disabled) return rgba(props.theme.themeColor.bodyMain, 0.4);
  if (props.isSecondary) return rgba(props.theme.themeColor.bodyMain, 0.6);
      
  return props.theme.themeColor.textColor
}};
  cursor: ${(props) => props.isDisabled ? 'not-allowed' : 'pointer'};

  background: ${(props) => {
  if (props.disabled) return rgba(props.theme.themeColor.bodyMain, 0.2);
  if (props.isSecondary) return rgba(props.theme.themeColor.bodyMain, 0.05);  
  return props.theme.themeColor.brandPrimaryBlue
}};

  &:hover {
    background: ${(props) => {
  if (props.isSecondary) return rgba(props.theme.themeColor.bodyMain, 0.1);
      
  return props.theme.themeColor.brandPrimaryDarkBlue
}};
  box-shadow: ${(props) => props.isSecondary && 'none'};
  }

  padding: ${(props) => props.isSecondary ? '.125em 0.6em' : '0.4em 1em'};
  height: ${(props) => props.isSecondary ? '1.8em' : '40px'};
`

const Button = (props) => {
  const { icon, variant, children, iconPosition } = props;
  const theme = useTheme();
  
  return (
    <StyledButton
      {...props}
      variant="contained"
      isSecondary={variant === 'secondary'}
      startIcon={iconPosition === "left" ? icon : null}
      endIcon={iconPosition === "right" ? icon : null}
      theme={theme}
    >
      {children}
    </StyledButton>
  );
};

export default Button;


import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import * as XLSX from 'xlsx/xlsx.mjs';
import { useMutation } from '@apollo/client';
import { UPLOAD_INFLIGHT_LOANS } from '../../apollo/mutations/uploadInflightLoans';
import Typography from '../../styled/Typography/Typography';

// Excel stores its date as # days since Jan 1, 1990, need to convert into real date
const excelEpoc = new Date(1900, 0, -1).getTime();
const msDay = 86400000;
const excelDateToJS = (excelDate) => new Date(excelEpoc + excelDate * msDay)

// Checks for errors in extracted data
const checkErrors = (loanData) => {
  let assetErrors = false, processErrors = false;

  const loanNums = [];
  loanData.assetSheet.forEach((asset) => {
    if (!asset.loanNumber || !asset.state )
      assetErrors = true;
    if (loanNums.includes(asset.loanNumber))
      assetErrors = true;
    else 
      loanNums.push(asset.loanNumber);
  })

  loanData?.processSheet?.forEach((process) => {
    if (!process.processType || !process.referral || !process.referralEmail || !process.processStartDate)
      processErrors = true;
  })
  return assetErrors && processErrors;
}

// Makes strings camelCase
const camelize = (str) => 
  str.replace(/(?:^\w|[A-Z]|\b\w)/g, 
    (word, index) => index === 0
      ? word.toLowerCase() 
      : word.toUpperCase()
  ).replace(/\s+/g, '');

// Renames keys to camelCase
const renameKeys = obj => Object
  .entries(obj)
  .reduce((acc, [key, val]) => {
    const modifiedKey = camelize(key.toLowerCase());
    return ({
      ...acc,
      ...{ [modifiedKey]: val },
    });
  }, {});

// Checks the datatype of values and changes them to ones the backend is expecting
const changeValueTypes = (fileData) => {
  const strings = ["loanNumber", "currentValue", "postal"];
  const dates = ["paidThroughDate", "nextPaymentDueDate", "processStartDate", "holdStartDate", 
    "holdExpectedCloseDate", "holdCloseDate", "completedDate", "stepStartDate", "stepCloseDate"
  ];

  const typeChecked = fileData.map((row) => {
    for (const key in row) {
      if (key === "stepNumber") {
        typeof row[key] === "string"
          ? row.stepID = Number(row[key]).toFixed(2).toString()
          : row.stepID = String(row[key].toFixed(2));
        delete row[key];
      }
      if (key === "paidThroughDate" || key === "nextPaymentDueDate")
        row[key] = String(excelDateToJS(row[key]));
      if (strings.includes(key))
        row[key] = String(row[key]);
      if (dates.includes(key))
        row[key] = excelDateToJS(row[key])
    }
    return row;
  });
  return typeChecked;
};

// Formats file in a shape that is easy for backend to parse
const formatFile = (fileData) => {
  const copy = [...fileData];
  const formatted = copy.map((entry) => renameKeys(entry));
  const typeChecked = changeValueTypes(formatted);

  const backendFormatted = {};
  typeChecked.forEach((entry) => {
    if (entry.sheetType === "Asset Import") {
      delete entry.sheetType;
      backendFormatted.assetSheet ? backendFormatted.assetSheet.push(entry) : backendFormatted.assetSheet = [entry];
    } else if (entry.sheetType === "Process Import") {
      delete entry.sheetType;
      backendFormatted.processSheet ? backendFormatted.processSheet.push(entry) : backendFormatted.processSheet = [entry];
    }
  })

  backendFormatted.processSheet = backendFormatted?.processSheet?.sort((a, b) => a.processStartDate - b.processStartDate);
  return backendFormatted;
};

export default function InFlightLoans() {
  const [loanData, setLoanData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [hasError, setHasError] = useState(false);

  const [uploadMutation, { loading }] = useMutation(UPLOAD_INFLIGHT_LOANS, {
    skip: !loanData || Array.isArray(loanData),
  })

  const upload = async () => {
    try {
      const response = await uploadMutation({
        variables: { input: loanData },
      });
      setUploadResponse(response.data.uploadInflightLoans);
    } catch(err) {
      setHasError(true);
    }
  }

  const readFile = (file) => {
    const reader = new FileReader();
  
    const fileData = [];
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
  
      workbook.SheetNames.forEach((sheetName) => {
        const xlSheet = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        // Removes rows that include the list of types from the sheet
        const filtered = xlSheet.filter((entry) => Object.keys(entry).length !== 0 && Object.keys(entry).length !== 1);
        filtered.forEach((row) => {
          row["Sheet Type"] = sheetName
          if (row["List of Processes"])
            delete row["List of Processes"]
          if (row["List of types"])
            delete row["List of types"]
        });
        fileData.push(...filtered);
      })
      const formatted = formatFile(fileData);
      if (!checkErrors(formatted))
        setLoanData(formatted);
      else {
        setHasError(true);
      }
    };
  
    reader.onerror = (e) => console.error("File could not be read! Code " + e.target.error.code); 
    reader.readAsBinaryString(file);
  }

  const onFileUpload = (e) => {
    setIsUploading(true);
    setLoanData([]);

    const file = e.target.files[0];
    readFile(file);
    setIsUploading(false);
  }

  useEffect(() => {
    if ((loanData && !Array.isArray(loanData)) && !isUploading) {
      upload(loanData);
    }
  }, [loanData])

  return (
    <Container sx={{ height: "89.5vh" }}>
      <input type="file"
        id="fileUploader"
        name="fileUploader"
        accept=".xls, .xlsx"
        onChange={onFileUpload}
        disabled={loading}
      />
      { loading &&
        <div>
          <Typography>Uploading...</Typography>
        </div>
      }
      { uploadResponse &&
        <div>
          <Typography>{uploadResponse.length} assets have been uploaded.</Typography>
          <Typography>It may take a few minutes for your portfolio to reflect the upload.</Typography>
        </div>
      }
      { hasError &&
        <div>
          <Typography>There was an error uploading the file. Please try again.</Typography>
        </div>
      }
    </Container>
  )
}
import React, { useCallback, useState } from 'react';
import { InputAdornment, IconButton, InputBase, Box } from '@mui/material';
import styled from '@emotion/styled';
import { FaTimes } from 'react-icons/fa';
import { rgba } from 'emotion-rgba';
import { useTheme } from '@emotion/react';

const StyledTextField = styled(InputBase)`
  border-radius: 8px;
  font-size: 12px;
  height: 32px;
  background-color: ${({ theme }) => rgba(theme.themeColor.bodyMain, 0.1)};
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 0 8px;
  margin: 0 8px;
`
const StyledContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  width: 100%;
`


const GridSearch = ({ ...rest }) => {
  const { api } = rest;
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState("");

  const onTextInput = useCallback((e) => {
    const value = e.target.value;
    api.setQuickFilter(value);
    setSearchValue(value);
  }, [api])

  const onCloseSearch = () => {
    setSearchValue("");
    api.setQuickFilter("");
  }

  return (
    <StyledContainer>
      <StyledTextField
        fullWidth
        id="filter-text-box" 
        placeholder='Search' 
        theme={theme} 
        onChange={onTextInput} 
        autoFocus 
        value={searchValue}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onCloseSearch}
              edge="end"
              data-testid="close-search-button"
            >
              <FaTimes />
            </IconButton>
          </InputAdornment>
        } />
    </StyledContainer>
  )
};

export default GridSearch;
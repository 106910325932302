import { gql } from "@apollo/client";

export const UPDATE_TASK_MUTATION = gql`
  mutation UpdateTask(
    $task: updateTaskInput!
  ) {
    updateTask(input: $task) {
      id
    }
  }
`;

// Code based on this:
// https://stripe.com/docs/payments/save-and-reuse?platform=web&html-or-react=react

import React, { useState, useEffect } from "react";
import { useStripe, Elements, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { gql, useQuery } from "@apollo/client";
import CardDetailsPaymentStatus from "./CardDetailsPaymentStatus";
import Modal from "../../styled/Modal/Modal";
import ProcessingScreen from "../ProcessingScreen/ProcessingScreen";
import './card-details.scss';
import Button from '../../styled/Button/Button';

export const GET_STRIPE_CLIENT_SECRET = gql`
  query stripeClientSecret {
    getStripeClientSecretCurUser{
      clientSecret
      stripeCustomerID
    }
  }
`;

const SetupForm = ({/*onCloseImportModal,*/ setIsSavingCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsSavingCard(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_REDIRECT}/payment`,
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsSavingCard(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement className="payment-element" />
      <p className="error">{errorMessage}</p>
      <div className="payment-buttons">
        <Button type="submit" className="save">Save</Button>
        {/* <Button variant="secondary" className="skip" onClick={onCloseImportModal} >Skip</Button> */}
      </div>
    </form>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// if clientSecret already taken from URL then pass it here and deal with the Status
export default function CardDetails({ clientSecret }) {

  const { data } = useQuery(GET_STRIPE_CLIENT_SECRET);
  const [isReady, setIsReady] = useState(false);
  const [options, setOptions] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [isSavingCard, setIsSavingCard] = useState(false);
  const theme = window.localStorage.getItem('theme') || "light"

  useEffect(() => {
    if (!data) return;
    setOptions({
      // passing the client secret
      clientSecret: data.getStripeClientSecretCurUser.clientSecret,
      // Fully customizable with appearance API.
      // https://stripe.com/docs/stripe-js/appearance-api
      appearance: {
        theme: theme == "light" ? "stripe" : "night"
      },
    })
    setIsReady(true);
  }, [data]);

  const onModalClose = () => {
    setShowModal(false);
  }

  if (!isReady) return (<></>)

  return (
    <div>
      <ProcessingScreen open={isSavingCard} label='Saving' />
      <Modal open={showModal && !isSavingCard} width="25rem">
        <div className="cc-container">
          <h2>Add Payment Method</h2>
          <p>
            Add a valid payment method to ensure your service is uninterrupted
          </p>
          <Elements className={"element " + (theme == "light" ? "pe-light" : "pe-dark")} stripe={stripePromise} options={options}>
            {clientSecret ? <CardDetailsPaymentStatus clientSecret={clientSecret} onCloseImportModal={onModalClose} setIsSavingCard={setIsSavingCard} /> : <SetupForm onCloseImportModal={onModalClose} setIsSavingCard={setIsSavingCard} />}
          </Elements>
        </div>
      </Modal>
    </div>
  );
}

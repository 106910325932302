import React from "react";
import { useTheme } from "@emotion/react";
import { CircularProgress } from "@mui/material";

const CustomProgress = ({ value, isClosed }) => {
  const theme = useTheme();
  return (
    <div>
      <CircularProgress sx={{
        color: theme.themeColor.bodyMain,
        mr: 2,
        position: 'absolute',
        top: isClosed ? '18px' : '26px',
        left: '16px',
        zIndex: 1,
      }} variant="determinate" value={value} size="12px"thickness={5} />
      <CircularProgress sx={{
        color: theme.themeColor.borderColor,
        mr: 2,
      }} variant="determinate" value={100} size="12px" />
    </div>
  )
}

export default CustomProgress;
import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      message
      type
      redirectUrl
      read
      createdAt
    }
  }
`;
import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import DocumentList from '../components/DocumentList/DocumentList';
import Typography from '../styled/Typography/Typography';

export default function Documents() {
  const { user } = useContext(UserContext);

  if (user?.accDeleted || user?.noTeamsOrPortfolio) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      {user.userType === "PORTFOLIO_MANAGER"
        ? "Your account has been deleted"
        : "You do not currently belong to a team or portfolio."
      }
    </Typography>
  )
  
  return ( 
    <div className="documents-list py-3">
      <DocumentList />
    </div>
  );
}

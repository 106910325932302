import React from "react";

const ExternalApiDocumentation = () => {
  return (
    <iframe src="asciidoc/bulk_api.html"
      title="External API Documentation"
      style={{
        height: "80vh",
        width: "100%",
        margin: "auto",
        border: ".5px solid gray",
        borderRadius: "5px",
        backgroundColor: "white",
      }}
    />

  )
}

export default ExternalApiDocumentation;
import { gql } from "@apollo/client";

export const TASKS_QUERY = gql`
  query GetTasks($processID: ID!) {
    tasks(where: { process: { id: $processID } }) {
      id
      title
      description
      completedAt
      createdAt
      deadlineAt
      dueByMilestone
      blocking
      deleted
      assignments {
        id
        firstName
        lastName
      }
      owner {
        id
        firstName
        lastName
      }
      task_comments {
        id
        createdAt
        comment
        createdBy {
          firstName
          lastName
        }
      }
      forms {
        id
        title
        form_fields {
          id
          label
          type
          value
          required
          options
          condition
          order
          permissions
          document {
            id
            name
            documentType
          }
        }
      }
    }
  }
`;
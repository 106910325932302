import React, { useContext } from "react";
import { Backdrop, Fade, Box, Modal as MUIModal } from '@mui/material'
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FaTimes } from 'react-icons/fa';
import { UserContext } from "../../context/UserContext";

const CloseModalArea = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyledModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width || "40vw"};
  height: ${(props) => props.height || 'auto'};
  max-height: 90vh;
  max-width: 90vw;
  border: 2px solid ${({ theme }) => theme.themeColor.sectionStroke};
  border-radius: 6px;
  boxShadow: 20;
  padding: 32px;
  background: ${({ theme }) => theme.themeColor.backgroundBody};
  outline: 0;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
`

const CloseButton = styled(FaTimes)`
  cursor: pointer;
`

const Modal = (props) => {
  const { children, open, width, height, onClose } = props;
  const theme = useTheme();
  const user = useContext(UserContext);

  if (user?.user?.timeoutOpen)
    return (<></>)

  return (
    <MUIModal
      {...props}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        backdropFilter: 'blur(8px)',
        overflowY: 'scroll',
      }}
      data-testid="modal-styled"
    >
      <Fade in={open}>
        <StyledModal height={height} width={width} theme={theme} data-testid="modal-body" sx={props?.sx}>
          {onClose && (
            <CloseModalArea>
              <CloseButton onClick={onClose} />
            </CloseModalArea>
          )}
          {children}
        </StyledModal>
      </Fade>
    </MUIModal>
  )
}

export default Modal;


import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { useTheme } from "@emotion/react";

function TableHeader() {
  const theme = useTheme();

  const headers = [
    { name: "Jonah Direct Destination Field", value: "destination" },
    { name: "Example", value: "example" },
    { name: "File Field", value: "fieldName" },
  ];

  return (
    <TableRow>
      {
        headers.map(({ name, value }) => (
          <TableCell
            key={value}
            align="left"
            sx={{ 
              backgroundColor: theme.themeColor.sectionFill,
              body: theme.themeColor.bodySecondary,
              color: theme.themeColor.textColor,
              fontWeight: 800
            }}
          >
            {name}
          </TableCell>
        ))
      }
    </TableRow>
  );
}

export default TableHeader;

import React from 'react';
import { Typography as MUITypography} from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const Text = styled(MUITypography)`
  font-family: ${(props) => props.number && props.theme.themeFonts.complimentary};
`

export default function Typography(props) {
  const theme = useTheme();
  return <Text {...props} theme={theme} />
}
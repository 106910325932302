import React, { createContext, useState } from "react";

export const MessageContext = createContext();

export default function MessageProvider({ children }) {
  const [messages, setMessages] = useState([]);

  function addMessage(message) {
    setMessages(messages.concat(message));
  }

  function removeMessage(inputMessage) {
    const copy = messages.map((message) => ({ ...message }));
    const toRemove = copy.map((message) => message.message).indexOf(inputMessage.message)
    copy.splice(toRemove, 1);
    setMessages(copy);
  }

  return (
    <MessageContext.Provider value={{ addMessage, messages, removeMessage }}>
      {children}
    </MessageContext.Provider>
  );
}

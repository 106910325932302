import { gql } from "@apollo/client";

export const REGISTER_TEAM_USER = gql`
  mutation RegisterTeamUser($input: registerTeamUserInput!) {
    registerTeamUser(input: $input) {
      jwt
      refresh
      user {
        id
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION(
    $token: String!
    $password: String!
  ) {
    resetPassword(
      token: $token
      password: $password
    ) {
      id
    }
  }
`;

import { gql } from "@apollo/client";

export const UPDATE_TASK_AND_EVENTS_MUTATION = gql`
  mutation UpdateTaskAndLog(
    $task: updateTaskInput!
    $event: createEventInput!
  ) {
    updateTask(input: $task) {
      id
    }

    createEvent(input: $event) {
      id
    }
  }
`;

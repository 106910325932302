import { gql } from "@apollo/client";

export const DOCUMENT_DOWNLOAD_LINK = gql`
  query GetDocumentDownloadLink($id: ID!) {
    downloadDocumentLink(documentID: $id) {
      id
      downloadLink
    }
  }
`;


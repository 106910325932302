import React, { createContext, useState, useMemo, useEffect } from "react";

export const AssetImportContext = createContext();

const filterIgnoreFields = [
  "id",
  "assigned",
  "created_at",
  "created_by",
  "updated_at",
  "updated_by",
  "dataSource",
  "tasks",
  "holds",
  "comments",
  "events",
  "hasApi",
  "lastImport",
  "lawFirm",
  "portfolio",
  "budgetDaysToSale",
  "daysInDlq",
  "deleted",
  "billingStatus",
  "activeProcess",
  "activeProcessID",
  "saleScheduledDate",
  "portfolioID",
  "billingId",
  "assignedID",
  "statusValue",
  "loan_status",
  "processes",
  "referral",
  "referralStatus",
  "lastImport",
  "hasApi",
  "processIDs",
];

export default function AssetImportProvider({ children }) {
  const [file, setMewFile] = useState(null);
  const [data, setNewData] = useState("");
  const [analysis, setNewAnalysis] = useState(null);
  const [target, setNewTarget] = useState(null);
  const [nanFields, setNewNanFields] = useState([]);
  const filteredAttributes = useMemo(
    () => {
      if (target !== null)
        return target.map((field) => ({name: field.column_name, example: field.example})).filter(({ name }) => !filterIgnoreFields.includes(name));
    },
    [target]
  );

  const [destinationOptions, setNewDestinationOptions] = useState([])
  const [mappedFields, setNewMappedFields] = useState(null);

  const setFile = (file) => setMewFile(file);
  const setData = (data) => setNewData(data);
  const setAnalysis = (analysis) => setNewAnalysis(analysis);
  const setTarget = (target) => setNewTarget(target);
  const setNanFields = (fields) => setNewNanFields(fields);
  const setDestinationOptions = (filteredAttributes) => {
    return setNewDestinationOptions(
      [{ label: "none", value: "none", example: "Example" }].concat(
        filteredAttributes.map(({name, example}) => ({ label: name, value: name, example }))
      )
    )
  }
  const setMappedFields = () => {
    const { fieldsInfo } = analysis;
    const fields = {};

    Object.keys(fieldsInfo).forEach((field) => {
      const fieldName = filteredAttributes.find(({ name }) => name === field)?.name;
      const { format } = fieldsInfo[field];
      const targetField = fieldName ? fieldName : "none";
      const targetFormat = format;

      fields[field] = { format, targetField, targetFormat };
    });


    return setNewMappedFields(fields);
  }

  useEffect(() => {
    if (analysis !== null)
      setMappedFields()

    if (filteredAttributes)
      setDestinationOptions(filteredAttributes)
  }, [analysis, filteredAttributes])

  return (
    <AssetImportContext.Provider value={{
      file,
      data,
      analysis,
      target,
      mappedFields,
      filteredAttributes,
      destinationOptions,
      nanFields,
      setFile,
      setData,
      setAnalysis,
      setTarget,
      setMappedFields,
      setNanFields,
      setNewMappedFields
    }}>
      {children}
    </AssetImportContext.Provider>
  );
}

import React, { useState, useRef, useContext } from 'react';
import axios from "axios";
import { useQuery, useMutation } from '@apollo/client';
import { DOCUMENTS_BY_ASSET_QUERY } from '../../apollo/queries/documentsByAssetQuery';
import { CREATE_DOCUMENT_MUTATION } from '../../apollo/mutations/createDocumentMutation';
import { CREATE_EVENT_MUTATION } from '../../apollo/mutations/createTaskAndEventsMutation';
import { HOLDS_QUERY } from '../../apollo/queries/holdsQuery';
import { EVENTS_QUERY } from '../../apollo/queries/eventsQuery';
import { UserContext } from '../../context/UserContext';
import { MessageContext } from '../../context/MessageContext';
import Modal from '../../styled/Modal/Modal';
import Button from '../../styled/Button/Button';
import { TextInput } from '../../styled/TextInput/TextInput';
import Typography from '../../styled/Typography/Typography';
import { Box, CircularProgress, FormControl, FormHelperText, MenuItem, Select, TextField, InputLabel } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { formatDocumentName } from '../../helpers';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { TASKS_QUERY } from '../../apollo/queries/tasksQuery';
import { TASK_FLOWS_QUERY } from '../../apollo/queries/taskFlowsQuery';
import { ACTIVITY_TASKS_QUERY } from '../../apollo/queries/activityTasksQuery';
import { ACTIVITY_TASK_FLOWS_QUERY } from '../../apollo/queries/activityTaskFlowsQuery';
import { GET_ACTIVITY_EVENTS } from '../../apollo/queries/getActivityEvents';

const INITIAL_FORM_STATE = {
  name: "",
  // description: "",
  type: undefined,
  process: undefined,
  file: null
}

const INITIAL_ERROR_STATE = {
  name: false,
  type: false,
  process: false,
};

const errorMessages = {
  name: "Please provide a document name.",
  type: "Please select document type",
  process: "Please select a process",
};

const eventsData = (documents, activity, processID, milestoneID, eventTitle, originalFC) => {
  const docNames = {};
  documents.forEach((doc) => (
    docNames[doc.process]
      ? docNames[doc.process].push(doc.name)
      : docNames[doc.process] = [doc.name]
  ))

  const event = {
    type: "document-upload",
    createdDate: new Date(),
  }

  const activityEvent = docNames["activity"] && {
    activity: activity.id,
    title: eventTitle ? eventTitle : `Uploaded ${docNames["activity"].length} ${docNames["activity"].length < 2 ? 'document' : 'documents'}`,
    description: typeof docNames === 'string' ? docNames["activity"] : docNames["activity"].join(', '),
    ...event
  };
  const fcEvent = docNames["foreclosure"] && {
    process: originalFC ? originalFC.id : processID,
    title: eventTitle ? eventTitle : `Uploaded ${docNames["foreclosure"].length} ${docNames["foreclosure"].length < 2 ? 'document' : 'documents'}`,
    description: typeof docNames === 'string' ? docNames["foreclosure"] : docNames["foreclosure"].join(', '),
    milestoneID: originalFC ? originalFC.stepID : milestoneID,
    ...event
  };
  const bkEvent = docNames["bankruptcy"] && {
    process: processID,
    title: eventTitle ? eventTitle : `Uploaded ${docNames["bankruptcy"].length} ${docNames["bankruptcy"].length < 2 ? 'document' : 'documents'}`,
    description: typeof docNames === 'string' ? docNames["bankruptcy"] : docNames["bankruptcy"].join(', '),
    milestoneID,
    ...event
  };
  return { activityEvent, fcEvent, bkEvent }
}

const DropFileZone = ({ setFiles, setFormData, setFormError, disabled }) => {
  const fileInput = useRef(null);
  const { addMessage } = useContext(MessageContext);

  async function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = ({ target: { result } }) => resolve(result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  const preUploadFiles = async (files) => {
    try {
      files.forEach(async (file) => {
        await readFileContent(file)
        setFiles((prev) => [...prev, file]);

        await setFormError(prev => [...prev, INITIAL_ERROR_STATE]);

        const initialForm = { ...INITIAL_FORM_STATE };
        initialForm.file = file;
        initialForm.name = file.name;
        initialForm.extension = file.name.split('.').pop();
        await setFormData(prev => [...prev, initialForm]);
      })
    } catch (err) {
      const plural = files.length > 1;
      addMessage({ message: `Unable to read the contents of ${plural ? "these files" : "this file"}.`, type: "error" })
    }
  };

  const handleFileChange = ({ target: { files } }) => preUploadFiles([...files]);
  const stopDragEvent = (event) =>
    event.preventDefault() && event.stopPropagation();
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    preUploadFiles([...files]);
  };

  return (
    <label>
      <TextInput
        onDrop={handleDrop}
        id='upload-document-dropzone'
        onDragOver={stopDragEvent}
        onClick={() => fileInput.current?.click()}
        type='text'
        placeholder='Choose a file to upload...'
        sx={{width: '100%'}}
        disabled={disabled}
      />
      <input
        type='file'
        multiple='multiple'
        ref={fileInput}
        onChange={handleFileChange}
        onDrop={handleDrop}
        onDragEnter={() => fileInput.current.handleDragEnter}
        onDragOver={stopDragEvent}
        style={{visibility: 'hidden'}}
        disabled={disabled}
      />
    </label>
  )
}

const DocumentsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`
const DocumentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`
const Spinner = styled(CircularProgress)`
  color: ${({theme}) => theme.themeColor.bodyTetriary};
  padding: 7px;
  justify-self: center;
  align-self: center;
`
const CheckIcon = styled(CheckCircleRoundedIcon)`
  color: ${({theme}) => theme.themeColor.brandPrimaryGreen};
  height: 32px;
  width: 32px;
  justify-self: center;
  align-self: center;
`
const ErrorIcon = styled(ErrorRoundedIcon)`
  color: ${({theme}) => theme.themeColor.brandPrimaryRed};
  height: 32px;
  width: 32px;
  justify-self: center;
  align-self: center;
`

const DocumentRow = ({ docTypes, index, formData, setFormData, formError, promises, availableProcesses }) => {
  const theme = useTheme();
  function handleChange(event) {
    const formCopy = formData.map(item => ({...item}));

    formCopy[index][event.target.name] = event.target.value;
    setFormData(formCopy)
  }
  const [openDescription, setOpenDescription] = useState(false);

  if (availableProcesses.length === 1) {
    formData[index].process = availableProcesses[0];
  }
  
  return (
    <DocumentContainer>
      <Typography number={true} sx={{alignSelf: 'center', justifySelf: 'center'}}>{index + 1}</Typography>

      <FormControl error={formError[index].name}>
        <TextField
          name="name"
          variant='outlined'
          label={
            formData[index].file.name
          }
          onChange={handleChange}
          placeholder={
            formData[index].file.name
          }
        />
        {formError[index].name && (
          <FormHelperText>{errorMessages.name}</FormHelperText>
        )}
      </FormControl>

      <FormControl sx={{width: '30%'}} error={formError[index].type}>
        <InputLabel>Type *</InputLabel>
        <Select
          id={`${formData[index].file.name}-type`}
          className="document-type-dropdown"
          label="Type *"
          name="type"
          value={formData[index].type}
          onChange={handleChange}
        >
          {
            docTypes.map((type) => {
              return <MenuItem key={type.name} value={type.name}>{formatDocumentName(type.name)}</MenuItem>
            })
          }
        </Select>
        {formError[index].type && (
          <FormHelperText>{errorMessages.type}</FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{width: '30%'}} error={formError[index].process}>
        <InputLabel>Process *</InputLabel>
        <Select
          id={`${formData[index].file.name}-process`}
          label="Process *"
          name="process"
          className="document-process-dropdown"
          value={formData[index].process}
          onChange={handleChange}
        >
          {
            availableProcesses.map((process) => (
              <MenuItem key={process} value={process}>{formatDocumentName(process)}</MenuItem>
            ))
          }
        </Select>
        {formError[index].process && (
          <FormHelperText>{errorMessages.process}</FormHelperText>
        )}
      </FormControl>

      <FormControl sx={{width: '5%', display: 'flex', alignSelf: 'center'}} error={formError[index].description}>
        <ChatBubbleIcon
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenDescription(true)}
        />
        <Modal open={openDescription} onClose={() => setOpenDescription(false)}>
          <Typography component="h1" variant="h4">File Description</Typography>
          <TextField
            name="description"
            variant="outlined"
            label="Description or notes"
            fullWidth
            onChange={handleChange}
            placeholder="Please add a description or notes and press enter to add it to the file"
            sx={{ marginTop: '5%' }}
            multiline
            rows={3}
            value={formData[index].description || ""}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleChange(e);
                setOpenDescription(false);
              }
            }}
          />
        </Modal>
      </FormControl>

      {(promises.length > 0 && promises[index].status === 'pending') && <Spinner theme={theme} thickness={6}/>}
      {(promises.length > 0 && promises[index].status === 'rejected') && <ErrorIcon theme={theme} />}
      {(promises.length > 0 && promises[index].status === 'fulfilled') && <CheckIcon theme={theme} />}
    </DocumentContainer>
  )
}

const DocumentRows = ({ docTypes, formData, setFormData, formError, files, promises, availableProcesses }) => {
  return (
    <DocumentsContainer>
      {
        formData.length === files.length && files.map((entry, i) => {
          return (
            <DocumentRow
              availableProcesses={availableProcesses}
              key={`${entry.name}-${i}`}
              docTypes={docTypes}
              index={i}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              promises={promises}
            />
          )
        })
      }
    </DocumentsContainer>
  )
};

export default function DocumentUpload({ open, onClose, assetID, processID, milestoneID, fieldID, eventTitle, availableProcesses, activity, originalFC }) {
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState([]);
  const [promises, setPromises] = useState([]);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const { user } = useContext(UserContext);

  const reset = () => {
    setFiles([]);
    setFormData([]);
    setFormError([]);
    setPromises([]);
    setDocumentsUploaded(false);
  }
  const handleClose = () => {
    onClose();
    reset();
  }

  const {
    data: dataDocuments,
  } = useQuery(DOCUMENTS_BY_ASSET_QUERY, {
    variables: {
      assetID,
    },
  });
  const createDocumentsRefetch = !processID && activity
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: activity.id } },
      { query: ACTIVITY_TASK_FLOWS_QUERY, variables: { activityID: activity.id } },
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
    ];

  const [createDocument, documentResponse] = useMutation(CREATE_DOCUMENT_MUTATION, {
    refetchQueries: [
      { query: DOCUMENTS_BY_ASSET_QUERY, variables: { assetID } },
      ...createDocumentsRefetch
    ],
  });
  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  function formValidation() {
    let hasError = false;
    const errorCopy = formError.map((item) => ({...item}));

    formData.forEach((doc, i) => {
      if (!doc.type) {
        errorCopy[i].type = true;
        hasError = true;
      } else {
        errorCopy[i].type = false;
      }
      if (!doc.process) {
        errorCopy[i].process = true;
        hasError = true;
      } else {
        errorCopy[i].process = false;
      }
    })

    setFormError(errorCopy);
    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formCopy = formData.map((item) => ({...item}));

    if (!formValidation()) {
      const documentsData = formCopy.map((doc) => ({
        asset: assetID,
        documentType: doc.type,
        extension: doc.extension,
        name: doc.name,
        process: doc.process,
        uploadedAt: new Date(),
        uploadedBy: user.id,
        description: doc.description,
        form_field: fieldID,
      }));

      const { activityEvent, fcEvent, bkEvent } = eventsData(documentsData, activity, processID, milestoneID, eventTitle, originalFC);

      setPromises(await Promise.allSettled(documentsData.map(async (documentData, i) => {
        const createDocumentData = await createDocument(
          { variables: {
            input: documentData
          } 
          });
        
        const form = new FormData();
        form.append('documentID', createDocumentData.data.createDocument.id);
        form.append('file', formData[i].file);

        await axios.post(`${process.env.REACT_APP_NEXT_IMPORT_API_URL}/api/upload-file-version`, form).then((response => {
          console.log(response)
          setDocumentsUploaded(true);
        }));

      })))

      if ((processID && milestoneID) || originalFC) {
        if (fcEvent)
          await createEvent({ variables: { event: fcEvent } }, {
            refetchQueries: [
              { query: HOLDS_QUERY, variables: { processID } },
              { query: EVENTS_QUERY, variables: { milestoneID: fcEvent.milestoneID, processID: fcEvent.process } },
            ]
          })
        if (bkEvent)
          await createEvent({ variables: { event: bkEvent } }, {
            refetchQueries: [
              { query: HOLDS_QUERY, variables: { processID } },
              { query: EVENTS_QUERY, variables: { milestoneID: bkEvent.milestoneID, processID: bkEvent.process } },
            ]
          })
      }
      if (activityEvent)
        await createEvent({ variables: { event: activityEvent } }, {
          refetchQueries: [{ query: GET_ACTIVITY_EVENTS, variables: { activityID: activity.id } }]
        })
    }
  }
      
  return (
    <Modal open={open} width='50vw' height='auto'>
      <Typography variant="h4" component="h2" mb={5}>Upload</Typography>

      <DropFileZone
        setFiles={setFiles}
        setFormData={setFormData}
        setFormError={setFormError}
        disabled={promises.length > 0 || (fieldID && files.length > 0)}
      />

      {files.length > 0 && (
        <DocumentRows
          files={files}
          docTypes={dataDocuments.getDocumentTypes}
          formData={formData}
          setFormData={setFormData}
          formError={formError}
          promises={promises}
          availableProcesses={availableProcesses}
        />
      )}
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Button
          onClick={documentsUploaded ? handleClose : handleSubmit}
          sx={{
            mr: 1,
            position: 'relative',
            width: documentResponse.loading ? '290px' : '220px',
          }}
          disabled={formData.length === 0}
          loading={documentResponse.loading /** || uploadFileVersionResponse.loading **/}
        >
          {documentsUploaded && 'Continue'}
          {documentResponse.loading && 'Waiting for upload to finish'}
          {!documentsUploaded && 'Upload Documents'}
        </Button>
        <Button
          variant="secondary"
          sx={{fontSize: '14px', width: 'auto', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
          onClick={handleClose}
          disabled={documentResponse.loading || promises.length > 0}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  )
}

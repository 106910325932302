import { Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineContent } from "@mui/lab";
import { Skeleton } from "@mui/material";
import React from "react";

const TimelineSkeleton = () => {
  return (
    <Timeline sx={{ color: 'black' }}>
      <TimelineItem>
        <TimelineOppositeContent style={{ maxWidth: '100px' }}>
          <Skeleton variant="text" />
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          <Skeleton variant="text" width={140} />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent style={{ maxWidth: '100px' }}>
          <Skeleton variant="text" />
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          <Skeleton variant="rectangular" width={120} height={60} />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent style={{ maxWidth: '100px' }}>
          <Skeleton variant="text" />
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          <Skeleton variant="text" width={160} />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent style={{ maxWidth: '100px' }}>
          <Skeleton variant="text" />
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          <Skeleton variant="text" width={200} />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}

export default TimelineSkeleton
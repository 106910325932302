import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import Drawer from "../../styled/Drawer/Drawer";
import Typography from "../../styled/Typography/Typography";
import { Box } from "@mui/material";
import Button from "../../styled/Button/Button";
import { MessageContext } from '../../context/MessageContext';
import { GET_TASK_FLOW_TEMPLATES_QUERY } from '../../apollo/queries/getTaskFlowTemplatesQuery';
import { UserContext } from '../../context/UserContext';
import { UPDATE_TASK_FLOW_TEMPLATE_MUTATION } from '../../apollo/mutations/updateTaskFlowTemplateMutation';

const Header = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
`

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  background-color: ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 -25px;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`

const DeleteTaskFlow = ({ taskFlow, show, setShow }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { portfolioID } = useContext(UserContext);

  useEffect(() => {
    setFormData({
      ...taskFlow
    });
  }, [taskFlow]);

  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();

  const [updateTaskFlow, { loading }] = useMutation(UPDATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
    ],
  });

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onCancel = () => {
    setShow(false);
  }

  const handleSubmit = async () => {
    try {
      const resp = await updateTaskFlow({
        variables: { input: {
          where: { id: formData.id },
          data: { 
            deleted: true
          } 
        }},
      });

      if ('errors' in resp) {
        addMessage({ message: "Something went wrong while deleting the task.", type: "error"});
      } else {
        addMessage({ message: 'Task flow template deleted.' });
        setShow(false);
      }
    } catch {
      addMessage({ message: 'Something went wrong. Try again later!', type: 'error' });
    }
  }


  return (
    <Drawer open={open}>
      <Header theme={theme}>
        <Typography fontSize="24px">Delete Task Flow</Typography>
      </Header>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
        <p>Are you sure you want to delete {taskFlow.name} and all {taskFlow.subtasks.length} subtasks?</p>
      </Box>
      <Bottom>
        <Button
          onClick={handleSubmit}
          disabled={formData.subtasks?.length === 0 || loading}
          loading={loading}
          sx={{maxWidth: '120px'}}
        >
          Delete
        </Button>
        <a onClick={onCancel}>Cancel</a>
      </Bottom>
    </Drawer>
  )
}

export default DeleteTaskFlow;
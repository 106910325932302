import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "../apollo/mutations/resetPasswordMutation";
import { Button, TextField, Stack, List, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton } from '@mui/material';
import { containsNumber, containsLowerCase, containsUpperCase, containsSpecialChars } from "../utilities";
import { passwordStrength } from 'check-password-strength';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage/SuccessMessage";
import { FaCheck } from "react-icons/fa";
import styled from "@emotion/styled";

const INITIAL_FORM_STATE = {
  password: "",
  passwordConfirmation: "",
  noMatch: false,
};

const INITIAL_ERROR_STATE = {
  password: false,
  passwordConfirmation: false,
};

const ListIcon = styled(ListItemIcon)`
  min-width: 30px;
`

export default function ResetPassword() {
  const history = useHistory();
  const { token } = useParams();

  const [ResetPasswordMutation, { data, error, loading }] = useMutation(
    RESET_PASSWORD_MUTATION
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);

  function showMessageAndRedirect() {
    setTimeout(() => history.push("/auth/login"), 3000);
    return <SuccessMessage message="Password updated." />;
  }

  function formValidation() {
    let hasError = false;

    if (formData.password.length === 0) {
      setFormError((prevState) => ({ ...prevState, password: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, password: false }));
    }

    if (formData.passwordConfirmation.length === 0) {
      setFormError((prevState) => ({
        ...prevState,
        passwordConfirmation: true,
      }));
      hasError = true;
    } else {
      if (formData.password !== formData.passwordConfirmation) {
        setFormError((prevState) => ({
          ...prevState,
          noMatch: true,
          passwordConfirmation: true,
        }));
        hasError = true;
      } else {
        setFormError((prevState) => ({
          ...prevState,
          noMatch: false,
          passwordConfirmation: false,
        }));
      }
      setFormError((prevState) => ({
        ...prevState,
        passwordConfirmation: false,
      }));
    }

    return hasError;
  }

  function handleChange(event) {
    const {name, value} = event.target

    if (name == "password") {
      if (value == "") setPasswordStrengthValue(0)
      else {
        setPasswordStrengthValue(passwordStrength(value).id + 1);
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!formValidation()) {
      ResetPasswordMutation({
        variables: {
          token,
          password: formData.password,
        },
      });
    }
  }

  return (
    <div className="login">
      <form 
        style={{
          width: '350px',
          padding: '1rem',
        }}
        onSubmit={handleSubmit}>
        <h2>Reset Password</h2>

        <Stack
          sx={{marginTop: '10px'}}  
        >
          <TextField
            label="Password"
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            name="password"
            error={formError.password}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
            sx={{width: '92%', margin: '0 auto'}}
          />

          <TextField
            label="Confirm Password"
            variant="outlined"
            value={formData.passwordConfirmation}
            onChange={handleChange}
            type={showConfirmPassword ? "text" : "password"}
            name="passwordConfirmation"
            error={formError.passwordConfirmation}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
            sx={{width: '92%', margin: '20px auto !important'}}
          />
        </Stack>

        <div>
          <List dense={true}>
            <ListItem>
              <ListIcon sx={{minWidth: '30px'}}>
                <FaCheck sx={{width: "80%"}}/>
              </ListIcon>
              { formData.password.length > 8 ? 
                <s><ListItemText
                  primary="Longer than 8 characters"
                /></s>:
                <ListItemText
                  primary="Longer than 8 characters"
                />
              }
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaCheck />
              </ListIcon>
              { (containsLowerCase(formData.password) && containsUpperCase(formData.password)) ? 
                <s><ListItemText
                  primary="Use lowercase and uppercase letters"
                /></s> : 
                <ListItemText
                  primary="Use lowercase and uppercase letters"
                /> 
              }
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaCheck />
              </ListIcon>
              { containsSpecialChars(formData.password) ? 
                <s><ListItemText
                  primary="Use special symbols"
                /></s> : 
                <ListItemText
                  primary="Use special symbols"
                /> 
              }
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaCheck />
              </ListIcon>
              { containsNumber(formData.password) ?
                <s><ListItemText
                  primary="Use numbers"
                /></s>: 
                <ListItemText
                  primary="Use numbers"
                />
              }
            </ListItem>
          </List>
        </div>

        <Stack 
          spacing={2}
        >
          <div className="align-self-center">
            {(formError.password || formError.passwordConfirmation) &&
              !formError.noMatch && (
              <ErrorMessage error={"Please fill in all fields."} />
            )}

            {formError.noMatch && (
              <ErrorMessage error={"Passwords do not match."} />
            )}

            {error && <ErrorMessage error="Please contact your admin." />}

            {data && !error && showMessageAndRedirect()}
          </div>
        
          <Button
            variant="contained"
            type="submit"
            disabled={!formData.password || (formData.password !== formData.passwordConfirmation) || passwordStrengthValue < 4}
            loading={loading}
            sx={{width: '92%', ml:2}}
          >
            Reset Password
          </Button>
        </Stack>
      </form>
    </div>
  );
}

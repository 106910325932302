import React, { useEffect, useState } from "react";
import CardDetails from "../components/CardDetails/CardDetails";

export default function Payment(){

  const [clientSecret, setClientSecret] = useState(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const cs = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );
    if (cs) setClientSecret(cs)
    setIsReady(true)
  }, [])

  if (!isReady) return (<></>)

  return (
    <div className="centered">
      <CardDetails clientSecret={clientSecret}/>
    </div>
  )
}
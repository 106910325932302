import React, { useContext, useState } from 'react';
import Button from '../styled/Button/Button';
import { Container, CircularProgress } from '@mui/material';
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { UserContext } from '../context/UserContext';
import { gql } from '@apollo/client';
import Modal from '../styled/Modal/Modal';
import { useHistory } from "react-router-dom";

const RESPOND_TO_TNC_MUTATION = gql`
  mutation RESPOND_TO_TNC_MUTATION($user: updateUserInput!) {
    respondToTnc(input: $user) {
      tncStatus
    }
  }
`

export default function TermsAndPrivacy() {
  const { user, setUser } = useContext(UserContext);
  let queryError = false;
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  const [respondToTnc, { loading }] = useMutation(RESPOND_TO_TNC_MUTATION, {
    onCompleted: async (data) => {
      if (data) {
        await setUser((user) => ({ ...user, tncStatus: data.respondToTnc.tncStatus }))
      }
    },
  });

  const handleOnClick = async (status) => {
    setShowModal(false)
    try {
      const request = await respondToTnc({
        variables: {
          user: {
            id: user.id,
            tncStatus: status
          }
        }
      });

      if (request.data?.respondToTnc?.tncStatus === null) {
        queryError = true;
      } else {
        if (user.userType === "PORTFOLIO_MANAGER" && user.paymentMethodAdded !== true && process.env.REACT_APP_USE_ONLY_WITH_CC == "true") {
          history.push('/payment');
        } else {
          history.push('/auth')
        }
      }
    } catch (error) {
      queryError = true;
    }
  }

  if (loading)
    return <CircularProgress />;

  if (user && user.tncStatus === true) return <Redirect to='/auth' />
  if (queryError) return null;

  return (
    <Modal open={showModal} className='tnc-page' width="50rem">
      <h1>Terms and Conditions</h1>
      <p>Please, review and accept our Terms in order to start using Jonah Direct.</p>
      <Container className="tnc-container">
        <TermsAndConditions />
        <PrivacyPolicy />
      </Container>
      <Button data-testid="accept-btn" className='accept-btn' onClick={() => handleOnClick(true)}>Accept</Button>
    </Modal>
  )
}
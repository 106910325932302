import React from "react";
import { Stack, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const StyledCircle = styled("div")`
  background: ${(props) => props.theme.themeColor.lightBlue};
  border: 1px solid ${(props) => props.theme.themeColor.oppositeBodyMain};
  color: ${(props) => props.theme.themeColor.brandPrimaryBlue};
  font-size: 12px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  text-align: center;
`

const AssignmentsBadge = ({ assignments, onClick }) => {
  const theme = useTheme();
  const diff = assignments.length - 2;

  return (
    <Tooltip
      title={assignments.map((a) => `${a.firstName} ${a.lastName}`).join(', ')}
      placement="top"
    >
      <Stack
        spacing={-1}
        direction="row"
        onClick={onClick}
      >
        {assignments.map((assignee, idx) => (
          <>
            {idx < 2 && (
              <StyledCircle key={`${assignee.id}-${idx}`} theme={theme}>
                <span>{assignee.firstName[0]}{assignee.lastName[0]}</span>
              </StyledCircle>
            )}
            {idx === 2 && (
              <StyledCircle key={`${assignee.id}-${idx}`} theme={theme}>
                <span>+{diff}</span>
              </StyledCircle>
            )}
          </>
        ))}
      </Stack>
    </Tooltip>
  )
}

export default AssignmentsBadge
import React, { useContext, useState } from "react";
import { gql } from "@apollo/client";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import { useMutation } from "@apollo/client";
import { GET_PORTFOLIO_USERS_QUERY } from "../../apollo/queries/getPortfolioUsers";
import { GET_TEAMS_BY_MANAGER } from "../../apollo/queries/getTeamsByManager";
import SplitButton from "../../styled/SplitButton/SplitButton";
import Button from "../../styled/Button/Button";
import DeleteUserModal from "./DeleteUserModal";
import { Box } from "@mui/material";

const REINVITE_MEMBER = gql`
  mutation reinviteMember($input: reinviteMemberInput) {
    reinviteMember(input: $input) {
      id
      firstName
      lastName
      memberStatus
      portfolio {
        id
        name
      }
    }
  }
`;

const REMOVE_TEAM_MEMBER = gql`
  mutation RemoveMember($memberID: ID, $membershipID: ID) {
    removeMember(memberID: $memberID, membershipID: $membershipID) {
      user {
        id
      }
    }
  }
`;

export default function UserListDropdown({ value, ...rest }) {  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { addMessage } = useContext(MessageContext);
  const { user, portfolioID } = useContext(UserContext);
  const { userType, firstName, lastName, userID } = user;
  const { username, recipientEmail, teamName, teamID } = rest.data;

  const [reinviteMember] = useMutation(REINVITE_MEMBER);
  const [removeMember] = useMutation(REMOVE_TEAM_MEMBER);

  function getRefetchQuery(userType, portfolioID) {
    if (!portfolioID) return null;
    portfolioID = parseInt(portfolioID.replace(/^"|"$/g, ""), 10);
    if (userType === "PORTFOLIO_MANAGER")
      return [{ query: GET_PORTFOLIO_USERS_QUERY, variables: { portfolioID } }];
    if (userType === "TEAM_MANAGER")
      return [{ query: GET_TEAMS_BY_MANAGER, variables: { userID: userID } }];
    return null;
  }

  const message = `Hello! Please accept this invitation to join the team ${teamName}.`;

  const refetchQuery = getRefetchQuery(userType, portfolioID);

  const onClickHandler = async (option) => {
    if (option === "delete") {
      setShowDeleteModal(false);
      try {
        const response = await removeMember({
          variables: {
            memberID: parseInt(rest.data.userID, 10),
            membershipID: parseInt(rest.data.id, 10),
          },
          refetchQueries: refetchQuery,
        });

        if (response && response.errors) {
          response.errors.forEach((item) =>
            addMessage({ message: item.message })
          );
        } else {
          addMessage({ message: "Successfully removed user!" });
        }
        
      } catch (error) {
        addMessage({
          message:
            "Currently unable to remove this team member. Please try again or come back later.",
          type: "error",
        });
      }
    }
    if (option === "resend") {
      const id = teamID || portfolioID;

      const input = {
        userType: "MEMBER",
        id,
        message,
        UserInput: {
          username: recipientEmail,
          email: recipientEmail,
          firstName,
          lastName,
          memberStatus: "PENDING",
          confirmed: true,
        },
        recipientID: rest.data.id,
      };

      try {
        await reinviteMember({
          variables: { input },
        });

        addMessage({ message: "Successfuly reinvited member." });
      } catch (error) {
        addMessage({
          message:
            "Currently unable to reinvite member. Please try again or come back later.",
          type: "error",
        });
      }
    }
    return;
  };

  const onClickDelete = () => {
    setShowDeleteModal(true);
  }

  if (value === 'Invite') {
    return (
      <Button
        type="submit"
        sx={{height: '35px !important', width: '75%', boxShadow: 'none'}}
      >
        Invite
      </Button>
    )
  }

  const getOptions = (value) => {
    if (value === 'PENDING') {
      return [
        { label: 'Re-send', onClick: () => onClickHandler("resend")},
        // { label: 'Edit', onClick: () => console.log('perm')},
        { label: 'Delete', onClick: () => onClickDelete(), color: 'red'},
      ]
    }

    return [
      // { label: 'Edit', onClick: () => console.log('perm')},
      // { label: 'Permissions', onClick: () => console.log('edit')},
      { label: 'Delete', onClick: () => onClickDelete(), color: 'red'},
    ]
  }

  const valueGenerator = (value) => {
    if (value === "PENDING") return 'Pending';
    else if (value === "ACCEPTED") return 'Member';
    else return 'Creator';
  }
  const colorGenerator = (value) => {
    if (value === "PENDING") return 'ltGray';
    else if (value === "ACCEPTED") return 'gray';
    else return 'black'
  }

  return (
    <Box>
      <DeleteUserModal 
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        username={username}
        email={recipientEmail}
        onDelete={() => onClickHandler("delete")}
      />
      <SplitButton 
        label={valueGenerator(value)}
        color={colorGenerator(value)}
        items={getOptions(value)}
      />
    </Box>
  );
}
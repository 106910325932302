import { gql } from "@apollo/client";

export const UPDATE_TASK_FLOW_AND_EVENTS_MUTATION = gql`
  mutation UpdateSubtaskAndLog(
    $taskFlow: updateTaskFlowInput!
    $event: createEventInput!
  ) {
    updateTaskFlow(input: $taskFlow) {
      id
    }

    createEvent(input: $event) {
      id
    }
  }
`;

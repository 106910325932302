import { gql } from '@apollo/client';

export const START_REFERRAL_PROCESS_MUTATION = gql`
  mutation StartReferralProcess($input: createRefInput!) {
  createReferral(input: $input) {
    id

    team {
      id
      name
    }
  }
}
`;
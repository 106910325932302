import { Box } from "@mui/material";
import React from "react";
import InitialsCircle from "../../styled/InitialsCircle/InitialsCircle";

const DocumentCreator = ({ value }) => {
  const initials = value.split(' ');
  const strInitials = initials[0][0] + initials[1][0];

  return (
    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
      <InitialsCircle variant="gray" initials={strInitials} fullName={value} />
    </Box>
  )
}

export default DocumentCreator;
import React, { useState, useCallback, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Checkbox from '../../styled/Checkbox/Checkbox';
import { rgba } from 'emotion-rgba';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import Button from '../../styled/Button/Button';


const IconsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 100px;
`

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ theme }) => rgba(theme.themeColor.bodyMain, 0.4)};
  cursor: pointer;
  width: 20px;
`

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => rgba(theme.themeColor.bodyMain, 0.4)};
  cursor: pointer;
  width: 20px;
`

const StyledBox = styled(Box)`
  padding: 10px;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border: 1px solid gray;
  height: 60vh;
  width: 30vw;
  margin: auto;
  top: 20%;
  left: 35%;
  position: absolute;
  color: purple;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
  overflow: scroll;
`
const StyledTextField = styled(InputBase)`
  border-radius: 8px;
  position: relative;
  font-size: 12px;
  height: 32px;
  width: 100%;
  padding: 2px 5px;
  background-color: ${({ theme }) => rgba(theme.themeColor.bodyMain, 0.1)};
`

const ls = window.localStorage;

export default function GridOptions({ ...rest }) {
  const theme = useTheme();
  const { tableName, columnApi, api } = rest;

  const getCols = () => {
    const userDef = JSON.parse(ls.getItem(tableName));

    const cols = columnApi.columnModel.columnDefs
      .map((col) => ({ headerName: col.headerName, field: col.field, visible: !col.hide }))
      .filter((col) => col.headerName.length > 0);

    if (userDef) {
      cols.forEach((col) => {
        const userCol = userDef.find((userCol) => userCol.colId === col.field);
        if (userCol) col.visible = !userCol.hide;
      });
    }

    return cols;
  }
  
  const columnDefs = getCols();
  const [open, setOpen] = useState(false);
  const [headersToUpdate, setHeadersToUpdate] = useState([]);
  const [colDefs, setColDefs] = useState(columnDefs);
  const [showSearch, setShowSearch] = useState(false);
  const [checkedCols, setCheckedCols] = useState({});
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const startCheckedCols = {};
    colDefs.forEach(col => {
      startCheckedCols[col.headerName] = col.visible;
    });

    setCheckedCols(startCheckedCols);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateHeaders = () => {
    const colDefsCopy = [...colDefs];

    function autoSizeAll(skipHeader) {
      const allColumnIds = [];
      columnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });

      columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    headersToUpdate.forEach((header) => {
      columnApi.setColumnVisible(header.field, header.value);
      colDefsCopy.forEach((col) => {
        if (col.field === header.field) {
          col.visible = header.value;
        }
      })
    })

    const columnState = JSON.stringify(columnApi.getColumnState());
    if (window) window[tableName] = columnState;
    ls.setItem(tableName, columnState);

    setColDefs(colDefsCopy);
    autoSizeAll();
    handleClose();
  }

  const onClickSearch = () => {
    setShowSearch(true);
  }

  const onTextInput = useCallback(() => {
    const value = document.getElementById('filter-text-box').value
    api.setQuickFilter(value);
    setSearchValue(value);
  }, [api]);

  const onCheckColumn = (col) => {
    const newCheckedCols = {...checkedCols};
    newCheckedCols[col.headerName] = !newCheckedCols[col.headerName];
    setCheckedCols(newCheckedCols);
    setHeadersToUpdate((prev) => [...prev, { field: col.field, value: !col.visible }]);
  }

  const onCloseSearch = () => {
    setShowSearch(false);
    setSearchValue("");
    api.setQuickFilter("");
  }

  return (
    <div style={{width: '100%'}}>
      {!showSearch && (
        <IconsContainer>
          <StyledSettingsIcon data-testid="SettingsIcon" theme={theme} onClick={handleOpen} />
          <StyledSearchIcon theme={theme} onClick={onClickSearch} />
        </IconsContainer>
      )}
      {showSearch && (
        <StyledTextField 
          id="filter-text-box" 
          placeholder='Search' 
          theme={theme} 
          onChange={onTextInput} 
          autoFocus 
          value={searchValue}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onCloseSearch}
                edge="end"
                data-testid="close-search-button"
              >
                <FaTimes />
              </IconButton>
            </InputAdornment>
          } />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox theme={theme}>
          <div>
            {
              colDefs.map((col, i) => {
                return (
                  <Checkbox
                    key={`${col.headerName} ${i}`}
                    label={col.headerName}
                    onClick={() => onCheckColumn(col)}
                    defaultChecked={col.visible}
                    checked={checkedCols[col.headerName]}
                  />
                )
              })
            }
          </div>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              sx={{ width: '150px' }}
              onClick={() => {
                updateHeaders();
              }}
            >Save</Button>
            <Button
              onClick={() => handleClose()}
              variant="secondary"
              sx={{
                ml: 1,
                fontSize: '12px',
                background: 'inherit !important',
                boxShadow: 'none',
                width: '125px',
                "&:hover": {
                  background: 'inherit',
                  boxShadow: 'none'
                }
              }}
            >Cancel</Button>
          </Box>
        </StyledBox>
      </Modal>
    </div>
  );
}
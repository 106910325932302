/* eslint-disable */
// PaymentStatus.jsx
import { UserContext } from '../../context/UserContext';
import {MessageContext} from '../../context/MessageContext';
import React, {useState, useEffect, useContext} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import { gql, useMutation } from "@apollo/client";
import Button from '../../styled/Button/Button';
import './card-details.scss';
import { useHistory } from "react-router-dom";

const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($paymentMethodID: ID!) {
    setDefaultPaymentMethod(paymentMethodID: $paymentMethodID) {
      id
    }
  }
`;

const CardDetailsPaymentStatus = ({clientSecret, onCloseImportModal, setIsSavingCard}) => {
  const { setUser } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const stripe = useStripe();
  const [message, setMessage] = useState(null); 
  const [hadError, setHadError] = useState(false);
  const [setDefaultPaymentMethod] = useMutation(SET_DEFAULT_PAYMENT_METHOD);
  const history = useHistory();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(async ({setupIntent}) => {
        
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
        case 'succeeded':
          // set this card as the default payment method
          const res = await setDefaultPaymentMethod({
            variables: { paymentMethodID: setupIntent.payment_method },
          });
          
          if (res.errors) {
            await setUser((user) => ({ ...user, paymentMethodAdded: false }))
            addMessage({ message: 'Unable to set default payment method.', type: 'error' });
            setHadError(true);
          } else {
            await setUser((user) => ({ ...user, paymentMethodAdded: true }))
            setMessage('Success! Your payment method has been saved.');
            setHadError(false);
          }
          break;

        case 'processing':
          setMessage("Processing payment details. We'll update you when processing is complete.");
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Failed to process payment details. Please try another payment method.');
          setHadError(true);
          break;
        default:
          setMessage('There was an error setting up your payment method. Please try again, or contact support.');
          setHadError(true);
          break;
        }

        setIsSavingCard(false);
      });
  }, [stripe, clientSecret, setDefaultPaymentMethod]);


  return (
    <div>
      <p className={(hadError ? 'error' : '')}><strong>{message}</strong></p>
      <Button onClick={() => {onCloseImportModal(); history.push(hadError ? "/" : "/applycoupon");}}>{hadError ? "Retry" : "Continue"}</Button>
    </div>
  );
};

export default CardDetailsPaymentStatus;
import React, { useEffect, useState, useContext } from "react";
import { Box, Autocomplete } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TEAMS_BY_OWNER } from "../../apollo/queries/getTeamsByOwner";
import { ADD_ASSETS_TO_TEAMS } from "../../apollo/mutations/addAssetsToTeams";
import { MessageContext } from "../../context/MessageContext";
import Modal from "../../styled/Modal/Modal";
import { TextInput } from "../../styled/TextInput/TextInput";
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import { useTheme } from "@emotion/react";

export default function AssignAssetToTeam({ open, onClose, assets, user, client }) {
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const { data } = useQuery(GET_TEAMS_BY_OWNER, {
    variables: { portfolioManager: user.userID },
  });
  const [addAssetToTeam] = useMutation(ADD_ASSETS_TO_TEAMS);

  useEffect(() => {
    if (data?.findTeamsByOwner) {
      const noLawfirms = data.findTeamsByOwner.filter((team) => team.type !== "LAWFIRM");
      setTeams(noLawfirms);
    }
  }, [data, assets, open]);

  const handleChange = (_event, newValue) => {
    _event.preventDefault();
    setSelectedTeams([...newValue]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addResponse = await addAssetToTeam({
        variables: {
          teamIDs: selectedTeams.map((team) => team.id),
          assetIDs: assets.map((asset) => asset.id)
        }
      })

      if (addResponse && !addResponse.errors) {
        setSelectedTeams([]);
        addMessage({ message: "Successfully assigned the selected assets!" })
        onClose();
        await client.refetchQueries({ include: "active" });
      }
    } catch(err) {
      addMessage({ type: "error", message: `Error while adding asset${assets.length > 1 ? "s" : ""} to team${selectedTeams.length > 1 ? "s" : ""}` })
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Typography mb={5} component="h1" variant="h4" align="center">{`Assign Asset${assets.length > 1 ? "s" : ""}`}</Typography>
      <Box sx={{ backgroundColor: theme.themeColor.backgroundBody, display: "flex", flexDirection: "column" }}>
        <label>
          <strong>Selected Assets: {" "}</strong>
          { assets &&
            assets.map((asset, i) => {
              return i === assets.length - 1
                ? asset.loanNumber
                : `${asset.loanNumber}, `
            }) }
        </label>
        <Autocomplete
          multiple
          className="select-team-dropdown"
          popupIcon={""}
          sx={{ width: 'auto', height: '80%', marginTop: "10px" }}
          size="small"
          options={teams}
          disablePortal
          getOptionLabel={option => `${option?.name}`}
          onChange={handleChange}
          value={selectedTeams}
          renderInput={(params) => <TextInput {...params}
            size="small"
            InputProps={{
              ...params.InputProps,
            }}
            label="Select teams"
          />}
        />
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
        <Button
          disabled={selectedTeams.length === 0}
          onClick={handleSubmit}
          sx={{
            mr: 1,
            position: 'relative',
            width: '225px'
          }}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          sx={{ width: "25%",fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
          onClick={() => { onClose(); setSelectedTeams([]); }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  )
}
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef
} from 'react';

const TypeSelector = forwardRef((props, ref) => {
  const [type, setType] = useState(props.value);
  const [editing, setEditing] = useState(true);

  const typeRef = useRef();

  useEffect(() => {
    focus();
  }, []);

  const focus = () => {
    window.setTimeout(() => {
      if (typeRef.current) {
        typeRef.current.focus();
      }
    });
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return type 
      },
    };
  });

  const setNeedsSave = (bool) => {
    const { data } = props;
    props.api.forEachNode((rowNode) => {
      if (data == rowNode.data) {
        rowNode.setData({ ...data, needsSave: bool });
      }
    })
  }

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const onChange = (e) => {
    setType(e.target.value);
    setEditing(false);
    setNeedsSave(true);
  }

  return (
    <div
      ref={typeRef}
      tabIndex={1} // important - without this the key presses wont be caught
    >
      <select onChange={onChange}>
        <option selected={props.value === "MANAGER"} value="MANAGER">Manager</option>
        <option selected={props.value === "MEMBER"} value="MEMBER">Member</option>
      </select>
    </div>
  );
});

TypeSelector.displayName = "TypeSelector";

export default TypeSelector;
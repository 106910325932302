import React from "react";
import { Chip as MUIChip } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { rgba } from "emotion-rgba";

const StyledChip = styled(MUIChip)`
  background-color: ${(props) => {
  switch (props.newColor) {
  case "orange":
    return props.theme.themeColor.brandPrimaryOrange;
  case "blue":
    return props.theme.themeColor.brandPrimaryBlue;
  case "red":
    return props.theme.themeColor.brandPrimaryRed;
  case "green":
    return props.theme.themeColor.brandPrimaryGreen;
  case "dark":
    return props.theme.themeColor.bodyMain;
  default:
    return rgba(props.theme.themeColor.bodyMain, 0.1);
  }
}};

  color: ${(props) => props.newColor ? '#FFF' : rgba(props.theme.themeColor.bodyMain, 0.4)};

  & .MuiChip-icon {
    color: ${(props) => rgba(props.theme.themeColor.bodyMain, 0.6)};
  };

  &:hover {
    background-color: ${(props) => {
  switch (props.newColor) {
  case "orange":
    return props.theme.themeColor.brandPrimaryOrange;
  case "blue":
    return props.theme.themeColor.brandPrimaryBlue;
  case "red":
    return props.theme.themeColor.brandPrimaryRed;
  case "green":
    return props.theme.themeColor.brandPrimaryGreen;
  case "dark":
    return props.theme.themeColor.bodyMain;
  default:
    return rgba(props.theme.themeColor.bodyMain, 0.1);
  }
}};
    color: ${(props) => props.newColor ? '#FFF' : props.theme.themeColor.bodyMain};
  };

  padding: 5px;
`;

const Chip = (props) => {
  const { newColor } = props;
  
  const theme = useTheme();

  return <StyledChip {...props} newColor={newColor} theme={theme} />;
};

export default Chip;

import { createUploadLink } from "apollo-upload-client";
import {
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

export const cache = new InMemoryCache();
export const defaultOptions = { mutate: { errorPolicy: "all" } };

export const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

export const nextLink = createUploadLink({
  uri: process.env.REACT_APP_NEXT_API_URL,
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    let accessToken = null;
    if (operation.operationName === "LoginMutation" ||
        operation.operationName === "loginMutation"    
    ) {
      accessToken = null;
    } else if (operation.operationName === "RegisterPortfolioUser") {
      accessToken = null;
    } else if (operation.operationName === "RegisterTeamUser") {
      accessToken = null;
    } else if (operation.operationName === "confirmEmail") {
      accessToken = null;
    } else if (operation.operationName === "FORGOT_PASSWORD_MUTATION") {
      accessToken = null;
    } else if (operation.operationName === "RESET_PASSWORD_MUTATION") {
      accessToken = null; } else {
      accessToken = JSON.parse(localStorage.getItem("user")).token
    }

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  });

  return forward(operation);
});

import React, { useEffect, useState, useContext } from "react";
import { Autocomplete } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TEAMS_BY_OWNER } from "../../../apollo/queries/getTeamsByOwner";
import { ADD_ASSETS_TO_TEAMS } from "../../../apollo/mutations/addAssetsToTeams";
import { REMOVE_ASSETS_FROM_TEAMS } from "../../../apollo/mutations/removeAssetsFromTeams";
import { MessageContext } from "../../../context/MessageContext";
import { UserContext } from "../../../context/UserContext";
import { TextInput } from "../../../styled/TextInput/TextInput";

export default function SidebarAssignAsset({ asset, client, readOnly }) {
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [assignedTeams, setAssignedTeams] = useState([]);

  const user = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);

  const { data } = useQuery(GET_TEAMS_BY_OWNER, {
    variables: { portfolioManager: user.userID },
  });
  const [addAssetToTeam] = useMutation(ADD_ASSETS_TO_TEAMS);
  const [removeAssetFromTeam] = useMutation(REMOVE_ASSETS_FROM_TEAMS);

  useEffect(() => {
    if (data?.findTeamsByOwner) {
      const noLawfirms = data.findTeamsByOwner.filter((team) => team.type !== "LAWFIRM");

      const hasSelectedTeams = [];
      noLawfirms.forEach((team) => {
        const { assets: teamAssets } = team;
        if (asset) {
          teamAssets.forEach((teamAsset) => {
            if (teamAsset.id == asset.id)
              hasSelectedTeams.push(team)
          })
        }
      })

      setTeams(noLawfirms);
      setAssignedTeams(hasSelectedTeams);
      setSelectedTeams(hasSelectedTeams);
    }
  }, [data, asset]);

  const handleChange = async (_event, newValue) => {
    _event.preventDefault();

    setSelectedTeams([...newValue]);
    const toAdd = [];
    const toDelete = [];

    const assignedIDs = assignedTeams.map((team) => team.id);
    const selectedIDs = newValue.map((team) => team.id)

    newValue.forEach((team) => {
      if (!assignedIDs.includes(team.id)) {
        toAdd.push(team.id)
      }
    })

    assignedIDs.forEach((assignedID) => {
      if (!selectedIDs.includes(assignedID)) {
        toDelete.push(assignedID)
      }
    })
    try {
      const addResponse = await addAssetToTeam({
        variables: {
          teamIDs: toAdd,
          assetIDs: [asset.id]
        }
      })
      const removeResponse = await removeAssetFromTeam({
        variables: {
          teamIDs: toDelete,
          assetIDs: [asset.id]
        }
      })

      if ((addResponse && !addResponse.error) && (removeResponse && !removeResponse.error)) {
        await client.refetchQueries({ include: "active" });
        addMessage({ message: "Successfully updated teams!" });
      }
    } catch(error) {
      addMessage({ type: "error", message: "Failed to update teams." })
    }
  }

  return (
    <Autocomplete
      readOnly={readOnly}
      multiple
      popupIcon={""}
      sx={{ width: 'auto', height: '80%', marginTop: "5px" }}
      size="small"
      options={teams}
      disablePortal
      getOptionLabel={option => `${option?.name}`}
      onChange={handleChange}
      id="assign-asset-dropdown"
      value={selectedTeams}
      renderInput={(params) => <TextInput {...params}
        size="small"
        InputProps={{
          ...params.InputProps,
        }}
        label="Select teams"
      />}
    />
  )
}
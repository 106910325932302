export const JUDICIAL = ["CT", "DE", "FL", "HI", "IA", "IL", "IN", "KS", "KY", "LA", "ME", "ND", "NE", "NJ", "NM", "NY", "OH", "OK",
  "PA", "PR", "SC", "SD", "VT", "WI"];

export const NON_JUDICIAL = ["AK", "AL", "AR", "AZ", "CA", "CO", "DC", "GA", "ID", "MA", "MD", "MI", "MN", "MO", "MS", "MT", "NC",
  "NH", "NV", "OR", "RI", "TN", "TX", "UT", "VA", "WA", "WV", "WY"];

export const ALL_STATES = JUDICIAL.concat(NON_JUDICIAL).sort();

export const GENERIC_JUDICIAL = [
  {
    "label": "Foreclosure Start",
    "stepID": "0.00",
    "weight": 0
  },
  {
    "label": "Preliminary Title Clear",
    "stepID": "0.10",
    "weight": 5
  },
  {
    "label": "First Legal",
    "stepID": "1.00",
    "weight": 30
  },
  {
    "label": "Service Complete",
    "stepID": "2.00",
    "weight": 30
  },
  {
    "label": "Answer Period",
    "stepID": "2.01",
    "weight": 30
  },
  {
    "label": "Mediation Scheduled (Optional)",
    "stepID": "2.20",
    "weight": 0
  },
  {
    "label": "Mediation Complete (Optional)",
    "stepID": "2.25",
    "weight": 50
  },
  {
    "label": "AOD Complete",
    "stepID": "2.60",
    "weight": 5
  },
  {
    "label": "MSJ/JFS Filed",
    "stepID": "2.80",
    "weight": 20
  },
  {
    "label": "Judgment Hearing Scheduled",
    "stepID": "2.90",
    "weight": 30
  },
  {
    "label": "Judgment Entered",
    "stepID": "3.00",
    "weight": 30
  },
  {
    "label": "Sale Scheduled",
    "stepID": "4.00",
    "weight": 30
  },
  {
    "label": "FC Sale Held",
    "stepID": "5.00",
    "weight": 30
  },
  {
    "label": "Foreclosure Complete",
    "stepID": "6.0",
    "weight": 0
  }
];

export const GENERIC_NONJUDICIAL = [
  {
    "label": "Foreclosure Start",
    "stepID": "0.00",
    "weight": 0
  },
  {
    "label": "Preliminary Title Clear",
    "stepID": "0.10",
    "weight": 5
  },
  {
    "label": "First Legal",
    "stepID": "1.00",
    "weight": 25
  },
  {
    "label": "Sale Scheduled",
    "stepID": "4.00",
    "weight": 45
  },
  {
    "label": "FC Sale Held",
    "stepID": "5.00",
    "weight": 45
  },
  {
    "label": "Foreclosure Complete",
    "stepID": "6.0",
    "weight": 0
  }
];

export const chooseGeneric = (state) => {
  if (isJudicial(state)) return GENERIC_JUDICIAL;
  else return GENERIC_NONJUDICIAL;
};

function haveCommonItems(arr1, arr2) {
  const set1 = new Set(arr1);
  const commonItems = arr2.filter(item => set1.has(item));
  return commonItems.length > 0;
}

export const isJudicial = (state) => {
  if (typeof state === 'string') {
    if (JUDICIAL.includes(state)) return true;
    else if (NON_JUDICIAL.includes(state)) return false;
    else return null;
  }
  else
    return haveCommonItems(state, JUDICIAL);
};

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { rgba } from "emotion-rgba";
import React from "react";
import Typography from "../../styled/Typography/Typography";

const Container = styled(Box)`
  position: absolute;
  right: 10px;
  top: 60px;
  background: ${({theme}) => rgba(theme.themeColor.bodyMain, 0.9)};
  width: 300px;
  z-index: 10;
  color: ${({theme}) => theme.themeColor.sectionStroke};
  font-size: 12px;
`

const TitleRow = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2px 0px;
  background: ${({theme}) => theme.themeColor.bodyMain};
  color: ${({theme}) => theme.themeColor.bodyBackground};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
`

const DetailContent = styled(Box)`
  padding: 10px;
`

const TaskDetails = ({ description, name, dueByMilestone, createdAt }) => {
  const theme = useTheme();
  
  return (
    <Container theme={theme}>
      <TitleRow theme={theme}>Task</TitleRow>
      <DetailContent>
        <Typography fontWeight="500" sx={{mb: 1}}>{name}</Typography>
        {description}
      </DetailContent>
      <TitleRow theme={theme}>Created at</TitleRow>
      <DetailContent>{new Date(+createdAt).toLocaleDateString()}</DetailContent>
      <TitleRow theme={theme}>Due by milestone</TitleRow>
      <DetailContent>{dueByMilestone}</DetailContent>
    </Container>
  )
}

export default TaskDetails;
import { gql } from "@apollo/client";

export const TASK_FLOWS_QUERY = gql`
  query GetTaskFlows($processID: ID!) {
    taskFlows(where: { process: { id: $processID } }) {
      id
      name
      completedAt
      dueDate
      created_at
      milestoneID
      blocking
      deleted
      task_comments {
        id
        createdAt
        comment
        createdBy {
          firstName
          lastName
        }
      }
      assignments {
        id
        firstName
        lastName
      }
      forms {
        id
        title
        form_fields {
          id
          label
          type
          value
          required
          options
          condition
          order
          permissions
          document {
            id
            name
            documentType
          }
        }
      }
      subtasks {
        id
        name
        completedAt
        order
        dueDate
        budgetDays
        task_comments {
          id
          createdAt
          comment
          createdBy {
            firstName
            lastName
          }
        }
        assignments {
          id
          firstName
          lastName
        }
        forms {
          id
          title
          form_fields {
            id
            label
            type
            value
            required
            options
            condition
            order
            permissions
            document {
              id
              name
              documentType
            }
          }
        }
      }
    }
  }
`;

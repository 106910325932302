import { useState, useEffect } from 'react';

export const useLocalStorage = (key, defaultValue = null) => {

  const [value, setValue] = useState(() => {
    const localData = localStorage.getItem(key);
    return localData !== null ? JSON.parse(localData) : defaultValue;
  });

  useEffect(() => {
    if (value) window.localStorage.setItem(key, JSON.stringify(value));
    // else window.localStorage.clear();
  }, [key, value]);


  return { value, setValue };
};
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const PaymentHistory = ({ paymentIntents }) => {
  const getCurrency = (currency) => {
    switch (currency) {
    case 'usd':
      return '$';
    case 'eur':
      return '€';
    default:
      return '$'
    }
  }
  
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Status</TableCell>
            <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Value</TableCell>
            <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentIntents && paymentIntents?.length === 0 && (
            <span>No payment history to show.</span>
          )}
          {paymentIntents && paymentIntents?.length && paymentIntents.map((pi) => (
            <TableRow
              key={pi.amount}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {capitalizeFirstLetter(pi.status)}
              </TableCell>
              <TableCell component="th" scope="row">
                {getCurrency(pi.currency)}{pi.amount/100}
              </TableCell>
              <TableCell component="th" scope="row">
                {new Date(pi.date * 1000).toDateString()}
              </TableCell>
            </TableRow>
          )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PaymentHistory;
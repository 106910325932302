import React from "react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";

const MAX_BARS = 5;

const BarsContainer = styled('div')`
  display: flex;
  align-items: center;
`

const Rect = styled('div')`
  width: 1px;
  height: 12px;
  background-color: ${(props) => {
  if (props.hg) {
    return props.theme.themeColor.brandPrimaryBlue;
  }

  return rgba(props.theme.themeColor.bodyMain, 0.2);
}};
  border-radius: 2px;
  margin: 2px;
`

const ValueContainer = styled('span')`
  margin-left: 12px;
`

const StageBars = (props) => {
  const { value, data } = props;
  const activeProcess = data?.activeProcess;

  if (!activeProcess || activeProcess?.deleted) return <span />;
  const { stepID } = activeProcess;

  const milestones = activeProcess.processSteps;
  const position = milestones.findIndex((milestone) => milestone.stepID === (stepID || value)) + 1;
  const part = Math.round(milestones.length / MAX_BARS);
  const bars = Math.round(position / part);

  return (
    <BarsContainer>
      <Rect hg={bars >= 1} />
      <Rect hg={bars >= 2} />
      <Rect hg={bars >= 3} />
      <Rect hg={bars >= 4} />
      <Rect hg={bars >= 5} />
      <ValueContainer>{stepID || value}</ValueContainer>
    </BarsContainer>
  )
};

export default StageBars;
import { gql } from "@apollo/client";

export const ACCEPT_REFERRAL_MUTATATION = gql`
  mutation AcceptRefferal($teamID: ID!, $referralID: ID!) {
    acceptReferral(teamID: $teamID, referralID: $referralID) {
      id
      referralStatus
    }
  }
`;

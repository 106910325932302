import React, { useState, useEffect } from 'react';
import { Container, Box, FormGroup, FormControlLabel } from "@mui/material";
import Typography from '../../styled/Typography/Typography';
import Checkbox from '../../styled/Checkbox/Checkbox';
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';

const GET_USER_INFO = gql`
  query GET_USER_INFO {
    getUserInfo {
      digest {
        optedIn
        frequency
        lastSent
      }
    }
  }
`
const UPDATE_USER_DIGEST = gql`
  mutation UPDATE_USER_DIGEST($input: UpdateDigestInput) {
    updateDigestInfo(input: $input) {
      digest {
        optedIn
        frequency
        lastSent
      }
    }
  }
`;

const StyledContainer = styled(Container)`
  width: 100% !important;
  height: 75vh;
  margin: 0;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  width: 92vw !important;
  padding: 40px;
  margin-bottom: 20px;
`
const SmallerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  padding: 20px;
`

const SectionTitle = styled('h3')`
  margin-bottom: 25px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export default function JonahDirectDigest() {
  const theme = useTheme();
  const [digestInfo, setDigestInfo] = useState({
    optedIn: false,
    frequency: 'daily',
    lastSent: ''
  });

  const { data } = useQuery(GET_USER_INFO);
  const [updateUserDigest] = useMutation(UPDATE_USER_DIGEST, {
    refetchQueries: [{ query: GET_USER_INFO }]
  });

  const handleOptIn = async () => {
    setDigestInfo((prev) => ({...prev, optedIn: !prev.optedIn}))
    await updateDigest({ optedIn: !digestInfo.optedIn, frequency: "daily" })
  }

  const handleFrequency = async (frequency) => {
    setDigestInfo((prev) => ({...prev, frequency }))
    await updateDigest({ frequency, optedIn: true })
  }

  const updateDigest = async (input) => {
    try {
      await updateUserDigest({
        variables: { input }
      })
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    if (data?.getUserInfo?.digest) {
      setDigestInfo((prev) => ({
        ...prev,
        optedIn: data.getUserInfo.digest.optedIn,
        frequency: data.getUserInfo.digest.frequency,
        lastSent: data.getUserInfo.digest.lastSent ? data.getUserInfo.digest.lastSent : ''
      }))
    }
  }, [data])

  return (
    <StyledContainer>
      <StyledBox>
        <SectionTitle>Jonah Direct Digest</SectionTitle>
        <Typography variant="body1">Jonah Direct Digest is an email that provides a summary of your account activity and performance.</Typography>
        <Typography variant="body1">You can opt in or out of this email at any time.</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={digestInfo.optedIn} />}
            label = {digestInfo.optedIn 
              ? <Typography sx={{fontWeight: "500", color: theme.themeColor.bodyMain}}>Opted In to Digest</Typography>
              : <Typography sx={{color: theme.themeColor.bodySecondary}}>I would like to receive Jonah Direct Digest emails.</Typography>
            }
            onClick={handleOptIn}
          />
        </FormGroup>
        {digestInfo.optedIn && (
          <SmallerBox>
            <Typography variant="body1" sx={{paddingBottom: '15px'}}>Last Digest Sent: {
              digestInfo.lastSent === ''
                ? ''
                : new Date(digestInfo.lastSent).toLocaleDateString()
            }</Typography>
            <Typography variant="body1">Frequency:</Typography>
            <FormControlLabel
              control={<Checkbox checked={digestInfo.frequency === 'daily'} />}
              label="Daily"
              onClick={() => handleFrequency('daily')}
            />
            <FormControlLabel
              control={<Checkbox checked={digestInfo.frequency === 'weekly'} />}
              label="Weekly"
              onClick={() => handleFrequency('weekly')}
            />
          </SmallerBox>
        )}
      </StyledBox>
    </StyledContainer>
  )
}
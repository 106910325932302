import React from 'react';
import { TextField } from "@mui/material";
import styled from '@emotion/styled';

const Input = styled(TextField)`
  input {
    color: ${({theme}) => theme.themeColor.bodyMain};
    background: ${({theme}) => theme.themeColor.backgroundBody};
  }
`;

export const TextInput = (props) => {
  return (
    <Input 
      {...props}
    />
  )
}
import React, { useRef } from "react";
import Button from "../../styled/Button/Button";
import { TextInput } from "../../styled/TextInput/TextInput";

export default function DropFileZone({
  acceptMimeTypes,
  acceptFilesTypes,
  onUploadFile,
  onUploadError,
  isLoading,
  analyzeImports,
  file,
  otherSubmit,
}) {
  const fileInput = useRef(null);

  const validateFile = (file) => {
    if (acceptMimeTypes.includes(file?.type)) {
      onUploadFile(file);
    } else {
      onUploadError();
    }
  };

  const handleFileChange = ({ target: { files } }) => validateFile(files[0]);
  const stopDragEvent = (event) =>
    event.preventDefault() && event.stopPropagation();
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    validateFile(files[0]);
  };

  return (
    <label
      onDrop={handleDrop}
      onDragOver={stopDragEvent}
    >
      <TextInput
        onDrop={handleDrop}
        id="upload-file-dropzone"
        onDragOver={stopDragEvent}
        onChange={handleFileChange}
        onClick={() => fileInput.current?.click()}
        type='text'
        placeholder={file ? file.name : 'Choose a file to upload...'}
        accept={acceptFilesTypes.join()}
        sx={{width: '100%'}}
      />
      <input
        type='file'
        ref={fileInput}
        onChange={handleFileChange}
        onDrop={handleDrop}
        onDragEnter={() => fileInput.current.handleDragEnter}
        onDragOver={stopDragEvent}
        style={{visibility: 'hidden'}}
      />
      {!otherSubmit && (
        <Button
          onClick={analyzeImports}
          type='submit'
          variant={file ? "primary" : "secondary"}
          disabled={!file || isLoading}
        >
          Upload
        </Button>
      )}

    </label>
  );
}

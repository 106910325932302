import { gql } from "@apollo/client";

export const UPDATE_ACOUNT_INFO_MUTATION = gql`
  mutation UpdateAccountInfo($input: updateAccountInput!) {
    updateAccountInfo(input: $input) {
      id
      firstName
      lastName
    }
  }
`;
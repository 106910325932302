import React from 'react';
import Chip from '../../styled/Chip/Chip';

export default function LawfirmCell({ value }) {
  if (!value) {
    return "--"
  } else if (value === "Pending") {
    return <Chip label='Pending' newColor="orange" />
  } else if (value === "Rejected") {
    return <Chip label='Rejected' newColor="red" />
  }

  return <Chip label={value} />;
}
import styled from "@emotion/styled";
import { Box, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { rgba } from "emotion-rgba";
import React, { useContext, useState } from "react"
import Drawer from "../../styled/Drawer/Drawer";
import { MdKeyboardReturn } from 'react-icons/md';
import { useTheme } from "@emotion/react";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { useMutation } from "@apollo/client";
import { COMMENT_HOLD_MUTATION } from "../../apollo/mutations/commentHoldMutation";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { MessageContext } from "../../context/MessageContext";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import Typography from "../../styled/Typography/Typography";
import InitialsCircle from "../../styled/InitialsCircle/InitialsCircle";
import { getInitials } from "../../helpers";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { HOLDS_QUERY } from "../../apollo/queries/holdsQuery";
import { FaQuestionCircle } from 'react-icons/fa';
import HoldDetails from "./HoldDetails";

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 71px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
  font-weight: 500;
  font-size: 24px;
`

const Content = styled(Box)`
  margin-top: 10px;
`

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 80px;
  background-color: ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 -25px;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`

const CustomIcon = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background:  ${({theme}) => theme.themeColor.backgroundBody};
  border: 1px solid ${({theme}) => theme.themeColor.bodyTetriary};
  border-radius: 4px;
  font-family: 'IBM Plex Mono';
  min-width: 28px;
  height: 20px;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
`

const TimelineContainer = styled(Box)`
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &:after {
    content  : "";
    position : absolute;
    z-index  : 1;
    bottom   : 78px;
    left     : 0;
    pointer-events   : none;
    background-image : linear-gradient(to bottom, 
                      rgba(255,255,255, 0), 
                      ${({theme}) => rgba(theme.themeColor.backgroundBody, 0.9)}
                      );
    width    : 100%;
    height   : 5em;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const TimelineDate = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color:  ${({theme}) => theme.themeColor.bodySecondary};
  font-size: 12px;
`

const CommentsTimeline = ({ comments }) => {
  const theme = useTheme();
  const timelineComments = [...comments];
  timelineComments.sort((ca, cb) => new Date(cb.createdAt) - new Date(ca.createdAt));
  const options = {
    day: '2-digit',
    month: '2-digit',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  }
  return (
    <TimelineContainer sx={{overflowY: 'scroll', height: '65%'}} theme={theme}>
      <Timeline position="right" sx={{padding: 0}}>
        {timelineComments.map((cmt) => { 
          const fullName = `${cmt.createdBy.firstName} ${cmt.createdBy.lastName}`;
          const initials = getInitials(fullName);
          return (
            <TimelineItem key={cmt}>
              <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{pr: 0}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <TimelineDate theme={theme} number>{new Date(+cmt.createdAt).toLocaleString(undefined, options)}</TimelineDate>
                  <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Typography sx={{
                      mr: 1,
                      fontSize: '12px',
                      lineHeight: '18px'
                    }}>{`${cmt.createdBy.firstName} ${cmt.createdBy.lastName}`}</Typography>
                    <InitialsCircle size="25px" fontSize="10px" fullName={fullName} initials={initials} />
                  </Box>
                </Box>
                <Typography sx={{
                  fontSize: '12px',
                  lineHeight: '18px'
                }}>
                  {cmt.comment}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </TimelineContainer>
  )
}

const HoldComments = ({ open, hold, onClose, milestoneID, processID, comments, readOnly }) => {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const [showHoldDetails, setShowHoldDetails] = useState(false);

  const [addToTimeline, setAddToTimeline] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [formError, setFormError] = useState('');

  const [addComment, { loading }] = useMutation(COMMENT_HOLD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  const { holdType, id, holdDescription } = hold;
  
  const handleSubmit = async () => {
    if (!newComment) {
      setFormError("Comment can't be empty.");
      return;
    }
    
    const data = {
      comment: newComment,
      hold: id,
      addToTimeline
    }

    try {
      await addComment({
        variables: {
          input: data
        }
      });

      if (addToTimeline) {
        const event = {
          title: `Re: ${holdType}`,
          type: "hold-comment",
          description: newComment,
          createdDate: new Date(),
          milestoneID: milestoneID,
          process: processID,
        }

        await createEvent({
          variables: {
            event
          }
        });
      }

      setNewComment('');
    } catch (error) {
      addMessage({ message: 'Unable to create a comment on this hold. Please refresh and try again.', type: 'error' })
    }

  }

  const handleChange = (event) => {
    const value = event.target.value;
    setNewComment(value);
    setFormError('');
  }

  return (
    <Drawer open={open} onClose={onClose} width="33vw">
      <Header>
        {holdType}
        <FaQuestionCircle style={{cursor: 'pointer'}} onClick={() => setShowHoldDetails(!showHoldDetails)}/>
        {showHoldDetails && (
          <HoldDetails
            description={holdDescription}
            type={holdType}
            createdBy={hold.createdBy}
            comment={hold.holdComment}
            closeDate={hold.closeDate || hold.expectedCloseDate}
            startDate={hold.startDate}
          />
        )}
      </Header>
      <Content>
        <form onSubmit={handleSubmit}>
          <TextField
            id="hold-comment-input"
            label="Enter your comment"
            multiline
            rows={3}
            value={newComment}
            fullWidth
            onChange={handleChange}
            error={formError}
            disabled={loading || readOnly}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MdKeyboardReturn color={theme.themeColor.bodySecondary} />
                </InputAdornment>
              )
            }}
          />
          {formError && <FormHelperText sx={{color: theme.themeColor.brandPrimaryRed}}>{formError}</FormHelperText>}
          <Checkbox disabled={loading || readOnly} checked={addToTimeline} onChange={() => setAddToTimeline(!addToTimeline)} label="Add my comment to the main timeline" />
        </form>
        <CommentsTimeline comments={comments} />
      </Content>
      <Bottom>
        <span>
          Enter your comment and hit <CustomIcon><MdKeyboardReturn /></CustomIcon> to post it. Press <span style={{display: 'inline-flex', alignItems: 'center'}}><CustomIcon sx={{mr: 0.5}}>SHIFT</CustomIcon><CustomIcon><MdKeyboardReturn /></CustomIcon></span> to start a new line.
        </span>
      </Bottom>
    </Drawer>
  )
}

export default HoldComments;
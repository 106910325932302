import { gql } from "@apollo/client";

export const GET_PORTFOLIO_COUNTS = gql`
  query GetPortfolioCounts($portfolioId: ID!) {
    getPortfolioCounts(portfolioID: $portfolioId) {
      referralsCount
      tasksCount
    }
  }
`;

import React from 'react';
import { Box, Button, Badge } from '@mui/material';
import styled from '@emotion/styled';
import CloseMilestone from '../CloseMilestone/CloseMilestone';
import { useTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import SelectMilestone from './SelectMilestone';

const StyledContainer = styled(Box)`
  position: absolute;
  top: 49px;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
`
const StyledButton = styled(Button)`
  width: 200px;
  margin-left: 15px;
  background-color: transparent;
`

export default function CurrentMilestone({
  asset,
  milestoneID,
  milestones,
  activeStep,
  process,
  hasBlockingTask,
  currentMilestone,
  setCurrentMilestone,
  hasOpenHolds,
  assetState,
  readOnly,
  originalFC,
  setScreen,
  openFilter,
  numFiltersChanged,
  activeProcess,
  setAsset,
  setActiveProcess,
}) {
  const theme = useTheme();

  return (
    <StyledContainer theme={theme}>
      <Box sx={{display: 'flex'}}>
        {!readOnly &&
          <CloseMilestone
            originalFC={originalFC}
            asset={asset}
            activeProcess={activeProcess}
            milestoneID={milestoneID}
            milestones={milestones}
            activeStep={activeStep}
            process={process}
            hasBlockingTask={hasBlockingTask}
            setCurrMilestone={setCurrentMilestone}
            hasOpenHolds={hasOpenHolds}
            assetState={assetState}
            currentMilestone={currentMilestone}
            setScreen={setScreen}
            setAsset={setAsset}
            setActiveProcess={setActiveProcess}
          />}
        <SelectMilestone
          milestones={milestones}
          currMilestone={currentMilestone}
          setCurrMilestone={setCurrentMilestone}
          process={process}
          originalFC={originalFC}
        />
        <StyledButton
          onClick={() => openFilter(true)}
        >
          FILTER TIMELINE
          <Badge sx={{ml: 2, fontFamily: 'IBM Plex Mono'}} badgeContent={numFiltersChanged} color="secondary" />
        </StyledButton>
      </Box>
    </StyledContainer>
  )
}
import "ag-grid-enterprise";
import React, { useState } from "react";
import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "../../styled/Button/Button";
import { FormControl, InputLabel, MenuItem, Select, ListSubheader } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import SaveFilterModal from "./SaveFilterModal";
import { GET_TABLE_FILTERS } from "./Grid";
import { rgba } from "emotion-rgba";

const DELETE_TABLE_FILTER = gql`
  mutation deleteTableFilter($filterID: ID!) {
    deleteTableFilter(filterID: $filterID) {
      id
    }
  }
`;

const GridFilterDropdown = styled(Box)`
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FilterButton = styled(Button)`
  background-color: inherit !important;
  width: auto;
  box-shadow: none !important;
  margin-left: 15px;
`;
const Group = styled(ListSubheader)`
  line-height: 20px;
  padding: 0 0 5px 7px;
  color: ${props => rgba(props.theme.themeColor.bodyMain, .75)};
`;

const GridFilters = ({
  gridRef,
  tableName,
  allFilters,
  currFilter,
  setCurrFilter,
  canSaveFilter,
  setCanSaveFilter,
  canDeleteFilter,
  setCanDeleteFilter,
  filterRef,
}) => {
  const theme = useTheme();
  const [openSaveFilter, setOpenSaveFilter] = useState(false);

  const [deleteTableFilter] = useMutation(DELETE_TABLE_FILTER, {
    refetchQueries: [{ query: GET_TABLE_FILTERS, variables: { tableName } }],
  });

  const handleSelectFilter = (event) => {
    const filterValue = event.target.value;

    if (filterValue === '') {
      filterRef.current = '';
    } else {
      const filter = allFilters.find(f => f.name === filterValue);

      filterRef.current = {
        id: filter.id,
        name: filter.name,
        filters: filter.filters,
        tableName: filter.tableName,
        showToEveryone: filter.showToEveryone
      }
      gridRef.current.api.setFilterModel(filter.filters);
    }
  };

  const handleSaveFilter = () => {
    const currentFilters = gridRef.current.api.getFilterModel();
    filterRef.current = {
      id: '',
      name: '',
      filters: currentFilters,
      tableName: tableName
    }

    setOpenSaveFilter(true);
  };

  const handleClearFilters = () => {
    gridRef.current.api.setFilterModel(null);
    filterRef.current = '';
    setCurrFilter({});
    setCanSaveFilter(false);
    setCanDeleteFilter(false);
  };

  const handleDeleteFilter = async () => {
    await deleteTableFilter({ variables: { filterID: filterRef.current.id } });
    
    gridRef.current.api.setFilterModel(null);
    filterRef.current = '';
    setCurrFilter({});
    setCanSaveFilter(false);
    setCanDeleteFilter(false);
  };

  const grouped = allFilters.reduce((acc, filter) => {
    const key = filter.showToEveryone ? 'public' : 'private';
    !acc[key]
      ? acc[key] = [filter]
      : acc[key].push(filter);
    return acc;
  }, {});

  return (
    <>
      <GridFilterDropdown>
        { (canDeleteFilter) && 
            <FilterButton
              onClick={handleDeleteFilter}
              sx={{
                color: theme.themeColor.brandPrimaryRed
              }}
            >
              DELETE
            </FilterButton>
        }
        <FormControl size="small" sx={{width: '10vw', height: '80% !important'}}>
          <InputLabel>Saved Filters</InputLabel>
          <Select
            id="table-filters-dropdown"
            value={filterRef.current?.name ? filterRef.current.name : ''}
            label="Saved Filters"
            onChange={handleSelectFilter}
            inputProps={{ 'data-testid': 'id-table-filters-dropdown-props'}}
            displayEmpty
          >
            {grouped['public'] && <Group sx={{color: theme.themeColor.bodySecondary, lineHeight: '20px', paddingLeft: '5px'}}>Pre-canned</Group>}
            {
              grouped['public']?.map((filter) => (
                <MenuItem tableName={tableName} key={`tablefilter-${filter.id}`} id={filter.id} name={filter.name} value={filter.name}>{filter.name}</MenuItem>
              ))
            }
            {grouped['private'] && <Group sx={{color: theme.themeColor.bodySecondary, lineHeight: '20px', paddingLeft: '5px'}}>User-made</Group>}
            {
              grouped['private']?.map((filter) => (
                <MenuItem tableName={tableName} key={`tablefilter-${filter.id}`} id={filter.id} name={filter.name} value={filter.name}>{filter.name}</MenuItem>
              ))
            }
          </Select>
      
        </FormControl>
        <FilterButton
          onClick={handleClearFilters}
          variant="secondary"
          sx={{
            color: theme.themeColor.sectionFill,
          }}
        >
            Clear
        </FilterButton>
        <FilterButton
          onClick={handleSaveFilter}
          disabled={!canSaveFilter}
          sx={{
            color: theme.themeColor.brandPrimaryBlue
          }}
        >
            Save
        </FilterButton>
      </GridFilterDropdown>

      <SaveFilterModal
        open={openSaveFilter}
        setOpen={setOpenSaveFilter}
        tableName={tableName}
        filters={currFilter}
        // setSelectedFilter={setSelectedFilter}
        filterRef={filterRef}
        setCurrFilter={setCurrFilter}
      />
    </>
  );
}

export default GridFilters;

import differenceInDays from "date-fns/differenceInDays";
import { getUniqueDateRange } from "../utilities/uniqueDateRange";

export function calculateTotalBudgetDays(milestones) {
  let totalWeight = 0;
  milestones.forEach((milestone) => {
    totalWeight = milestone.weight + totalWeight;
  });
  return totalWeight;
}

export function getTotalDaysInProcess(initialDate) {
  if (initialDate === null || initialDate === "") return 0;
  const referalStartDate = new Date(initialDate);
  const today = new Date();
  return differenceInDays(today, referalStartDate);
}

export function calculateTotalHoldsDateDuration(holds) {
  const range = getUniqueDateRange(holds);

  const totalHoldDays = range.reduce((acc, item) => {
    const { startDate, closeDate, expectedCloseDate } = item;
    const result =
      closeDate === null
        ? differenceInDays(new Date(+expectedCloseDate), new Date(+startDate)) +
        acc
        : differenceInDays(new Date(+closeDate), new Date(+startDate)) + acc;
    return result;
  }, 0);

  return totalHoldDays;
}

export function getDaysSinceLastHold(holds) {
  let daysSinceLastHold = 0;

  holds.forEach((hold) => {
    const { startDate, closeDate } = hold;

    if (closeDate === null) {
      const difference = differenceInDays(new Date(), new Date(+startDate));
      if (difference > daysSinceLastHold)
        daysSinceLastHold = difference;
    }
  })

  return daysSinceLastHold;
}

export function getActiveMilestone(milestones) {
  const result = milestones.find((milestone) => milestone.status === "active");
  return result ? result : "";
}

export function getMilestoneOpenDate(events, activeMilestone, foreclosureStartDate) {
  if (!events.length > 0) return foreclosureStartDate;
  const milestoneCreatedDate = events.find(
    (event) => event.type === "milestone-opened"
  );
  return activeMilestone === "0.10"
    ? foreclosureStartDate
    : milestoneCreatedDate.createdDate;
}

export default function getMilestoneStatistics(milestones, data) {  
  const { asset } = data;
  const { loan_status } = asset;
  
  if (loan_status) {
    const {
      stepBudgetDays,
      cumulativeBudget,
      totalDaysInFc,
      totalDaysInBk,
      totalHoldsDuration,
      stepDaysOnHold,
      daysSinceHold,
      stepGrossFcDays,
      totalDaysOverdue,
      netDuration,
      netInFC,
      netVsBudget,
      grossVsBudget,
      budgetDaysToSale,
      daysInDlq
    } = loan_status;
  
    const nextStepStats = {
      stepGrossFcDays,
      stepDaysOnHold,
      netDuration,
      netVsBudget,
      grossVsBudget,
      stepBudgetDays,
      netInFC,
      totalDaysOverdue,
      daysSinceHold,
    };
    
    const sideBarStats = {
      totalDaysInFc,
      totalDaysInBk,
      cumulativeBudget,
      totalHoldsDuration,
      daysInDlq,
      budgetDaysToSale,
    };
  
    return {
      nextStepStats,
      sideBarStats,
    };
  }

  const nextStepStats = {
    stepGrossFcDays: 0,
    stepDaysOnHold: 0,
    netDuration: 0,
    netVsBudget: 0,
    grossVsBudget: 0,
    stepBudgetDays: 0,
    netInFc: 0,
    totalDaysOverdue: 0,
    daysSinceHold: 0,
    totalDaysInFc: 0,
    totalDaysInBk: 0,
  };
  
  const sideBarStats = {
    budgetDaysToSale: 0,
    totalDaysInFc: 0,
    totalDaysInBk: 0,
    cumulativeBudget: 0,
    totalHoldsDuration: 0,
    daysInDlq: 0,
  };

  return {
    nextStepStats,
    sideBarStats,
  };
}
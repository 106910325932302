import { gql } from "@apollo/client";

export const GET_PORTFOLIOS = gql`
  query Query {
    getUserPortfolios {
      id
      name
      assets {
        id
      }
    }
  }
`;

import React from "react";
import Modal from "../../styled/Modal/Modal";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`

export default function FilterAssetTimeline({ onClose, open, setFilters, setNumFilters, filters }) {
  const onCheckboxClick = (value) => {
    const newFilters = filters.map((filter) => {
      if (filter.value === value) {
        return {...filter, checked: !filter.checked}
      } else return { ...filter }
    })
    setFilters(newFilters);

    let numFilters = Object.values(newFilters).reduce((acc, curr) => {
      if (!curr.checked) ++acc;
      return acc;
    }, 0)
    if (numFilters === 0) numFilters = "0"
    setNumFilters(numFilters)
  }
  
  return (
    <Modal onClose={onClose} open={open} height="67vh" width="42vw">
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-around" }}
      >
        <Column>
          {filters.map((filter, i) => {
            if (i <= filters.length / 2) {
              return <Checkbox
                key={`${filter.name}-${i}`}
                checked={filter.checked}
                label={filter.name}
                onClick={() => onCheckboxClick(filter.value)}
              />
            }
          })}
        </Column>
        <Column>
          {filters.map((filter, i) => {
            if (i > filters.length / 2) {
              return <Checkbox
                key={`${filter.name}-${i}`}
                checked={filter.checked}
                label={filter.name}
                onClick={() => onCheckboxClick(filter.value)}
              />
            }
          })}
        </Column>
      </Box>
    </Modal>
  )
}
import { Box, Tooltip } from "@mui/material";
import React from "react";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const DocumentDescription = ({ value }) => {
  if (value) {
    return (
      <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
        <Tooltip
          title={value}
          placement="top"
        >
          <ChatBubbleIcon fontSize="12px" />
        </Tooltip>
      </Box>
    )
  }

  return '';
}

export default DocumentDescription;
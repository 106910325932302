import { gql } from "@apollo/client";

export const DOCUMENTS_BY_ASSET_QUERY = gql`
  query GetDocumentsByAsset($assetID: ID!, $limit: Int, $start: Int) {
    findDocuments(
      where: { asset: { id: $assetID } }
      limit: $limit
      start: $start
    ) {
      id
      name
      documentType
      description
      uploadedAt
      process
      extension
      deleted
      uploadedBy {
        firstName
        lastName
      }
    }
    getDocumentTypes {
      name
    }
  }
`;
import React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Form,
  FormHeading,
  FormText,
  FormRadioGroupLabel,
  FormRadioGroup,
  FormRadioGroupButton,
  LinkButtonLarge,
  LinkButtonSmall
} from "./styles.js";
import { FormControl, Radio, Box } from "@mui/material";
import Label from "../../styled/Label/Label";
import TextBox from "../../styled/TextBox/TextBox";

export default function BlockedAccountForm() {
  const { url } = useRouteMatch();

  const [selected, setSelected] = React.useState("export");
  return (
    <div>
      <Form>
        <FormHeading variant="h3" gutterBottom component="div">
          Account Suspended
        </FormHeading>
        <FormText variant="subtitle1" gutterBottom component="div">
          Unfortunately, your account has been suspended. You can no longer sign
          in to Jonah Direct using this account.
        </FormText>
        <Label>Reason:</Label>
        <TextBox marginBottom={"40px"}>
          Multiple Terms and Conditions violations.
        </TextBox>

        <FormControl fullWidth>
          <FormRadioGroupLabel>Where to next?</FormRadioGroupLabel>
          <FormRadioGroup
            name="nextStep"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            <FormRadioGroupButton
              value="export"
              control={<Radio />}
              label="Export my account data"
              checked={selected === "export"}
            />
            <FormRadioGroupButton
              value="contact"
              control={<Radio />}
              label="Contact Support"
              checked={selected === "contact"}
            />
          </FormRadioGroup>
        </FormControl>
        <Box sx={{ display: 'flex' }}>
          <LinkButtonLarge to={`${url}/${selected}`}>Continue</LinkButtonLarge>
          <LinkButtonSmall to={`${url}/remove`}>Remove Account</LinkButtonSmall>
        </Box>
      </Form>
    </div>
  );
}

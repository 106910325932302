import { gql } from "@apollo/client";

export const GET_STRIPE_DETAILS = gql`
  query getStripeDetailsCurUser {
    getStripeDetailsCurUser {
      paymentMethods {
        id
        brand
        last4
        exp_month
        exp_year
        active
      }
      paymentIntents {
        date
        amount
        status
        currency
      }
    }
  }
`;

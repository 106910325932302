import { gql } from "@apollo/client";

export const CREATE_HOLD_MUTATION = gql`
  mutation CreateHold($hold: createHoldInput) {
    createHold(input: $hold) {
      id
    }
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($event: createEventInput!) {
    createEvent(input: $event) {
      id
    }
  }
`;

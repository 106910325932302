import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const FormField = ({ field, value, handleChange, formValues, className, disabled }) => {
  const [showField, setShowField] = useState(true);

  useEffect(() => {
    if (field.condition) {
      const conditionsParts = field.condition.split(' = ');
      const condField = conditionsParts[0];
      const condValue = conditionsParts[1];
  
      // Find formValue with the given field label
      const formField = formValues.find((field) => field.label === condField);
  
      if (!(formField?.value === condValue)) setShowField(false);
      else setShowField(true);
    }
  }, [field, formValues]);

  if (!showField) return <span />

  return (
    <TextField
      className={className}
      key={field.label}
      type={field.type}
      label={field.label}
      InputProps={{
        inputProps: { min: 0 }
      }}
      value={value}
      required={field.required}
      onChange={(event) => handleChange(event, field)}
      disabled={!formValues || disabled}
      InputLabelProps={{ shrink: field.type === 'date' || value }}
    />
  )
}

export default FormField;
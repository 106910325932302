import React, { useState } from 'react';
import { Container, Tabs, Box, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Notifications from '../components/Notifications/Notifications';
import NotificationsSettings from '../components/NotificationsSettings/NotificationsSettings';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 33,
    width: '100%',
    backgroundColor: theme.themeColor.brandPrimaryBlue,
    borderBottom: 'none'
  },
}));
const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  width: '10vw',
  color: theme.themeColor.grayText,
  border: '1px solid gray',
  borderRadius: '8px 8px 0 0',
  background: 'white',
  '&.Mui-selected': {
    color: 'black',
    borderBottom: 'none'
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.themeColor.brandPrimaryBlue,
    borderBottom: 'none'
  },
}));


const FormContainer = styled(Box)`
  background-color: white;
  height: 100%;
  border-radius: 0 8px 8px 8px;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  color: black;
`

const NotificationsCenter = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();

  const onChangeTab = (_, newTab) => {
    setSelectedTab(newTab);
  }
  
  return (
    <div className="portfolio-assets asset-list py-3"
      style={{height: '85vh'}}
    >
      <Container
        maxWidth='xl'
        sx={{
          height: '100%',
        }}
      >
        <StyledTabs
          theme={theme}
          value={selectedTab}
          onChange={onChangeTab}
        >
          <StyledTab label="Notifications" />
          <StyledTab label="Settings" />
        </StyledTabs>
        <FormContainer>
          <Box sx={{ p: 3 }}>
            {selectedTab === 0 && <Notifications />}
            {selectedTab === 1 && <NotificationsSettings />}
          </Box>
        </FormContainer>
      </Container>
    </div>
  )
}

export default NotificationsCenter;
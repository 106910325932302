import React from "react";
import BlockedAccountForm from "./BlockedAccountForm";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export default function BlockedAccount() {
  const { path } = useRouteMatch();

  return (
    <div>
      <BlockedAccountForm />
      <Switch>
        <Route exact path={`${path}/export`}>
          <h1>Export</h1>
        </Route>
        <Route path={`${path}/remove`}>
          <h1>Remove</h1>
        </Route>
      </Switch>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import Button from "../../styled/Button/Button";
import StandardGrid from "../Grid/Grid";
import StaffBulkImportModal from "./StaffBulkImportModal";
import TaskFlowTemplateAction from './TaskFlowTemplateAction';
import StaffOptionsModal from "./StaffActionsModal";

const GET_ALL_PORTFOLIOS = gql`
  query {
    getAllPortfolios {
      id
      name
      portfolioOwner {
        firstName
        lastName
      }
    }
  }
`;

const components = {
  TaskFlowTemplateAction
}

const transformAssetData = (templates) => {
  return templates.map((item) => {
    const subtasksNames = [...item.subtasks].map((subtask) => subtask.name).join(', ');

    return {...item, subtasksNames};
  });
}

const TaskFlowTemplates = ({ templates }) => {
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [myColsDef, setMyColsDef] = useState([
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Process",
      field: "processType",
      sortable: true,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
    },
    {
      headerName: "Subcategory",
      field: "subcategory",
      sortable: true,
    },
    {
      headerName: "Subtasks",
      field: "subtasksNames",
      sortable: true,
    },
    {
      headerName: "Create on Milestone",
      field: "createOnMilestoneId",
      sortable: true,
    },
    {
      headerName: "States",
      field: "states",
      sortable: true,
    },
    {
      headerName: "Is Shown to Everyone",
      field: "showToEveryone",
      sortable: true,
    },
    {
      headerName: "",
      field: "",
      sortable: true,
      cellRenderer: 'TaskFlowTemplateAction',
      cellRendererParams: {
        allPortfolios
      }
    },
  ]);

  const { data } = useQuery(GET_ALL_PORTFOLIOS);

  useEffect(() => {
    if (data) {
      setAllPortfolios(data.getAllPortfolios);
      const colsCopy = [...myColsDef];
      colsCopy[colsCopy.length-1].cellRendererParams.allPortfolios = data.getAllPortfolios;
      setMyColsDef(colsCopy)
    }
  }, [data]);

  useEffect(() => {
    setRowData(transformAssetData(templates));
  }, [templates])
  
  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'multiple',
    columnDefs: myColsDef,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
  };

  const gridApiRef = useRef(null);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setRowData(transformAssetData(templates));
  }
  const onGridSizeChanged = () => {
    gridApiRef.current.sizeColumnsToFit();
  }
  const onCheckboxClick = () => {
    setSelected(gridApiRef.current.getSelectedRows());
  }

  return (
    <>
      <StandardGrid
        tableName="STAFF_task-flow-templates"
        rowData={rowData}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onSelectionChanged={onCheckboxClick}
        components={components}
        floatingButton={
          <Button onClick={() => setShowModal(true)}>Bulk Import</Button>
        }
      />
      {selected?.length > 1 && 
        <Button
          sx={{width: "33vw", float: "right"}}
          onClick={() => setShowOptions(true)}
        >
          Show Modal
        </Button>
      }
      <StaffOptionsModal allPortfolios={allPortfolios} show={showOptions} selected={selected} onClose={() => setShowOptions(false)} />
      <StaffBulkImportModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default TaskFlowTemplates;
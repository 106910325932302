import React from "react";
import Events from "../../Events/Events";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import Typography from "../../../styled/Typography/Typography";
import { isForeclosure } from "../../../utilities";
import styled from "@emotion/styled";

const CustomBox = styled(Box)`
  padding-top: 80px;
  height: 80vh;
  width: 100%;
  display: flex;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
}
`

const formatBkStatus = (status) => {
  if (!status) return "Bankruptcy Closed"
  return status.toLowerCase().split("_").join(" ");
}

export default function Milestones({
  startDate,
  process,
  milestoneID,
  setOpenHold,
  setOpenTask,
  activeStep,
  currentMilestone,
  setAddTaskFlow,
  readOnly,
  timelineFilters
}) {
  const theme = useTheme();
  const { id: processID } = process;

  return (
    <CustomBox>
      {
        milestoneID === '6.0'
          ? (
            <Box sx={{
              height: '50%',
              width: '65%',
              display: 'flex',
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 3
            }}>
              <Typography component="h1" variant="h3" ml={5}
                sx={{ color: theme.themeColor.bodyMain, textTransform: "capitalize" }}>
                {isForeclosure(process) ? "Foreclosure Closed" : formatBkStatus(process.status)}
              </Typography>
            </Box>
          ) : (
            <Events
              activeStep={activeStep}
              processID={processID}
              milestoneID={milestoneID}
              setOpenHold={setOpenHold} 
              setOpenTask={setOpenTask}
              setAddTaskFlow={setAddTaskFlow}
              startDate={startDate}
              currentMilestone={currentMilestone}
              readOnly={readOnly}
              timelineFilters={timelineFilters}
            />
          )
      }
    </CustomBox>
  )
}

import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import TeamsList from '../components/TeamsList/TeamsList';
import Typography from '../styled/Typography/Typography';

export default function Teams() {
  const { user } = useContext(UserContext);

  if (user?.accDeleted) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      Your account has been deleted.
    </Typography>
  )

  return <TeamsList />
}

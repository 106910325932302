import { gql } from "@apollo/client";

export const CREATE_COMMENT_AND_EVENTS_MUTATION = gql`
  mutation CreateCommentAndLog(
    $comment: createCommentInput!
    $event: createEventInput!
  ) {
    createComment(input: $comment) {
      id
      createdDate
      description
    }

    createEvent(input: $event) {
      id
    }
  }
`;

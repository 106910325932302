import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useMutation } from "@apollo/client";
import { MARK_NOTIFICATIONS_READ } from "../../apollo/mutations/markNotificationsRead";
import { GET_NOTIFICATIONS } from "../../apollo/queries/getNotifications";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useHistory } from 'react-router-dom';

const StyledMenu = styled(Menu)`
  width: 33vw;
  min-width: 30vw;
`;
const StyledMenuItem = styled(MenuItem)`
  width: 100% !important;
  overflow-x: wrap;
  display: flex;
  justify-content: space-between;
`;
const ViewAll = styled(MenuItem)`
  width: 100% !important;
  font-weight: 500;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  border-top: 1px solid ${({ theme }) => theme.themeColor.sectionStroke};
  display: flex;
  align-items: flex-end;
`;
const NotificationText = styled('dt')`
  max-width: 90%;
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const CreatedAt = styled('dd')`
  font-size: 12px;
  color: ${({ theme }) => theme.themeColor.bodyTertiary};
  max-width: 10%;
`;

const getTimeFromNow = (createdAt) => {
  const d = new Date();
  const now = d.getTime();
  const created = new Date(createdAt);

  const diff = now - created.getTime();
  const diffInMinutes = Math.floor(diff / 60000);
  const diffInHours = Math.floor(diff / 3600000);
  const diffInDays = Math.floor(diff / 86400000);

  if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  }
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }
  return `${diffInDays}d ago`;
}

const NotificationsDropdown = ({ anchor, open, onClose, notifications }) => {
  const history = useHistory();
  const theme = useTheme();

  const mostRecentNotifications = notifications.slice(0, 10);

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  });

  const onMenuItemClick = async (id) => {
    onClose();
    await markNotificationsAsRead({
      variables: { notificationIDs: [id] }
    });
  }
  
  const viewAllNotifications = () => {
    history.push('/account-settings#tab2');
    onClose();
  }

  return (
    <StyledMenu
      id="notifications-menu"
      anchorEl={anchor}
      open={open}
      onClose={onClose}
    >
      {mostRecentNotifications.length 
        ? (
          mostRecentNotifications.map((notification) => {
            return (
              <StyledMenuItem
                key={notification.id}
                href={
                  notification.redirectUrl
                    ? `${notification.redirectUrl}`
                    : 'account-settings#tab2'
                }
                component='a'
                target={notification.redirectUrl ? "_blank" : ""}
                onClick={() => onMenuItemClick(notification.id)}
                sx={{
                  color: notification.read ? theme.themeColor.bodySecondary : theme.themeColor.bodyMain
                }}
              >
                <NotificationText>{notification.message}</NotificationText>
                <CreatedAt>{getTimeFromNow(+notification.createdAt)}</CreatedAt>
              </StyledMenuItem>
            )
          })
        )
        : (
          <MenuItem disabled={true}>
            No notifications
          </MenuItem>
        )
      }
      <ViewAll onClick={viewAllNotifications} theme={theme}>
        View all notifications
      </ViewAll>
    </StyledMenu>
  )
};

export default NotificationsDropdown;
import React, { useCallback } from 'react';
import { TextInput } from '../../styled/TextInput/TextInput';

export default function FilterText({ ...rest }) {
  const { api } = rest;

  const onTextInput = useCallback(() => {
    api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, [api]);

  return (
    <TextInput 
      type="text"
      id="filter-text-box"
      placeholder="Search"
      onInput={onTextInput}
    />
  )
}
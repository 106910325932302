import React, { useState, useContext } from 'react';
import { Container, FormGroup, Box, FormControlLabel, Checkbox } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { DEACTIVATE_ACCOUNT } from "../../apollo/mutations/deactivateAccount";
import { MessageContext } from '../../context/MessageContext';
import { UserContext } from '../../context/UserContext';
import Typography from "../../styled/Typography/Typography";
import Modal from "../../styled/Modal/Modal";
import Button from "../../styled/Button/Button";

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  width: 92vw !important;
  padding: 40px;
  margin-bottom: 20px;
`
const StyledContainer = styled(Container)`
  width: 100% !important;
  height: 75vh;
  margin: 0;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`
const SectionTitle = styled('h3')`
  margin-bottom: 25px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
const SectionText = styled('span')`
  margin-bottom: 25px;
`
const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
`

//TODO: Add flag/controller that checks if Terms has been updated
//TODO: Export ALL data as csv
//TODO: Remove/Delete account controller/logic

export default function DataAndLegal() {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();
  const { user, setUser, setPortfolioID, setTeamID } = useContext(UserContext)
  const { tncStatus, userType } = user;
  const { addMessage } = useContext(MessageContext);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [deactivateAccount] = useMutation(DEACTIVATE_ACCOUNT);

  async function clickDeactivate(event) {
    event.preventDefault();

    try {
      await deactivateAccount()
      setOpenConfirmation(false);
      addMessage({ message: "Account successfully removed." });

      setUser(null);
      setTeamID(null);
      setPortfolioID(null);
      sessionStorage.clear();
      client.clearStore();
      history.push("/auth/login");
    } catch(e) {
      addMessage({ message: "Unable to delete account. Please try again or come back later.", type: "error" })
    }
  }

  return (
    <StyledContainer theme={theme}>
      <StyledBox spacing={1}>
        <SectionTitle theme={theme}>Terms and Conditions</SectionTitle>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={tncStatus} disabled/>}
            label="You've accepted the Terms and Conditions"
          />
          {/* <SectionText style={{backgroundColor: 'gray'}}>
            We&apos;ve updated our Terms on 03/10/2022 13:05.
            You need to review the new Terms and accept them.
          </SectionText> */}
        </FormGroup>
      </StyledBox>

      {/* <StyledBox spacing={2}>
        <SectionTitle theme={theme}>Data</SectionTitle>
        <SectionText>
        You can export all your Assets, Team, Law Firm Members data
        in .csv format and download in an archive. You will receive a link
        once your archive is ready.
        </SectionText>
        <Button
          sx={{ width: '160px' }}
        >Export My Data</Button>
      </StyledBox> */}
      
      {(userType === "PORTFOLIO_MANAGER" && !user?.accDeleted) && (
        <StyledBox spacing={2}>
          <SectionTitle theme={theme}>Account Removal</SectionTitle>
          <SectionText>
            You can opt to remove your account with all your personal data,
            settings, assets, and team data at any time.
          </SectionText>
          <Button
            style={{backgroundColor: theme.themeColor.brandPrimaryRed, width: '160px'}}
            onClick={() => setOpenConfirmation(true)}
          >Remove Account</Button>
        </StyledBox>
      )}

      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <Typography>
          Are you sure you want to remove your account?
        </Typography>

        <ButtonBox>
          <Button
            className="mb-2"
            onClick={clickDeactivate}
            sx={{
              mr: 1,
              position: 'relative',
              width: '125px',
              backgroundColor: `${theme.themeColor.brandPrimaryRed} !important`
            }}
          >
          Confirm
          </Button>

          <Button
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
            sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
          >
          Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </StyledContainer>
  )
}
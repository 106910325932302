import React from "react";
import { Box } from '@mui/material';
import Modal from "../../styled/Modal/Modal";
import AssetImport from "../../pages/AssetImport";

const ImportAssets = ({show, onClose, firstImport}) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Box mt={5}>
        <AssetImport onClose={onClose} firstImport={firstImport} />
      </Box>
    </Modal>
  )
}

export default ImportAssets;
import { gql } from "@apollo/client";

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation(
    $invitation: updateInvitationInput!
  ) {
    updateInvitation(input: $invitation) {
      invitation {
        id
        status
      }
    }
  }
`;

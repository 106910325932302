import { useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import { UPDATE_ASSET_MUTATION } from "../../apollo/mutations/updateAssetMutation";
import Modal from "../../styled/Modal/Modal";
import TaskForm from "../TaskForm/TaskForm";
import AdditionalInfo from "./AdditionalInfo";
import Header from "./Header";
import TaskCommentsTimeline from "./TaskCommentsTimeline";

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: auto;
  align-items: flex-start;
  padding-top: 20px;
  margin: 0 -25px;
  padding: 25px;
`

const FormArea = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  font-weight: 500;
  font-size: 16px;
  overflow-y: auto;
  margin-right: 20px;
  width: 48%;
`

const TaskCommentsModal = ({
  processID,
  open,
  onClose,
  taskName,
  taskDescription,
  dueByMilestone,
  createdAt,
  portfolioUsers,
  assignmentsValue,
  handleReassignTask,
  blocking,
  user,
  deadlineAt,
  handleSubmit,
  newComment,
  handleChange,
  formError,
  loading,
  addToTimeline,
  setAddToTimeline,
  comments,
  forms,
  assetID,
  milestoneID
}) => {
  const theme = useTheme();

  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  
  const onSaveForm = async (formValues) => {
    if (taskName === "Submit Sale Schedule Date") {
      // Should update asset to save form date in database
      const input = formValues.find((value) => value.label === "Sale scheduled date");
      
      await updateAsset({ variables: 
        { 
          asset: {
            where: {id: assetID},
            data: {
              saleScheduledDate: input.value
            }
          }
        } 
      });
    }
  }

  return (
    <Modal open={open} onClose={onClose} width="85vw" height="80vh">
      <Header taskName={taskName} taskDescription={taskDescription} dueByMilestone={dueByMilestone} createdAt={createdAt} />
      
      <Container>
        <Box sx={{ width: '48%' }}>
          <AdditionalInfo 
            portfolioUsers={portfolioUsers}
            assignmentsValue={assignmentsValue}
            handleReassignTask={handleReassignTask}
            blocking={blocking}
            user={user}
            deadlineAt={deadlineAt} 
          />
          <TaskCommentsTimeline
            handleSubmit={handleSubmit}
            newComment={newComment}
            handleChange={handleChange}
            formError={formError}
            loading={loading}
            addToTimeline={addToTimeline}
            setAddToTimeline={setAddToTimeline}
            comments={comments}
            showEffect={false}
          />
        </Box>
        {forms && (
          <FormArea theme={theme}>
            {forms.map((form) => (
              <TaskForm assetID={assetID} key={form.title} form={form} processID={processID} onSaveForm={onSaveForm} milestoneID={milestoneID} />
            ))}
          </FormArea>
        )}
      </Container>
    </Modal>
  )
}

export default TaskCommentsModal;
import React from "react";
import { styled } from "@mui/material";
import { rgba } from "emotion-rgba";
import { TextInput } from "../../styled/TextInput/TextInput";
import { Box, Autocomplete, CircularProgress, Select, MenuItem } from "@mui/material";
import DatePickerSelect from "../DatePickerSelect/DatePickerSelect";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";

const Container = styled(Box)`
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: ${({ userType }) => userType === "PORTFOLIO_MANAGER" ? "150px" : "80px"}
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 5px 25px;
  font-weight: 500;
  font-size: 16px;
  overflow-y: auto;
  min-height: 30%;
  &::-webkit-scrollbar {
    display: none;
  }
`
const DueByBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;
const DueBy = styled('span')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AdditionalInfo = ({
  portfolioUsers,
  assignmentsValue,
  handleReassignTask,
  blocking,
  user,
  process,
  deadlineAt,
  dueByMilestone,
  updateTaskDate,
  isTaskFlow,
  isSubtask,
  updateTaskMilestone,
  taskDescription,
  readOnly
}) => {
  const theme = useTheme();

  const getOptionLabel = (option) => (
    option.name ? option.name : `${option.firstName} ${option.lastName}`
  )

  return (
    <Container userType={user.userType} theme={theme}>
      <Typography mb={2}>{taskDescription}</Typography>
      {blocking && (
        <span style={{ color: theme.themeColor.brandPrimaryRed, marginBottom: '15px', marginTop: "-10px" }}>
            This is a blocking task.
        </span>
      )}

      <DueByBox>
        <span style={{ width: isTaskFlow || isSubtask ? '100%' : '30%'}}>
          Due by:
          <DatePickerSelect
            placeholder="Due Date"
            selected={deadlineAt && new Date(+deadlineAt)}
            setSelected={updateTaskDate}
            disabled={readOnly}
          />
        </span>

        <DueBy
          style={{
            width: isTaskFlow || isSubtask ? '0' : '65%',
            visibility: isTaskFlow || isSubtask ? 'hidden' : 'show'
          }}
        >
          Due by:
          <Select
            value={dueByMilestone && dueByMilestone}
            label=""
            id="due-by-milestone"
            onChange={updateTaskMilestone}
          >
            {
              process && process.processSteps.map((item) => {
                return <MenuItem 
                  {...item}
                  key={item.stepID}
                  value={item.stepID}
                >
                  {`${item.stepID} - ${item.label}`}
                </MenuItem>
              })
            }
          </Select>
        </DueBy>
      </DueByBox>
      
        
      <span style={{float: "left", display: 'inline'}}>Assigned to:
        {portfolioUsers ? 
          (<Autocomplete
            popupIcon={""}
            readOnly={readOnly}
            multiple
            groupBy={(option) => `${option.__typename}s`}
            sx={{ width: "250px", marginTop: "5%", maxHeight: "100px" }}
            size="small"
            value={assignmentsValue}
            options={portfolioUsers}
            disablePortal
            getOptionLabel={getOptionLabel}
            onChange={handleReassignTask}
            renderInput={(params) => <TextInput {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                "data-testid": "assign-task-to",
              }}
            />}
          />) :
          (<CircularProgress />)
        }
      </span>

    </Container>
  )
}

export default AdditionalInfo;
const { differenceInDays } = require('date-fns');

export function sortRanges(dateRanges) {
  let result = [...dateRanges].sort((prev, current) => {
    var previousTime = prev.startDate;
    var currentTime = current.startDate;

    if (previousTime < currentTime) {
      return -1;
    }

    if (previousTime > currentTime) {
      return 1;
    }

    return 0;
  });

  return result;
}

export function calculateTotalDaysInRange(dates) {
  return dates.map((dateRange) => {
    const totalDays = differenceInDays(dateRange.end, dateRange.start);
    return {
      ...dateRange,
      totalDays,
    };
  });
}

export function getUniqueDateRange(ranges) {
  const sortedRanges = sortRanges(ranges);
  let overlapingRange = [];
  let uniqueDateRange = [];

  function checkIfDateAreOverlapping(currentEnd, nextStart) {
    const overlap = currentEnd >= nextStart;
    return overlap;
  }
  sortedRanges.forEach((range, index) => {
    const nextRange = ranges[index + 1];
    const prevRange = ranges[index - 1];
    const currentRange = range;

    const isOverlapping = nextRange
      ? checkIfDateAreOverlapping(
        currentRange.closeDate || currentRange.expectedCloseDate,
        nextRange.startDate
      )
      : false;

    const prevDayOverlap = prevRange
      ? checkIfDateAreOverlapping(
        prevRange.closeDate || prevRange.expectedCloseDate,
        currentRange.startDate
      )
      : false;

    if (isOverlapping || prevDayOverlap) {
      if (isOverlapping) {
        overlapingRange.push(range);
      }

      if (prevDayOverlap && isOverlapping === false) {
        overlapingRange.push(range);

        const id = overlapingRange[0].id;

        const start = overlapingRange[0].startDate;
        const end =
          overlapingRange[overlapingRange.length - 1].closeDate !== null
            ? overlapingRange[overlapingRange.length - 1].closeDate
            : overlapingRange[overlapingRange.length - 1].expectedCloseDate;
        const hasCloseDate = overlapingRange[overlapingRange.length - 1].closeDate !== null;
        const updateRange = { ...range };
        if (hasCloseDate) {
          updateRange.id = id;
          updateRange.startDate = start;
          updateRange.closeDate = end;
        } else {
          updateRange.id = id;
          updateRange.startDate = start;
          updateRange.closeDate = null;
          updateRange.expectedCloseDate = end;
        }
        uniqueDateRange.push(updateRange);
        overlapingRange = [];
      }
    } else {
      uniqueDateRange.push(range);
    }
  });

  return uniqueDateRange;
}

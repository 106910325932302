import { gql } from "@apollo/client";

export const UPDATE_MEMBERSHIP = gql`
  mutation UpdateMembership($where: ID, $data: JSONObject) {
    updateMember(where: $where, data: $data) {
      id
      type
      deleted
    }
  }
`;
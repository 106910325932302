import React, { useEffect, useRef, useState } from "react";
import Modal from "../../styled/Modal/Modal";
import { BULK_IMPORT_TASK_FLOW_TEMPLATES } from "../../apollo/mutations/bulkImportTaskFlowTemplates";
import { useMutation } from "@apollo/client";
import { TextInput } from "../../styled/TextInput/TextInput";
import Papa from "papaparse";
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import { useTheme } from "@emotion/react";

const DropFileZone = ({ setFile }) => {
  const fileInput = useRef(null);

  async function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = ({ target: { result } }) => resolve(result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  const preUploadFile = async (file) => {
    try {
      await readFileContent(file);
      setFile(file);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = ({ target: { files } }) => preUploadFile(files[0]);
  const stopDragEvent = (event) =>
    event.preventDefault() && event.stopPropagation();
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    preUploadFile(files[0]);
  };

  return (
    <label
      onDrop={handleDrop}
      onDragOver={stopDragEvent}
    >
      <TextInput
        onDrop={handleDrop}
        id="upload-document-dropzone"
        onDragOver={stopDragEvent}
        onChange={handleFileChange}
        onClick={() => fileInput.current?.click()}
        type='text'
        placeholder='Choose a file to upload...'
        sx={{width: '100%'}}
      />
      <input
        type='file'
        ref={fileInput}
        onChange={handleFileChange}
        onDrop={handleDrop}
        onDragEnter={() => fileInput.current.handleDragEnter}
        onDragOver={stopDragEvent}
        style={{visibility: 'hidden'}}
      />
    </label>
  )
}

const StaffBulkImportModal = ({ show, onClose }) => {
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const [bulkImportTaskFlowTemplates] = useMutation(BULK_IMPORT_TASK_FLOW_TEMPLATES);

  const handleParse = () => {
    setSuccessMessage('');
    setErrorMessage('');
    if (!file) return;

    const reader = new FileReader();
     
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true, dynamicTyping: true });
      const parsedData = csv?.data;
      // in case the task order is a string, convert it to int
      if (typeof parsedData[0]["Task Order"] === "string") {
        parsedData.forEach((item) => {
          item["Task Order"] = parseInt(item["Task Order"]);
        });
      }

      setData(parsedData);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    handleParse();
  }, [file]);

  const handleSubmit = () => {
    setSuccessMessage('');
    setErrorMessage('');

    setLoading(true);

    bulkImportTaskFlowTemplates({ variables: { input: { data } } })
      .then((res) => {
        if (res.data.bulkImportTaskFlowTemplates.errors) {
          console.log(res.data.errors);
          setErrorMessage('Something went wrong. Check console for details.');
        }
        if (res.data.bulkImportTaskFlowTemplates.success) {
          setSuccessMessage(`A total of ${res.data.bulkImportTaskFlowTemplates.total} task flow templates successfully added.`);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage('Something went wrong. Check console for details.');
        setLoading(false);
      });
  }

  const onClickClose = () => {
    onClose();
    location.reload();
  }
  
  return (
    <Modal open={show} onClose={onClickClose}>
      <Typography>Select file</Typography>
      <DropFileZone
        setFile={setFile}
      />
      {successMessage && <Typography sx={{ color: theme.themeColor.brandPrimaryGreen }}>{successMessage}</Typography>}
      {errorMessage && <Typography sx={{ color: theme.themeColor.brandPrimaryRed }}>{errorMessage}</Typography>}
      <Button disabled={!data} onClick={handleSubmit} loading={loading}>Import</Button>
    </Modal>
  )
}

export default StaffBulkImportModal;
import React, { useState } from 'react';
import Checkbox from '../../styled/Checkbox/Checkbox';
import styled from '@emotion/styled';
import { Box, Stack, Chip } from '@mui/material';
import Button from '../../styled/Button/Button';

const TitleArea = styled(Box)`
  display: flex;
  width: 600px;
  justify-content: space-between;
`

const NotificationsSettings = () => {
  const [lawFirmCheckbox, setLawFirmCheckbox] = useState(false);
  const [milestoneOpenned, setMilestoneOpenned] = useState(false);
  const [milestoneClosed, setMilestoneClosed] = useState(false);
  const [memberInvite, setMemberInvite] = useState(false);
  const [memberDeleted, setMemberDeleted] = useState(false);

  const enableAllAssets = () => {
    setLawFirmCheckbox(true);
    setMilestoneOpenned(true);
    setMilestoneClosed(true);
  }

  const enableAllTeam = () => {
    setMemberInvite(true);
    setMemberDeleted(true);
  }

  const handleDelete = () => {
    console.log('delete');
  }
  
  return (
    <div>
      <Box>
        <TitleArea>
          <h3 style={{color: 'black'}}>Assets</h3>
          <Button 
            sx={{width: 150}}
            variant="secondary"
            onClick={enableAllAssets}
          >Enable All</Button>
        </TitleArea>
        <Checkbox
          label="Law firm accepted a referral"
          checked={lawFirmCheckbox}
          onClick={() => setLawFirmCheckbox(!lawFirmCheckbox)} 
        />
        <Checkbox
          label="Milestone openned"
          checked={milestoneOpenned}
          onClick={() => setMilestoneOpenned(!milestoneOpenned)} 
        />
        <Checkbox
          label="Milestone closed"
          checked={milestoneClosed}
          onClick={() => setMilestoneClosed(!milestoneClosed)} 
        />
      </Box>

      <Box>
        <TitleArea>
          <h3 style={{color: 'black'}}>Team</h3>
          <Button 
            sx={{width: 150}}
            variant="secondary"
            onClick={enableAllTeam}
          >Enable All</Button>
        </TitleArea>
        <Checkbox
          label="Member accepted invite"
          checked={memberInvite}
          onClick={() => setMemberInvite(!memberInvite)} 
        />
        <Checkbox
          label="Team member deleted"
          checked={memberDeleted}
          onClick={() => setMemberDeleted(!memberDeleted)} 
        />
      </Box>

      <Box>
        <TitleArea>
          <h3 style={{color: 'black'}}>Subscribed Assets</h3>
          <Button 
            sx={{width: 150}}
            variant="secondary"
          >Unsubscribe All</Button>
        </TitleArea>
        <Stack direction="row" spacing={1}>
          <Chip
            label="#123456789"
            onDelete={handleDelete}
          />
          <Chip
            label="#398765421"
            variant="outlined"
            onDelete={handleDelete}
          />
        </Stack>
      </Box>

      <Box sx={{mt: 5}}>
        <Button sx={{width: 150}}>Save Changes</Button>
      </Box>
    </div>
  )
}

export default NotificationsSettings;
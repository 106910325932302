import { useQuery } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { rgba } from "emotion-rgba";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { GET_STAFF_TEMPLATES } from "../apollo/queries/staffTemplates";
import TaskFlowTemplates from "../components/StaffTaskTemplate/StaffTaskFlowTemplates";
import { UserContext } from "../context/UserContext";

const StyledPageContainer = styled(Box)`
  background: ${({theme}) => rgba(theme.themeColor.sectionFill, 0.6)};
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  backdrop-filter: blur(20px);
  border-radius: 4px;
  margin: 11px;
`

const StaffTasks = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [taskFlows, setTasksFlow] = useState([]);
  const { user } = useContext(UserContext);
  
  const theme = useTheme();
  
  const { data, loading } = useQuery(GET_STAFF_TEMPLATES);
  
  useEffect(() => {
    if (data) {
      setTasksFlow(data.findStaffTemplates);
    }
  }, [data]);

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  if (!user.staff) return <p>not authorized</p>

  if (loading) return <CircularProgress />

  return (
    <StyledPageContainer theme={theme}>
      <Tabs value={selectedTab} onChange={handleChange} >
        <Tab label="Tasks Flows" />
      </Tabs>
      
      <Box sx={{ height: '80vh' }}>
        {selectedTab === 0 && <TaskFlowTemplates templates={taskFlows} />}
      </Box>
    </StyledPageContainer>
  );
}

export default StaffTasks;
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Box from "@mui/material/Box";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import Modal from "../../styled/Modal/Modal";
import "./footer.scss";

const StyledLink = styled("a")`
  text-decoration: none;
  color: ${({theme}) => theme.themeColor.bodySecondary };
  &:hover {
    color: ${({theme}) => theme.themeColor.brandPrimaryBlue};
    text-decoration: none;
  };
`
const StyledTermsContainer = styled(Box)`
  height: 500px;
  overflow-y: scroll;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  padding: 5px 20px;
  border-radius: 5px;
  margin-top: 33px;
  border-radius: ${({theme}) => theme.themeSizing.borderRadiusSmall};
`

const Footer = () => {
  const year = new Date().getFullYear();
  const theme = useTheme();

  const [showTerms, setShowTerms] = useState(false);

  return (
    <footer className="main-footer">
      <div>
        <span style={{ fontSize: '1.25em', color: '#106AF1', marginRight: '30px', cursor: "default" }}>
          Jonah
        </span>
        <span>
          ©{year} Jonah All Rights Reserved. A Product of Redhawk Research, LLC.
        </span>
      </div>
      <div>
        <StyledLink
          style={{marginRight: '30px'}}
          onClick={() => setShowTerms(!showTerms)}
        >
          Terms and Conditions
        </StyledLink>
        <StyledLink href="mailto:info@redhawkresearch.com?subject=Jonah Direct" theme={theme}>
          Need help?
        </StyledLink>
      </div>
      <Modal open={showTerms} onClose={() => setShowTerms(false)}
        width="66vw"
      >
        <StyledTermsContainer theme={theme}
          sx={{ backgroundColor: theme.themeColor.bodyTetriary }}
        >
          <TermsAndConditions />
        </StyledTermsContainer>
      </Modal>
    </footer>
  );
};

export default Footer;

import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import Typography from "../../styled/Typography/Typography";

export const Form = styled.form`
  margin: 0 auto;
  padding: 40px;
  max-width: 380px;
  min-height: 565px;
  border: 1px solid rgba(31, 32, 32, 0.2);
  box-shadow: 4px 4px 40px rgba(31, 32, 32, 0.04);
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
  background-color: ${(props) => props.theme.themeColor.medGrey};
`;

export const FormHeading = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 20px;
  color: ${(props) => props ? props.theme.themeColor.textColor : "white"};
`;

export const FormText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${(props) => props ? props.theme.themeColor.textColor : "white"};
`;

export const FormRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const FormRadioGroupLabel = styled(FormLabel)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${(props) => props ? props.theme.themeColor.textColor : "white"};


  &.Mui-focused {
    color: ${(props) => props ? props.theme.themeColor.textColor : "white"};
  }
`;

export const FormRadioGroupButton = styled(FormControlLabel)`
  display: flex;
  background: ${(props) => (props.checked ? props.theme.themeColor.darkGrey : props.theme.themeColor.lightMedGrey)};
  margin: 0;
  width: 100%;
  height: 44px;

  > input {
    color: ${(props) => props ? props.theme.themeColor.textColor : "white"};
  }

  &:first-of-type {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusSmall};
`;

export const LinkButtonLarge = styled(Link)`
  height: 44px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #106af1;
  border-radius: 4px;
  padding: 10px 37px;
  color: #fff;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  &:hover {
    background: #0e5cdd;
    color: white;
    text-decoration: none;
  }
`;

export const LinkButtonSmall = styled(Link)`
  height: 26px;
  width: fit-content;

  display: inline-block;

  background: ${(props) => props.theme.themeColor.lightMedGrey};
  border-radius: ${(props) => props.theme.themeSizing.borderRadiusSmall};
  color: ${(props) => props.theme.themeColor.textColor};

  padding: 4px 8px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    background: #585757;
    color: white;
    text-decoration: none;
  }
`;

import React, { memo, useMemo, useState, useContext, useEffect } from "react";
import AssetImportTable from "./AssetImportTable/AssetImportTable";
import { useHistory } from "react-router-dom";
import { MessageContext } from '../../context/MessageContext';
import Modal from "../../styled/Modal/Modal";
import { Box } from "@mui/material";
import Button from "../../styled/Button/Button";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";
import { AssetImportContext } from "../../context/AssetImportContext";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { CHECK_CONTENT_MAPPING } from "../../apollo/queries/checkContentMappingQuery";

export const FIELDS_MATCH = gql`
  mutation fieldsMatch($input: csvFieldsMatchDBInput) {
    csvFieldsMatchDB(input: $input)
  }  
`;

const required = [
  "loanNumber", "state"
]

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const TableContainer = styled(Box)`
  overflow: hidden;
  width: 95%;
  margin: 30px auto;
  border: ${({ theme }) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`

const TableHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

function AssetDataMapper({ file, data, removeFile, setDataToSend, onContinueLink, onFinish, firstImport, isCsvMatching }) {
  const { addMessage } = useContext(MessageContext);
  const { mappedFields, destinationOptions, analysis, target, setNewMappedFields } = useContext(AssetImportContext);
  const { parsedData } = analysis;

  const history = useHistory();
  const theme = useTheme();
  const [csvFieldsMatch, setCsvFieldsMatch] = useState(isCsvMatching || false);
  const [dataToSendLocal, setDataToSendLocal] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [hasDuplicateLoanNums, setHasDuplicateLoanNums] = useState(false);
  const [hasDuplicateIds, setHasDuplicateIds] = useState(false);

  const [checkMapData, { loading: loadingCheck }] = useLazyQuery(CHECK_CONTENT_MAPPING);

  const [fieldsMatch] = useMutation(FIELDS_MATCH);

  useEffect(async () => {
    const dataToSend = setData();
    const res = await fieldsMatch({ variables: { input: dataToSend } });
    if (res?.data) {
      setCsvFieldsMatch(res.data.csvFieldsMatchDB);
    }
  }, [mappedFields]);

  // Handler Mapping
  const selectDestinationField = (source) => {
    const { id, target: sourceTarget } = source;
    const newFields = {...mappedFields};

    // If ID is already in some targetField, remove it first
    Object.entries(mappedFields).forEach(([key, value]) => {
      if (value.targetField === id) {
        newFields[key] = {
          ...value,
          targetField: "none",
          targetFormat: "none"
        }
      }
    });

    const getDataType = (array, columnName) => {
      const column = array.find(col => col.column_name === columnName);
      return column ? column.data_type : null;
    };

    newFields[sourceTarget] = {
      ...mappedFields[sourceTarget],
      targetField: source.id,
      targetFormat: source.id !== "none" ? getDataType(target, id) : undefined
    }

    setNewMappedFields(newFields);
  }

  // Mapping Table Headers
  const headers = mappedFields && useMemo(
    () =>
      Object.keys(mappedFields).map((field) => ({
        name: field,
        format: mappedFields[field].format,
        value: mappedFields[field].targetField,
      })),
    [mappedFields]
  );

  const setData = () => {
    const dataToSend = {
      source: "upload",
      type: "text/csv",
      options: { filename: file.name },
      data,
      contentType: "application::asset.asset",
      fieldMapping: mappedFields,
    };

    setDataToSendLocal(dataToSend);
    setDataToSend(dataToSend);
    return dataToSend;
  }

  const onContinue = async () => {
    // Prevent Upload Empty Data;
    if (parsedData.length === 0) {
      addMessage({ message: 'The provided file does not contain any usable data.', type: "error" });
      removeFile();
    }
    const fieldsToMap = Object.values(mappedFields).map((field) => field.targetField).filter(field => field !== 'none');

    const hasDuplicateDestinationFields = fieldsToMap.some((field, i) => fieldsToMap.indexOf(field) !== i);
    if (hasDuplicateDestinationFields) {
      addMessage({ message: "There are multiple fields mapping to the same destination. Please resolve these conflicts", type: "error" })
      return;
    }

    // If users don't map required fields...
    if (!required.every(val => fieldsToMap.includes(val))) {
      const missing = required.filter((item) => !fieldsToMap.includes(item))
      addMessage({ message: `There are missing fields that are required. Please enter values for ${missing}`, type: "error"})
      return;
    }

    let mappedLoanNumber = null;
    for (const mp in mappedFields) {
      if (mappedFields[mp].targetField == "loanNumber") {
        mappedLoanNumber = mp;
      }
    }
    if (!mappedLoanNumber) {
      addMessage({ message: 'You need to have a field mapped to Loan Number.', type: "error" });
      return;
    }

    const loanNums = parsedData.map((loan) => loan[mappedLoanNumber]);
    const ids = parsedData.filter((loan) => loan.id);
    const hasDuplicateLoanNums = loanNums.some((num, i) => loanNums.indexOf(num) != i);
    setHasDuplicateLoanNums(hasDuplicateLoanNums);
    const hasDuplicateIds = ids.some((id, i) => ids.indexOf(id) != i);
    setHasDuplicateIds(hasDuplicateIds);
    if (hasDuplicateLoanNums || hasDuplicateIds) {
      setOpenConfirmation(hasDuplicateLoanNums || hasDuplicateIds);
      return;
    }

    // Check in backend if selected data is valid
    const checkMapDataInput = {
      parsedData,
      mappedFields,
    };
    const checkResponse = await checkMapData({ variables: { input: checkMapDataInput } });
    if (checkResponse.data.checkContentMapping.error) {
      addMessage({ message: checkResponse.data.checkContentMapping.error, type: "error" });
      return;
    }
    
    onFinal();
  };

  const onFinal = () => {
    if (firstImport) {
      onFinish(firstImport, dataToSendLocal);
    } else {
      history.push(onContinueLink);
    }
  }

  return (
    <Modal open={true} width='65vw' sx={{display: 'flex', flexDirection: 'column'}}>
      <TableHeader>
        <Typography variant="h4" component="h1" mt={2}>Map Assets</Typography>
        <Typography variant="h6" component="h3" mt={2} mr={3}
          sx={{ color: theme.themeColor.brandPrimaryRed, fontSize: '1em' }}
        >
          *Loan Number and State are required fields
        </Typography>
      </TableHeader>
      {csvFieldsMatch ? 
        <p>All of your csv fields match the database. No need for manual mapping. You may proceed.</p>
        :
        <TableContainer theme={theme}>
          <AssetImportTable
            headers={headers}
            headersMappingOptions={destinationOptions}
            onChangeMapping={selectDestinationField}
          />
        </TableContainer>
      }
      <ButtonBox>
        <Button
          label="Import"
          onClick={onContinue}
          loading={loadingCheck}
          className="mb-2"
          sx={{
            mr: 1,
            position: 'relative',
            width: '125px'
          }}
        >
          { firstImport ? "Finish" : "Continue" }
        </Button>

        <Button
          variant="secondary"
          onClick={removeFile}
          sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
        >
          Cancel
        </Button>
      </ButtonBox>

      <Modal open={openConfirmation}>
        <Typography component="h1" variant="h4" mt={1} mb={2}>
        Your file contains assets with duplicate{" "}
          {hasDuplicateLoanNums && hasDuplicateIds ? "loan numbers and loan IDs."
            : hasDuplicateLoanNums ? "loan numbers."
              : "loan IDs."
          }
        </Typography>
        <Typography component="h1" variant="h5" mb={2}>
        Are you sure you want to continue?
        </Typography>

        <ButtonBox>
          <Button 
            onClick={() => {setOpenConfirmation(false); onFinal()}}
            sx={{width:130, marginRight: '25px'}}
          >
          Continue
          </Button>

          <Button
            variant="secondary"
            onClick={removeFile}
            sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
          >
          Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </Modal>
  )
}

export default memo(AssetDataMapper);

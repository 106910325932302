import { gql } from "@apollo/client";

export const UPDATE_HOLD_AND_EVENTS_MUTATION = gql`
  mutation UpdateHoldAndLog(
    $hold: updateHoldInput!
    $event: createEventInput!
  ) {
    updateHold(input: $hold) {
      id
    }

    createEvent(input: $event) {
      id
    }
  }
`;

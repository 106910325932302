import React, { useState, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import Overview from "./Overview";
import Milestones from "../Milestones/Milestones";
import CreateComment from "../Comments/CreateComment";
import AddTaskAndHold from "../Milestones/AddTaskAndHold";
import FilterAssetTimeline from "../../Modals/FilterAssetTimeline";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import { useTheme } from "@emotion/react";
import CurrentMilestone from "../CurrentMilestone";
import { getActiveStep } from "../../../pages/AssetDetails";

const FloatButton = styled('div')`
  position: absolute;
  bottom: 60px;
  left: 20px;
`
const MilestonesBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 84vh;
  background: ${({theme}) => theme.themeColor.backgroundBody};
`

const TimelinePattern = styled('div')`
  position: absolute;
  background-color: transparent;  
  background: repeating-linear-gradient( 180deg, transparent, transparent 10px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 1px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 11px);
  height: 83%;
  width: 5px;
  z-index: 1;
  top: 100px;
`

export default function Foreclosure({
  asset,
  originalFC,
  sideBarStats,
  nextStepStats,
  currentMilestone,
  setCurrentMilestone,
  fcMilestones,
  setScreen,
  readOnly,
  filters,
  activeProcess,
  setActiveProcess,
  setAsset
}) {
  const theme = useTheme();
  const { portfolioID } = useContext(UserContext);
  const [openTask, setOpenTask] = useState(false);
  const [openHold, setOpenHold] = useState(false);
  const [addTaskFlow, setAddTaskFlow] = useState(false);
  const [openFilterTimeline, setOpenFilterTimeline] = useState(false);
  const [timelineFilters, setTimelineFilters] = useState(filters);
  const [numFiltersChanged, setNumFiltersChanged] = useState("0");

  const hasBlockingTask = activeProcess?.tasks?.some((task) => task?.blocking && !task?.deleted && !task?.completedAt)
    || activeProcess?.task_flows?.some((task) => task?.blocking && !task?.completedAt);

  const hasOpenHolds = activeProcess?.holds.some((hold) => !hold?.closeDate);
  // Given to addtask/openhold/createcomment
  // Users will only create things for the active milestone, not currently selected(to view)
  const milestoneToUpdate = getActiveStep(fcMilestones);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Overview
          sideBarStats={nextStepStats}
          subStepStats={sideBarStats}
        />
        <CurrentMilestone
          asset={asset}
          setAsset={setAsset}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          milestoneID={originalFC ? originalFC.stepID : currentMilestone?.fc?.stepID}
          milestones={fcMilestones}
          activeStep={originalFC ? originalFC.stepID : activeProcess.stepID}
          process={originalFC || activeProcess}
          hasBlockingTask={hasBlockingTask}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          hasOpenHolds={hasOpenHolds}
          assetState={asset.state}
          readOnly={readOnly}
          originalFC={originalFC}
          openFilter={setOpenFilterTimeline}
          numFiltersChanged={numFiltersChanged}
        />
        <TimelinePattern theme={theme} />
        <MilestonesBackground theme={theme} />
        <Milestones
          process={originalFC || activeProcess}
          activeStep={originalFC ? originalFC.stepID : activeProcess?.stepID}
          milestoneID={currentMilestone?.fc?.stepID}
          setOpenHold={setOpenHold}
          setOpenTask={setOpenTask}
          startDate={originalFC ? originalFC.startDate : activeProcess?.startDate}
          currentMilestone={currentMilestone}
          setAddTaskFlow={setAddTaskFlow}
          readOnly={readOnly}
          timelineFilters={timelineFilters}
        />
        <CreateComment milestoneID={milestoneToUpdate.stepID} processID={originalFC ? originalFC.id : activeProcess?.id}/>
        <FloatButton>
          <AddTaskAndHold
            asset={asset}
            substeps={fcMilestones}
            process={originalFC ? originalFC : activeProcess}
            milestoneID={milestoneToUpdate.stepID}
            openTask={openTask}
            openHold={openHold}
            setOpenTask={setOpenTask}
            setOpenHold={setOpenHold}
            addTaskFlow={addTaskFlow}
            setAddTaskFlow={setAddTaskFlow}
            portfolioID={portfolioID}
            setScreen={setScreen}
            processType='FORECLOSURE'
            activeProcess={activeProcess}
            setActiveProcess={setActiveProcess}
            setAsset={setAsset}
          />
        </FloatButton>
      </div>
      <FilterAssetTimeline
        open={openFilterTimeline}
        onClose={() => setOpenFilterTimeline(false)}
        setFilters={setTimelineFilters}
        filters={timelineFilters}
        setNumFilters={setNumFiltersChanged}
      />
    </>
  )
}

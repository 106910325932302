import React from "react";
import { Radio as MUIRadio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { rgba } from 'emotion-rgba';

const StyledRadioGroup = styled('div')`
  display: flex;
  align-items: center;
  background: ${(props) => props.checked ? rgba(props.theme.themeColor.brandPrimaryBlue, 0.05) : rgba(props.theme.themeColor.bodyMain, 0.05) };
  color: ${(props) => rgba(props.theme.themeColor.bodyMain, 0.6)};
  padding: ${(props) => props.size === 'large' ? '8px 12px' : '4px 12px'};
  border-radius: 4px;
  margin: 15px 0px;
`

const StyledFormControlLabel = styled(FormControlLabel) `
  margin-bottom: 0;
  font-size: ${(props) => props.size === 'large' ? '16px' : '14px'};
`

const Radio = (props) => {
  const { label, size, checked } = props;

  const theme = useTheme();
  
  return (
    <>
      {!label && (<MUIRadio {...props} />)}
      {label && (
        <StyledRadioGroup size={size} theme={theme} checked={checked}>
          <StyledFormControlLabel {...props} control={<MUIRadio />} label={label} size={size} />
        </StyledRadioGroup>
      )}
    </>
  );
};

export default Radio;


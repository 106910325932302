import { gql } from "@apollo/client";

export const COMMENT_HOLD_MUTATION = gql`
  mutation createHoldComment($input: createHoldCommentInput!) {
    createHoldComment(input: $input) {
      id
      createdAt
      comment
      createdBy {
        firstName
        lastName
      }
    }
  }
`;
import { gql } from "@apollo/client";

export const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset(
    $asset: updateAssetInput!
  ) {
    updateAsset(input: $asset) {
      id
      saleScheduledDate
    }
  }
`;

import { Box } from "@mui/material";
import React from "react";
import ColoredDot from "../ColoredDot/ColoredDot";

const ReferralStatus = ({value}) => {
  let color;
  
  if (value === 'ACCEPTED') color = 'green';
  if (value === 'REJECTED') color = 'red';
  
  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <ColoredDot label={value} color={color} />
    </Box>);
}

export default ReferralStatus;
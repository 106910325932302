import React from 'react';
import Chip from '../../styled/Chip/Chip';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';

const StyledStack = styled(Stack)`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  };
`

export default function TeamCell({ value }) {
  if (!value || !value.length) {
    return "--"
  } 
  return (
    <div
      style={{
        width: "200px",
        overflow: "hidden"
      }}
    >
      <StyledStack
        spacing={-1}
        direction="row"
      >
        {
          value.map((team) => (
            <Chip label={team.name} key={`team-${team.id}`} />
          ))
        }
      </StyledStack>
    </div>
  )
}
import React from 'react';
import Chip from '../../styled/Chip/Chip'

const NotificationType = ({value}) => {
  const getColor = () => {
    switch (value) {
    case 'Hold':
      return 'orange';
    case 'Task':
      return 'green';
    case 'Milestone':
      return 'blue';
    case 'Info':
      return 'dark';
    default:
      return 'blue';
    }
  }

  return <Chip newColor={getColor()} label={value}></Chip>
}

export default NotificationType;
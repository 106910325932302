import React from "react";
import {
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";

import '../styles/pages.scss'

import { useLocalStorage } from "../hooks/useLocalStorage";
import LoginForm from "../components/LoginForm/LoginForm";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import RegisterLawyerForm from '../components/RegisterLawyerForm/RegisterLawyerForm';
import AcceptTeamInviteForm from '../components/AcceptInviteForm/AcceptTeamInviteForm';
import BlockedAccount from '../components/BlockedAccount/BlockedAccount';
import ForgotPassword from "../pages/ForgotPassword";
import ConfirmEmail from "../components/ConfirmEmail/ConfirmEmail";
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Footer from "../components/Footer/Footer";
import ResetPassword from "../pages/ResetPassword";

import Button from "../styled/Button/Button";
import { Box } from '@mui/material';
import newLogo from "../images/newlogo.svg";
import { FaArrowLeft } from "react-icons/fa";
import Typography from "../styled/Typography/Typography";
import signinBG from "../img/signin-bg.png";
import registerBG from "../img/home-bg.png";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StyledNav = styled('nav')`
  background-color: ${(props) => props.theme.themeColor.backgroundBody};
  color: ${(props) => props.theme.themeColor.bodyMain};
  justify-content: center;
`
  
const FormPanel = styled('div')`
  background-color: ${(props) => props.theme.themeColor.backgroundBody};
  color: ${(props) => props.theme.themeColor.bodyMain};
`

const selectStyles = () => {
  const url = window.location.href.split("/");
  const currPath = url[url.length - 1];
  const isLogin = url.some((str) => str.includes("login"));
  const isRegister = url.some((str) => str.includes("accept-invite"))
    || url.some((str) => str.includes("accept-team-invite"));

  const result = { bg: "", headerBG: "white" }

  if (currPath === "register" || isRegister) 
    result.bg = registerBG
  else if (currPath === "login" || isLogin) {
    result.headerBG = "transparent"
    result.bg = signinBG
  }
  else result.bg = "";

  return result;
}

function AuthPage({ client }) {
  const theme = useTheme();

  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { value: user } = useLocalStorage("user");
  const query = new URLSearchParams(location.search);
  const next = query.get("next") || '/';

  if (user !== null) return <Redirect to={next} />;
  return (
    <div className="auth-page bg-image fullhieght"
      style={{ backgroundImage: `url(${selectStyles().bg})` }}
    >
      <StyledNav className='auth-nav' theme={theme}
        style={{ background: selectStyles().headerBG }}
      >
        <div className='auth-logo'>
          <Button 
            icon={<FaArrowLeft style={{ height: '12px', width: '12px' }}/>}
            iconPosition="left"
            variant="secondary"
            onClick={() => window.location = 'http://jonahdirect.com/'}
            sx={{width: '75px', fontSize: '14px', boxShadow: 'none', marginRight: '15px'}}
          >
            Home
          </Button>
          <img src={newLogo} style={{margin: '0 10px 0 25px'}}/>
          <Typography
            sx={{ color: theme.themeColor.brandPrimaryBlue, fontWeight: 500, fontSize: '1.25em' }}
          >
            Jonah Direct
          </Typography>
        </div>
        
        <Box sx={{ opacity: "1 !important", '& button': { m: 1 } }}>
          {location.pathname === `${path}/login` ? (
            <div>
              <Button
                variant="secondary"
                onClick={() => history.push(`${path}/register`)}
                sx={{ boxShadow: 'none', fontSize: '14px' }}
              >
                Don&apos;t have an account?
              </Button>
            </div>
          ) : (
            <Button variant="secondary"
              onClick={() => history.push(`${path}/login`)}
              sx={{ boxShadow: 'none', fontSize: '14px' }}
            >
              Already have an account? Sign In here.
            </Button>
          )}
        </Box>
      </StyledNav>

      <FormPanel className="form-panel" theme={theme}>
        <Switch>
          <Route exact path={`${path}/login`}>
            <LoginForm client={client} />
          </Route>
          <Route path={`${path}/register`}>
            <RegisterForm />
          </Route>
          <Route exact path={`${path}/blocked-account`}>
            <BlockedAccount />
          </Route>
          <Route path={`${path}/accept-invite/:inviteToken/:inviteEmail/:invitationID/:firstName/:lastName`}>
            <RegisterLawyerForm />
          </Route>
          <Route path={`${path}/accept-team-invite/:tempPassword/:inviteEmail`}>
            <AcceptTeamInviteForm />
          </Route>
          <Route exact path={`${path}/forgot-password`}>
            <ForgotPassword />
          </Route>
          <Route path={`${path}/forgot-password/:emailID`}>
            <ForgotPassword />
          </Route>
          <Route
            path={`${path}/confirm-email/:emailToken`}
          >
            <ConfirmEmail />
          </Route>
          <Route path={`${path}/reset-password/:token`}>
            <ResetPassword />
          </Route>
        </Switch>
      </FormPanel>
      <footer
        style={{
          position: 'absolute',
          background: selectStyles.headerBG,
          width: '100vw',
          // height: '30px',
          bottom: -5
        }}
      >
        <Footer />
      </footer>
    </div>
  );
}

export default AuthPage;

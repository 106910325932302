import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Box, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { UserContext } from '../context/UserContext';
import UserSettings from '../components/UserSettings/UserSettings';
import DataAndLegal from '../components/DataAndLegal/DataAndLegal';
import SubscriptionSettings from '../components/SubscriptionSettings/SubscriptionSettings';
import JonahDirectDigest from '../components/JonahDirectDigest/JonahDirectDigest';
import Notifications from '../components/Notifications/Notifications';
import { rgba } from "emotion-rgba";
import APIKeys from '../components/APIKeys/APIKeys';

const StyledPageContainer = styled(Box)`
  background: ${({theme}) => rgba(theme.themeColor.sectionFill, 0.6)};
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  backdrop-filter: blur(20px);
  border-radius: 4px;
  margin: 11px;
`

const SettingsContainer = styled(Box)`
  background-color: ${({theme}) => theme.themeColor.sectionFill};
`

export default function AccountSettings() {
  const [screen, setScreen] = useState(0);
  const { user } = useContext(UserContext);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setScreen(newValue);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const tabIndex = parseInt(hash.replace('#tab', ''), 10) - 1;
      if (!isNaN(tabIndex) && tabIndex >= 0) {
        setScreen(tabIndex);
      }
    }
  }, []);

  return (
    <StyledPageContainer>
      <Tabs
        theme={theme}
        value={screen}
        onChange={handleChange}
      >
        <Tab theme={theme} label="Account" />
        <Tab theme={theme} label="Notifications" />
        <Tab sx={{ width: "125px", minWidth: "125px" }} theme={theme} label="Data & Legal" />
        <Tab sx={{ width: "125px", minWidth: "125px" }} theme={theme} label="Activity Digest" />

        {user.userType === 'PORTFOLIO_MANAGER' && <Tab theme={theme} label="API Keys" />}
        {user.userType === 'PORTFOLIO_MANAGER' && <Tab theme={theme} label="Subscription" />}
      </Tabs>
      <SettingsContainer theme={theme}>
        <Box sx={{ p: 3 }}>
          {screen === 0 && <UserSettings user={user}/>}
          {screen === 1 && <Notifications />}
          {screen === 2 && <DataAndLegal />}
          {screen === 3 && <JonahDirectDigest user={user}/>}
          {screen === 4 && <APIKeys />}
          {screen === 5 && <SubscriptionSettings user={user}/>}
        </Box>
      </SettingsContainer>
    </StyledPageContainer>

  )
}

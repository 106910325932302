import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD_MUTATION } from "../apollo/mutations/forgotPasswordMutation";
import { FormControl, RadioGroup, TextField, Stack, Button, Box } from '@mui/material';
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage/SuccessMessage";
import Radio from "../styled/Radio/Radio";
import { FaPhone, FaAt } from "react-icons/fa";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const ContactArea = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: .5em;
`

const ContactIcon = styled(Box)`
  margin-right: 2rem;
  color: ${(props) => props.theme.themeColor.bodyBackground};
`

const StyledContact = styled('a')`
  font-size: 16px;
  border-radius: 0.375rem;
  padding: 0.25rem 1rem;
  text-align: center;
  transition-duration: .33s;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  background-color: ${(props) => props.theme.themeColor.sectionStroke};
  cursor: pointer;
  color: ${(props) => props.theme.themeColor.bodyMain};
  
  &:hover {
    background-color: ${(props) => props.theme.themeColor.brandPrimaryBlue};
    color: white;
    text-decoration: none;
  }
`

export default function ForgotPassword() {
  const history = useHistory();
  const theme = useTheme();
  const { emailID } = useParams();

  const [ForgotPasswordMutation, { data, error, loading }] = useMutation(
    FORGOT_PASSWORD_MUTATION,
    {
      onCompleted() {
        if (error) {
          disableButton(false);
        }
      }
    }
  );

  const [email, setEmail] = useState(emailID || "");
  const [formError, setFormError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("password")
  const [isButtonDisabled, disableButton] = useState(false);

  function showMessageAndRedirect() {
    setTimeout(() => history.push("/auth/login"), 2000);
    return <SuccessMessage message="Reset password link sent." />;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (email.length === 0) {
      setFormError(true);
    } else {
      setFormError(false);
      disableButton(true);
      ForgotPasswordMutation({ variables: { email } });
    }
  }

  return (
    <div className="login">
      <form
        style={{
          width: '350px',
          padding: '1rem',
        }}
        onSubmit={handleSubmit}>
        <h2>Restore Account Access</h2>

        <p className="form-heading">
          What is it you can&apos;t seem to remember?
        </p>

        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <RadioGroup
            defaultValue="password"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <Radio label="My password" value="password" checked={selectedOption === 'password'} />
            {selectedOption === 'password' && (
              <TextField
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                name="identifier"
                error={error || formError}
                required
                fullWidth
              />
            )}
            <Radio label="My login e-mail" value="email" checked={selectedOption === 'email'} />
            {selectedOption === 'email' && (
              <Box>
                <p>If you don&apos;t remember your login e-mail, we kindly ask you to give us a call or send us an e-mail to recover access to you Jonah Direct account:</p>
                <Box>
                  <ContactArea>
                    <ContactIcon><FaPhone color={theme.themeColor.brandPrimaryBlue} /></ContactIcon>
                    <StyledContact theme={theme} href="tel:9493850771">(949) 385-0771</StyledContact>
                  </ContactArea>
                  <ContactArea>
                    <ContactIcon><FaAt color={theme.themeColor.brandPrimaryBlue} /></ContactIcon>
                    <StyledContact theme={theme} href="mailto:info@redhawkresearch.com">info@redhawkresearch.com</StyledContact>
                  </ContactArea>
                </Box>
              </Box>
            )}
          </RadioGroup>

          <Stack
            spacing={2}
          >
            <div className="align-self-center ">
              {error && selectedOption == 'password' && <ErrorMessage error="Please provide valid email." />}
              {formError &&<ErrorMessage error={"Email field can't be blank."} />}
              {data && !error && showMessageAndRedirect()}
            </div>
            <Button
              variant="contained"
              type="submit"
              loading={loading}
              disabled={isButtonDisabled}
              sx={{
                display: selectedOption === 'email' && 'none'
              }}
            >
              Reset Password
            </Button>
          </Stack>

        </FormControl>
      </form>
    </div>
  );
}

import React from 'react';
import { AgGridReact } from "ag-grid-react";
import { Box } from "@mui/material";
import Button from '../../styled/Button/Button';
import { createColumsDef } from "../../helpers";
import { useHistory } from "react-router-dom";
import Grid from "../../styled/Grid/StyledGrid";
import Modal from "../../styled/Modal/Modal";
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Typography from '../../styled/Typography/Typography';
  
const gridOptions = {
  suppressPropertyNamesCheck: true,
};
  
const ButtonBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
  `
const TableHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`


export default function PreviewImportTable({ analysis, setAnalysis, setTarget, setData, setFile, onContinueLink, firstImport }) {
  const history = useHistory();
  const { fieldsHeader, parsedData, fileTypeErrors } = analysis;
  const modifiedHeaders = fieldsHeader.map((item) => ({...item})).filter((field) => field.fieldName !== "hasApi" && field.fieldName !== "bkstatus");
  const columnDefs = createColumsDef(modifiedHeaders, fileTypeErrors);
  const theme = useTheme();
  
  fieldsHeader.forEach((header, i) => {
    if (header.fieldName === 'hasApi')
      fieldsHeader.splice(i, 1)
  })
  
  const removeFile = () => {
    history.push("/");
    setAnalysis(null);
    setTarget(null);
    setData("");
    setFile(null);
  };

  if (!analysis) {
    return <Typography variant="h4" component="h1"
      style={{ margin: "auto" }}>
      The server has encountered an error. Please refresh or try again later.
    </Typography>
  }

  return (
    <Modal open={true} width='80vw'>
      <TableHeader>
        <Typography variant="h4" component="h1" mb={3} mt={1}>Preview</Typography>
        {fileTypeErrors.length > 0 && 
            <Typography variant="h5" component="h5"
              mb={3}
              sx={{ color: theme.themeColor.brandPrimaryRed, fontSize: '1em', }}
            >
              WARNING: Columns with red values must be numbers (not contain other characters) before uploading
            </Typography>
        }
      </TableHeader>
      <Grid style={{height: '55vh', border: `1px solid ${theme.themeColor.sectionStroke}`, borderRadius: theme.themeSizing.borderRadiusLarge}}>
        <AgGridReact
          rowData={parsedData}
          columnDefs={columnDefs}
          pagination={true}
          gridOptions={gridOptions}
          suppressFieldDotNotation={true}
        />
      </Grid>
      <ButtonBox>
        <Button 
          onClick={() => history.push({ pathname: onContinueLink, state: { firstImport }})}
          sx={{width:130, marginRight: '25px'}}
          disabled={fileTypeErrors.length > 0}
        >
            Continue
        </Button>
  
        <Button
          variant="secondary"
          onClick={removeFile}
          sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
        >
            Cancel
        </Button>
      </ButtonBox>
    </Modal>
  );
}

import React, { useState } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Box, Menu, MenuItem } from "@mui/material";

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ theme }) => theme.themeColor.bodyTetriary};
  cursor: pointer;
  width: 20px;
`

const StyledMenuItem = styled(MenuItem)`
  color: ${(props) => props.color ? props.theme.themeColor.brandPrimaryRed : props.theme.themeColor.bodySecondary};
  font-weight: 500
`;

const TableSettings = ({ items }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', height: '100%', width: '100%', justifyContent: 'flex-end'}}>
        <StyledSettingsIcon onClick={handleClick} theme={theme} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          items.map((item, i) => (
            <StyledMenuItem 
              onClick={(e) => {
                e.preventDefault();
                handleClose();
                item.onClick();
              }}
              color={item.color}
              key={i}
            >
              {item.label}
            </StyledMenuItem>
          ))
        }
      </Menu>
    </>
  )
}

export default TableSettings;

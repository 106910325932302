import { Button } from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

const UploadedBy = ({ value }) => {
  const mockDate = new Date().toLocaleDateString();
  return (
    <div>
      <span>{`${mockDate}`}</span>
      <Button variant="contained" startIcon={<PersonIcon />} sx={{width: 150, ml: 5}}>
        {value}
      </Button>
    </div>
  )
}

export default UploadedBy;
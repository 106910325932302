import React, { useState } from "react";
import { Box, FormControl } from "@mui/material";
import Radio from "../../styled/Radio/Radio";
import AddApiForm from "../AddApiForm/AddApiForm";
import Button from "../../styled/Button/Button";
import AnalyzeAssetsImport from "../AnalyzeAssetsImport/AnalyzeAssetsImport";
import Typography from "../../styled/Typography/Typography";

const FirstAccessImportContent = ({ onClose, analysis, analyzeImports, isLoading, setFile, setData, file, removeFile }) => {
  const [importType, setImportType] = useState(0);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const handleSubmit = (e) => {
    if (importType === 0) analyzeImports(e);
  }

  return (
    <>
      <Typography variant="h4" component="h2" mb={3}>Import Assets</Typography>
      <FormControl sx={{ width: '100%' }}>
        <Radio
          label="Import from a file"
          checked={importType === 0}
          onClick={() => setImportType(0)}
        />
        {importType === 0 && (
          <div style={{maxHeight: '5%'}}>
            {!analysis && (
              <AnalyzeAssetsImport
                analyzeImports={analyzeImports}
                isLoading={isLoading}
                setFile={setFile}
                setData={setData}
                file={file}
                otherSubmit={true}
              />
            )}
          </div>
        )}

        <Radio size='small'style={{ margin: "0 !important" }}label="Import via TMO API" checked={importType === 1} onClick={() => setImportType(1)} />
        {importType === 1 && <AddApiForm onCloseImportModal={onClose} loading={isApiLoading} setLoading={setIsApiLoading} />}
      </FormControl>
  
      {
        importType === 0 && (
          <Box sx={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
            <Button
              onClick={handleSubmit}
              sx={{
                mr: 1,
                position: 'relative',
                width: '225px'
              }}
              disabled={!file}
              loading={isLoading}
              data-testid="first-access-import-button"
            >
              Import
            </Button>

            <Button
              variant="secondary"
              sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
              onClick={() => {removeFile(); onClose();}}
            >
              Skip and import later
            </Button>
          </Box>
        )
      }
    </>
  )
}

export default FirstAccessImportContent;
import React from 'react';
import styled from "@emotion/styled";

const StyledTextBox = styled.p`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
  color: ${(props) => (props ? props.theme.themeColor.lighterGrey : "white")};
  background: ${(props) => (props ? props.theme.themeColor.darkGrey : "grey")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  padding: ${({ padding }) => (padding ? padding : "4px 8px")};
  margin-bottom: ${({ marginBottom }) =>
  marginBottom ? marginBottom : "20px"};
`;

export default function TextBox({
  children,
  padding,
  lineHeight,
  fontSize,
  marginBottom,
}) {
  return (
    <StyledTextBox
      padding={padding}
      lineHeight={lineHeight}
      fontSize={fontSize}
      marginBottom={marginBottom}
    >
      {children}
    </StyledTextBox>
  );
}

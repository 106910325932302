import { gql } from "@apollo/client";

export const REFERRALS_BY_TEAM_QUERY = gql`
  query Query($teamID: ID!) {
    teamReferrals(teamID: $teamID) {
      id
      state
      loanNumber
      portfolioID
      referralStatus
      assetID
      deleted
      referredBy {
        id
        firstName
        lastName
      }
      team {
        id
        name
      }
    }
  }
`;
import React from 'react';
import Modal from '../../styled/Modal/Modal';
import InitialsCircle from '../../styled/InitialsCircle/InitialsCircle';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import { Box } from '@mui/material';
import TableDateTime from '../TableDateTime/TableDateTime';
import styled from '@emotion/styled';
import { getInitials, formatDocumentName } from '../../helpers';
import { useLazyQuery } from '@apollo/client';
import { DOCUMENT_DOWNLOAD_LINK } from "../../apollo/queries/downloadLink"
import { useTheme } from '@emotion/react';

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 23%;
`
const FirstLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const PreviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.themeColor.sectionFill};
  border: ${({theme}) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({theme}) => theme.themeSizing.borderRadiusLarge};
  height: 55%;
  width: 100%;
  margin: 30px 0;
  overflow-y: scroll;
`

export default function DocumentPreview({ open, onClose, document }) {
  if (!document) return <p>error</p>;
  const theme = useTheme();

  const [
    getDownloadLink,
    { loading, error }
  ] = useLazyQuery(DOCUMENT_DOWNLOAD_LINK, {
    onCompleted: (result) => {
      const win = window.open(result.downloadDocumentLink.downloadLink, "_blank");
      win.focus();
    }
  });

  if (error) return <p>error: {error.message}</p>

  return (
    <Modal open={open} onClose={onClose} width="55vw" height="60vh">
      <HeaderContainer>
        <FirstLine>
          <Typography variant='h3' component='h1'>{formatDocumentName(document.documentType)}</Typography>
          <div style={{display: 'flex', alignSelf: 'center'}}>
            <TableDateTime value={document.uploadedAt} style={{marginRight: '15px', alignSelf: 'center'}}/>
            <InitialsCircle
              initials={getInitials(document.uploadedBy)}
              fullName={document.uploadedBy}
              variant="gray"
            />
          </div>
        </FirstLine>

        <Typography variant='h6' component='h3'>{document.name}</Typography>
      </HeaderContainer>

      <PreviewContainer theme={theme}>
        <Typography variant="h4" component="h4">
          {
            document.description
              ? `${document.description}`
              : "No preview available."
          }
        </Typography>
      </PreviewContainer>

      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Button
          onClick={onClose}
          sx={{
            mr: 3,
            position: 'relative',
            width: '150px',
            padding: '10px 25px'
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          sx={{fontSize: '14px', width: 'auto', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
          loading={loading}
          onClick={() => {
            getDownloadLink({ variables: { id: document.id }})
          }}
        >
          Download
        </Button>
      </Box>
    </Modal>
  )
}

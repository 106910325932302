import { gql } from '@apollo/client';

export const IMPORT_ITEMS = gql`
  mutation importItems($input: ImportItemsInput!) {
    importItems(input: $input) {
      importConfigID
      status
      response
    }
  }
`;

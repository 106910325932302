import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery, useMutation } from "@apollo/client";
import { Container, Box, CircularProgress } from '@mui/material';
import Radio from '../../styled/Radio/Radio';
import Button from '../../styled/Button/Button'
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {GET_STRIPE_DETAILS} from '../../apollo/queries/getStripeDetails';
import PaymentHistory from './PaymentHistory';
import Billing from './Billing';
import { MessageContext } from '../../context/MessageContext';

const REMOVE_PAYMENT_METHOD = gql`
  mutation removePaymentMethod($paymentMethodID: ID!) {
    removePaymentMethod(paymentMethodID: $paymentMethodID) {
      id
      brand
      last4
      exp_month
      exp_year
    }
  }
`;

const SET_DEFAULT_PAYMENT = gql`
  mutation setDefaultPaymentMethod($paymentMethodID: ID!) {
    setDefaultPaymentMethod(paymentMethodID: $paymentMethodID) {
      id
    }
  }
`;

const StyledBox = styled(Box)`
  width: 50%;
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  border-radius: 12px;
  width: 92vw;
  padding: 40px;
  gap: 16px;
  margin-bottom: 20px;
`
const StyledContainer = styled(Container)`
  width: 100%;
  margin: 0;
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`
const SectionTitle = styled('h3')`
  color: ${({ theme }) => theme.themeColor.bodyMain};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`
const RemoveCard = styled(Button)`
  width: 150px;
  font-size: .75em;
  height: 30px;
`

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function SubscriptionSettings() {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentIntents, setPaymentIntents] = useState([]);

  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const [removePaymentMethod, {loading: loadingRemoveCard}] = useMutation(REMOVE_PAYMENT_METHOD, {
    refetchQueries: [
      { query: GET_STRIPE_DETAILS },
    ]
  });
  const [changePaymentMethod, {loading: loadingChangeMethod}] = useMutation(SET_DEFAULT_PAYMENT);

  const { data, loading: loadingData } = useQuery(GET_STRIPE_DETAILS);

  useEffect(() => {
    if (!data) return;
    setPaymentMethods(data.getStripeDetailsCurUser.paymentMethods);
    setPaymentIntents(data.getStripeDetailsCurUser.paymentIntents);
  }, [data]);

  const deletePaymentMethod = async (paymentMethodID) => {
    if (paymentMethods.length < 2){
      addMessage({type: 'error', message: 'You need to have at least one Credit Card available.'});
      return;
    }
    try {
      await removePaymentMethod({
        variables: { paymentMethodID },
      });
      setPaymentMethods(paymentMethods.filter((pm) => pm.id !== paymentMethodID));
    } catch {
      addMessage({type: 'error', message: 'Something went wrong. Try again later.'});
    }
  }

  useEffect(() => {
    if (!paymentMethods) return;
    setPaymentMethod(paymentMethods.find((pm) => pm.active));
  }, [paymentMethods]);

  const onChangePaymentMethod = async (paymentMethodID) => {
    try {
      await changePaymentMethod({
        variables: { paymentMethodID }
      });
      setPaymentMethod(paymentMethods.find((pm) => pm.id === paymentMethodID));
    } catch {
      addMessage({type: 'error', message: 'Something went wrong. Try again later.'});
    }
  }

  return (
    <StyledContainer theme={theme}>
      <StyledBox theme={theme}>
        <SectionTitle>Payment Method</SectionTitle>
        <Box sx={{width: '25%'}}>
          {loadingData && (
            <CircularProgress />
          )}
          {!loadingData && paymentMethods?.length && paymentMethods.map((pm) => (
            <Box key={pm.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Radio disabled={loadingChangeMethod} label={`${capitalizeFirstLetter(pm.brand)} - •••• •••• •••• ${pm.last4}`} checked={paymentMethod?.id === pm.id} onClick={() => onChangePaymentMethod(pm.id)} />
              <>
                { !(paymentMethod?.id === pm.id) && paymentMethods.length > 1 && (
                  <RemoveCard
                    onClick={() => deletePaymentMethod(pm.id)}
                    disabled={loadingRemoveCard || loadingChangeMethod}
                    loading={loadingRemoveCard}
                    theme={theme}
                  >
                    Remove Card
                  </RemoveCard> 
                )}
              </>
            </Box>
          ))}
        </Box>
        <Button disabled={loadingData || loadingRemoveCard || loadingChangeMethod} sx={{ width: '20ch', marginRight: '25px' }} onClick={() => location.href = '/payment'}>Add a new credit card</Button>
      </StyledBox>

      <StyledBox>
        <SectionTitle>Billing</SectionTitle>
        <Billing />
      </StyledBox>

      <StyledBox>
        <SectionTitle>Payment History</SectionTitle>
        {loadingData ? <CircularProgress /> : <PaymentHistory paymentIntents={paymentIntents} />}
      </StyledBox>
    </StyledContainer>
  )
}
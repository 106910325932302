import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import React from "react";
import { FaAt } from "react-icons/fa";
import Button from "../../styled/Button/Button";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";

const EmailContainer = styled(Box)`
  background-color: ${(props) => props.theme.themeColor.sectionFill};
  color: ${(props) => props.theme.themeColor.bodySecondary};
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  border-radius: ${(props) => props.theme.themeSizing.borderRadiusSmall};

  &:hover {
    background-color: ${(props) => props.theme.themeColor.brandPrimaryBlue};
    color: ${(props) => props.theme.themeColor.backgroundBody};
  }
`

const DeleteUserModal = ({show, onClose, onDelete, username, email}) => {
  const theme = useTheme();
  
  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" component="h3" mb={3}>Delete {username}</Typography>
        <EmailContainer theme={theme}>
          <FaAt style={{ marginRight: "10px" }} />
          {email}
        </EmailContainer>

        <p>Are you sure you want to delete this team member?</p>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button sx={{ width: '150px' }} onClick={onDelete}>Delete</Button>
        <Button sx={{ fontSize: '12px', width: '53px', ml: 3 }} variant="secondary" onClick={onClose}>Cancel</Button>
      </Box>
    </Modal>
  )
}

export default DeleteUserModal;
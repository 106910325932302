import React, { Fragment } from "react";
import { Route, Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TimeoutModal from "../TimeoutModal/timeoutModal";

export const PrivateRoute = ({ children, user, permissions = [], ...rest }) => {
  const location = useLocation();
  const { pathname } = location;
  if (!user) return <Redirect to={`/auth/login?next=${pathname}`} />;

  
  function hasPermission(userType, permissions) {
    if (permissions.includes(userType)) return true;
    return false;
  }

  const hasAccess = hasPermission(user.userType, permissions);

  if (!hasAccess) return <p>no access</p>

  return (
    <Fragment>
      <Route
        {...rest}
        render={() => children}
      />
      <TimeoutModal />
    </Fragment>
  );
};

import { useState, useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_CONTENT_TYPES = gql`
  query Query($table: String) {
    getContentTypes(table: $table) {
      column_name
      data_type
      example
    }
  }
`;

export default function useContentTypes() {
  const [contentTypes, setContentTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { value: user } = useLocalStorage("user");

  const [getContentTypes] = useLazyQuery(GET_CONTENT_TYPES);

  useEffect(() => {
    async function getContentTypeOptions() {
      try {
        const response = await getContentTypes();

        const contentTypes = {
          apiID: "asset",
          schema: response.data.getContentTypes
        }

        setContentTypes(contentTypes);
      } catch (err) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    getContentTypeOptions();
  }, [user, error]); 

  return {
    contentTypes,
    loading,
    error,
  }
}



import React, { useState, useContext } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { CLOSE_MILESTONE_MUTATION } from "../../apollo/mutations/closeMilestoneMutation";
import { FORECLOSURE_COMPLETE_MUTATION } from '../../apollo/mutations/foreclosureCompleteMutation';
import { Avatar, Box, CircularProgress, IconButton, MenuItem, Select } from "@mui/material";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import { MessageContext } from '../../context/MessageContext';
import { UserContext } from "../../context/UserContext";
import { useTheme } from "@emotion/react";
import { rgba } from "emotion-rgba";
import { checkAssetNextMilestoneAction } from "../../utilities/milestoneActions";
import { CREATE_FORM_MUTATION } from "../../apollo/mutations/createFormMutation";
import { CREATE_TASK_MUTATION } from "../../apollo/mutations/createTaskMutation";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { GET_PORTFOLIO_MANAGER } from "../../apollo/queries/getPortfolioManager";
import { GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE, GET_TASK_FLOW_TEMPLATES_QUERY } from "../../apollo/queries/getTaskFlowTemplatesQuery";
import { CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION } from "../../apollo/mutations/createTaskFlowAndSubtasksMutation";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import { CLOSE_BK_PROCESS } from "../../apollo/mutations/closeBKProcess";
import { CREATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/createTaskFlowTemplateMutation";
import TaskForm from "../TaskForm/TaskForm";
import { isForeclosure, isBankruptcy } from "../../utilities";

export default function CloseMilestone({
  asset,
  milestoneID,
  milestones,
  activeStep,
  process,
  hasBlockingTask,
  setCurrMilestone,
  hasOpenHolds,
  assetState,
  currentMilestone,
  setScreen,
  originalFC,
  activeProcess,
  setActiveProcess,
  setAsset
}) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const { user, portfolioID } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [fcClosed, setFcClosed] = useState(false);
  const nextMilestone = getNextMilestone(milestones, currentMilestone);
  const [modalMessage, setModalMessage] = useState("Are you sure you want to close this milestone?")
  const [formToConfirm, setFormToConfirm] = useState(null);
  const [formIsConfirmed, setFormIsConfirmed] = useState(true);
  const [saleScheduledDate, setSaleScheduledDate] = useState(null);
  const [bkCompleteStatus, setBkCompleteStatus] = useState(null);
  const { id: processID } = process;
  const { id: assetID } = asset;
  const [createTask] = useMutation(CREATE_TASK_MUTATION);
  const [createForm] = useMutation(CREATE_FORM_MUTATION, {
    refetchQueries: [
      { query: TASKS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
  });
  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: TASKS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } },
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
    ],
  });
  const [createFlow] = useMutation(CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION, {
    refetchQueries: [
      { query: TASK_FLOWS_QUERY, variables: { processID } }
    ]
  });
  const [closeBK] = useMutation(CLOSE_BK_PROCESS, {
    refetchQueries: [
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID }},
    ]
  });
  const [createFlowTemplate] = useMutation(CREATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
      { query: EVENTS_QUERY, variables: { milestoneID: nextMilestone.stepID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } },
      { query: GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE,
        variables: {
          portfolioID,
          createOnMilestone: false,
          stepID: nextMilestone.stepID,
          state: assetState
        }
      }
    ]
  })

  const [getFlowTemplates] = useLazyQuery(GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE, {
    variables: { portfolioID, createOnMilestone: true, stepID: nextMilestone.stepID, state: assetState }
  });

  const [taskFlowTemplates] = useLazyQuery(GET_TASK_FLOW_TEMPLATES_QUERY, {
    variables: { portfolioID }
  })

  const [getTasks] = useLazyQuery(TASKS_QUERY, {
    variables: { processID }
  });

  const { data: dataPortfolioManager, loading: loadingPm } = useQuery(GET_PORTFOLIO_MANAGER, {
    variables: { 
      portfolioID 
    },
  });

  const [closeMilestone] = useMutation(CLOSE_MILESTONE_MUTATION, {
    refetchQueries: [
      {
        query: EVENTS_QUERY,
        variables: { milestoneID: milestoneID, processID },
      },
      {
        query: EVENTS_QUERY,
        variables: { milestoneID: nextMilestone.stepID || '6.0', processID },
      },
      {
        query: ASSET_QUERY,
        variables: {
          assetID,
          portfolioID,
          userID: user.userID,
        },
      },
    ],
  });

  const completeFCRefetch = [
    {
      query: EVENTS_QUERY,
      variables: { milestoneID: milestoneID, processID },
    },
    {
      query: EVENTS_QUERY,
      variables: { milestoneID: nextMilestone.stepID || '6.0', processID },
    },
    {
      query: ASSET_QUERY,
      variables: {
        assetID,
        portfolioID,
        userID: user.userID,
      },
    },
  ];
  if (originalFC) {
    completeFCRefetch.push({
      query: EVENTS_QUERY,
      variables: { milestoneID: currentMilestone?.fc?.stepID, processID: originalFC.id }
    })
  }

  const [completeForeclosure] = useMutation(FORECLOSURE_COMPLETE_MUTATION, 
    {
      refetchQueries: completeFCRefetch,
    }
  );

  const checkConfirmationForm = async () => {
    if (milestoneID === "4.00") {
      const resp = await getTasks();
      const tasks = resp.data.tasks;
      
      const allForms = tasks.map(task => task.forms).flat();
      const form = allForms.find((form) => form.title === 'Sale Schedule')

      setFormIsConfirmed(false);
      setFormToConfirm(form);
    }
  }

  function getNextMilestone(milestones) {
    let nextStepIndex;
    milestones.forEach((milestone, index) => {
      if (milestone.stepID === milestoneID) {
        nextStepIndex = index + 1;
      }
    });

    return milestones[nextStepIndex] !== undefined
      ? milestones[nextStepIndex]
      : "complete";
  }

  function fcClosureModal() {
    const message = isForeclosure(process)
      ? "Foreclosure Completed!"
      : "Bankruptcy Completed!"
    setModalMessage(message);
    setOpenConfirmation(true);
    setFcClosed(true);
  }

  async function handleSubmit() {
    if (hasBlockingTask) {
      setLoading(false);
      addMessage({ message: "Cannot close current milestone with a blocking task.", type: "error" })
      return;
    }
    if (hasOpenHolds) {
      setLoading(false);
      addMessage({ message: "Cannot close current milestone with a open hold.", type: "error"});
      return;
    }
    setLoading(true);
    setOpenConfirmation(false);

    const closeMilestoneEvent = {
      type: "milestone-closed",
      createdDate: new Date(),
      milestoneID,
      process: processID,
    };

    const openMilestoneEvent = {
      type: "milestone-opened",
      createdDate: new Date(),
      milestoneID: nextMilestone.stepID,
      description: `${nextMilestone.stepID} ${nextMilestone.label}`,
      process: processID,
    };

    if (nextMilestone.stepID !== "6.0") {
      try {
        const asset = {
          statusValue: nextMilestone.stepID,
        };

        if (saleScheduledDate) asset.saleScheduledDate = saleScheduledDate;
        
        await closeMilestone({
          variables: {
            asset: { where: { id: assetID }, data: asset },
            closeMilestoneEvent,
            openMilestoneEvent,
          },
        });

        await checkAssetNextMilestoneAction(
          asset,
          process,
          portfolioID,
          nextMilestone,
          createTask,
          createForm,
          createEvent,
          dataPortfolioManager,
          getFlowTemplates,
          taskFlowTemplates,
          createFlow,
          createFlowTemplate,
        );

        const copy = { ...currentMilestone };
        if (isForeclosure(process)) {
          copy.fc = nextMilestone;
        } else {
          copy.bk = nextMilestone;
        }
        setCurrMilestone(copy);
        setAsset((p) => ({ ...p, statusValue: nextMilestone.stepID }));
        setActiveProcess((p) => ({ ...p, stepID: nextMilestone.stepID }));
      } catch (error) {
        console.log(error)
        addMessage({ message: "Unable to close current milestone. Please try again or come back later.", type: "error" })
      }
    } else {
      try {
        const billingStatus = isForeclosure(process)
          ? "ENDED_FORECLOSURE"
          : `BANKRUPTCY ${bkCompleteStatus.toUpperCase()}`;
        await completeForeclosure({
          variables: {
            asset: {
              where: { id: assetID }, data: {
                statusValue: "6.0",
                billingStatus
              }
            },
            process: {
              where: { id: process.id }, data: {
                status: billingStatus,
                stepID: "6.0"
              }
            },
            closeMilestoneEvent: {
              type: "foreclosure-closed",
              createdDate: new Date(),
              process: processID,
              milestoneID,
            },
          },
        })
        const copy = { ...currentMilestone };
        if (isForeclosure(process)) {
          copy.fc = nextMilestone;
        } else {
          copy.bk = nextMilestone;
        }
        setCurrMilestone(copy);
        billingStatus === "ENDED_FORECLOSURE"
          ? (
            setAsset((p) => ({ ...p, statusValue: "6.0" })),
            setActiveProcess((p) => ({ ...p, status: billingStatus, stepID: "6.0" }))
          )
          : (
            setActiveProcess(asset.processes.find((p) => p.stepID === copy.fc.stepID && !p.deleted)),
            setAsset((p) => ({ ...p, statusValue: copy.fc.stepID, activeProcess: asset.processes.find((p) => p.stepID === copy.fc.stepID && !p.deleted) }))
          )
        fcClosureModal();
      } catch (error) {
        console.log(error);
        addMessage({ message: "Unable to complete current foreclosure. Please try again or come back later.", type: "error" })
      }
    }
    setLoading(false);
  }

  const onSaveForm = (formValues) => {
    if (milestoneID === "4.00") {
      // Should update asset to save form date in database
      const input = formValues.find((value) => value.label === "Sale scheduled date");
      if (input) setSaleScheduledDate(input.value);
    }
  }

  const onBKClose = async () => {
    handleSubmit();
    setOpenConfirmation(false);
    let originalFC;
    if (isBankruptcy(activeProcess)) {
      asset.processes.forEach((process) => {
        if (!process.deleted && isForeclosure(process)) {
          const hasBkHold = process.holds.some((hold) => 
            hold.holdType === "BK" ? true : false
          );
          if (hasBkHold) originalFC = process;
        }
      })
    }
    if (originalFC)
      try {
        const bkResp = await closeBK({
          variables: {
            assetID: asset.id,
            processID: process.id,
            closeDate: new Date().toISOString(),
            bkStatus: bkCompleteStatus,
            destinationProcess: {
              id: originalFC?.id || null,
              stepID: originalFC?.stepID || null,
              hasFC: originalFC ? true : false
            }
          }
        });

        const originalHold = originalFC && originalFC.holds.filter((hold) => {
          return hold.holdType === "BK" && hold.active && !hold.closeDate
        })
        await createEvent({
          variables: {
            event: {
              type: "hold-closed",
              description: `BK Hold has been manually closed`,
              createdDate: new Date(),
              process: originalFC.id,
              milestoneID: originalFC.stepID,
              hold: originalHold[0].id,
              holdType: `Bankruptcy ${bkCompleteStatus}`
            }
          }
        });

        if (bkResp?.data) {
          addMessage({ message: "Successfully finished the Bankruptcy Process!" });
          setScreen(asset.activities.length+1 || 1);
        } else {
          addMessage({ message: "Unable to finish the Bankruptcy. Please try again.", type: "error" })
        }
      } catch(error) {
        addMessage({ message: "Unable to complete process. Please try again.", type: "error" })
      }
  }

  const setBkStatus = (event) => {
    const { value } = event.target;
    setBkCompleteStatus(value);
  }

  if (loadingPm) {
    return <CircularProgress />
  }

  const onClickOpenModal = () => {
    setOpenConfirmation(true);
    checkConfirmationForm();
  }
  const showCloseMilestone = 
    (!fcClosed && process.stepID !== "5.10" && isBankruptcy(process)) ||
    (!fcClosed && isForeclosure(process));


  return (
    <Box>
      {(activeStep !== "6.0" && milestoneID === activeStep) && (
        <IconButton onClick={onClickOpenModal} disabled={loading} title="Close Milestone">
          <Avatar sx={{
            bgcolor: rgba(theme.themeColor.brandPrimaryRed, 0.1),
            color: theme.themeColor.brandPrimaryRed,
            width: 20,
            height: 20,
            fontSize: "12px",
            fontWeight: "500"
          }}>
            {loading ? (
              <CircularProgress/>
            ) : (
              "X"
            )}
          </Avatar>
        </IconButton>
      )}

      <Modal
        open={openConfirmation}
        // onClose={() => setOpenConfirmation(false)}
        height={formToConfirm ? "50vh" : "30vh"}
        width={formToConfirm ? "60vh" : "50vh"}
      >
        <Typography align="center" component="h1" variant="h4">{modalMessage}</Typography>
        {fcClosed}
        { !fcClosed && formToConfirm && (
          <Box>
            <Typography sx={{mt: 1}} align="center">You need to confirm this form before closing this milestone:</Typography>
            <TaskForm assetID={assetID} form={formToConfirm} processID={processID} setFormIsConfirmed={setFormIsConfirmed} onSaveForm={onSaveForm} milestoneID={milestoneID} />
          </Box>
        )}
        { showCloseMilestone && 
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '15%'}}>
              <Button
                onClick={handleSubmit}
                disabled={!formIsConfirmed && isForeclosure(process)}
                sx={{
                  mr: 1,
                  position: 'relative',
                  width: '200px'
                }}
              >Yes, close</Button>
              <Button
                variant="secondary"
                sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: '150px'}}
                onClick={() => {setOpenConfirmation(false);}}
              >Cancel</Button>
            </Box>
        }
        { !fcClosed && isBankruptcy(process) && process.stepID === "5.10" && 
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
            <label style={{textAlign: 'center'}}>Select Bankruptcy Status:</label>
            <Select
              id="select-bk-status-dropdown"
              sx={{ ml: 4, minWidth: "150px" }}
              value={bkCompleteStatus}
              onChange={setBkStatus}
            >
              <MenuItem value={"Dismissed"}>Dismissed</MenuItem>
              <MenuItem value={"Discharged"}>Discharged</MenuItem>
              <MenuItem value={"Relief"}>Relief</MenuItem>
            </Select>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '5%'}}>
            <Button
              onClick={onBKClose}
              disabled={!bkCompleteStatus}
              sx={{
                mr: 1,
                position: 'relative',
                width: '200px'
              }}
            >Yes, close</Button>
            <Button
              variant="secondary"
              sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: '150px'}}
              onClick={() => {setOpenConfirmation(false);}}
            >Cancel</Button>
          </Box>
        </>
        }
        {fcClosed && 
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '10%'}}>
            <Button
              disabled={isBankruptcy(process) && !bkCompleteStatus}
              onClick={() => { setOpenConfirmation(false); }}
              sx={{ mr: 1, position: 'relative', mt: 2 }}
            >Continue</Button>
          </Box>
        }
      </Modal>
    </Box>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_MISSING_ASSETS } from "../../apollo/mutations/missingAssets";
import Modal from "../../styled/Modal/Modal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import { MessageContext } from "../../context/MessageContext";
import { AssetImportContext } from "../../context/AssetImportContext";
import { useTheme } from "@emotion/react";
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import Typography from "../../styled/Typography/Typography";
import Tooltip from "@mui/material/Tooltip";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export default function MissingAssets({ data, setConflictResolution, setManualResolution, onContinueLink, removeFile, portfolioID }) {
  const history = useHistory();
  const { addMessage } = useContext(MessageContext);
  const { analysis, mappedFields } = useContext(AssetImportContext)
  const [selectedConflictResolutionOption, setSelectedConflictResolutionOption] = useState("keep_old")
  const [showModal, setShowModal] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [inDB, setInDB] = useState([]);
  const [inFile, setInFile] = useState([])

  // contains the asset location of each asset
  // e.g. whether it's in file, in DB, or both
  const [missingAssets, setMissingAssets] = useState([]);
  const [getMissingAssets] = useMutation(GET_MISSING_ASSETS);
  const theme = useTheme();

  const onModalClose = () => {
    setShowModal(false); 
    setInDB([]);
    setInFile([]);
    setMissingAssets([]);
    removeFile();
  }

  const updateMissingAssetItem = (item) => {
    const newMissingAssets = missingAssets.filter(ma => ma.loanNumber !== item.loanNumber);
    setMissingAssets([...newMissingAssets, item]);
    setManualResolution([...newMissingAssets, item]);
  }

  const onAssetDelete = (item) => {
    item.keep = false;
    updateMissingAssetItem(item);
  }

  const onAssetKeep = (item) => {
    item.keep = true;
    updateMissingAssetItem(item);
  }

  const onAssetUndo = (item) => {
    item.keep = null;
    updateMissingAssetItem(item);
  }

  const onContinue = () => {    
    if (selectedConflictResolutionOption === 'overwrite') {
      setOpenConfirmation(true);
      const db = missingAssets.filter((asset) => asset.dbState === 'In DB, but not in file')
      const file = missingAssets.filter((asset) => asset.dbState === 'In file, but not in DB')
      
      if (db.length === 0 && file.length === 0) {
        setInDB(missingAssets);
        setInFile(missingAssets);
      } else {
        setInDB(db);
        setInFile(file);
      }
    }
    else history.push(onContinueLink);
  }

  useEffect(() => {
    getAssetList();
  }, [])

  const getAssetList = async () => {
    const dataToSend = {
      source: "upload",
      options: {
        mappedFields
      },
      data,
      type: "text/csv",
      portfolioID,
    };

    try {
      const response = await getMissingAssets({
        variables: { input: dataToSend }
      })
      const { missingAssets } = response.data

      // If original file doesn't have "loanNumbers", it shows up as undefined
      const hasUndefinedLoans = missingAssets.some((loan) => loan.loanNumber == "undefined");
      
      if (hasUndefinedLoans) {
        // Replaces the undefined values with the ones from the field they mapped from
        const fieldToLoanNum = Object.entries(mappedFields).filter(([key, value]) => value.targetField === "loanNumber" && key)[0][0];
        const { parsedData } = analysis;
        const mappingLoanNums = parsedData.map((asset) => asset[fieldToLoanNum]);
        missingAssets.forEach((asset) => {
          if (asset.loanNumber == "undefined") {
            asset.loanNumber = mappingLoanNums.shift().toString();
          }
        })        
      }
      
      const reformattedArray = missingAssets.map(({ loanNumber, dbState }, i) => ({ id: i + 1, dbState, loanNumber, keep: null }));

      setMissingAssets(reformattedArray);
    } catch (error) {
      addMessage({ message: "The server has encountered an error while trying to preview the contents of this file. Please try again.", type: "error" })
    }
  }

  const onConflictResolutionOptionChange = (value) => {
    setSelectedConflictResolutionOption(value);
    setConflictResolution(value);
  }

  return (
    <Modal open={showModal} width='50vw' height='100vh' sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="h4" component="h2">Resolve Asset Conflicts</Typography>
      <p>Please decide how to deal with asset conflicts.</p>

      <FormControl>
        <RadioGroup
          value={selectedConflictResolutionOption}
          onChange={(e) => onConflictResolutionOptionChange(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="keep_old"
          name="radio-buttons-group"
        >
          <FormControlLabel value="keep_old" control={<Radio />} label="Keep Old (Ignore new, no changes to existing data)" />
          <FormControlLabel value="update" control={<Radio />} label="Update (Add new, update existing, keep rest in db)" />
          <FormControlLabel value="insert" control={<Radio />} label="Insert (Add new, don't update existing, keep rest in db)" />
          <FormControlLabel value="overwrite" control={<Radio />} label="Overwrite (Add all in file, delete rest in db)" />
          <FormControlLabel value="rebuild" control={<Radio />} label="Rebuild (Ignore new, update existing, and delete rest in db)" />
          <FormControlLabel value="resolve" control={<Radio />} label="Resolve manually (Choose for new, existing, and in db)" />
        </RadioGroup>
      </FormControl>

      <Box>
        <h3>Assets Review</h3>
        {missingAssets.length == 0 ? <p>No assets</p> :
          <TableContainer sx={{ maxHeight: 250 }} theme={theme}>
            <Table stickyHeader sx={{zIndex: 2}}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{backgroundColor: 'black', color: 'white'}}>ID</TableCell>
                  <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Loan #</TableCell>
                  <TableCell sx={{backgroundColor: 'black', color: 'white'}}>State</TableCell>
                  {
                    selectedConflictResolutionOption == "resolve" && 
                    <TableCell sx={{backgroundColor: 'black', color: 'white', zIndex: '2'}}>Action</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {missingAssets.sort((a, b) => a.id - b.id).map((ma) => (
                  <TableRow
                    key={ma.loanNumber}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{ma.id}</TableCell>
                    <TableCell>
                      {ma.keep != false ? ma.loanNumber : <s>{ma.loanNumber}</s> }
                    </TableCell>
                    <TableCell>{ma.dbState}</TableCell>
                    {selectedConflictResolutionOption == "resolve" && 
                    <TableCell>
                      {ma.keep == null ? 
                        <Box sx={{display: 'flex'}}>
                          <Fab size="small" onClick={() => onAssetKeep(ma)} color="success" sx={{mr:1, zIndex: 1}}>
                            <CheckIcon />
                          </Fab>
                          <Fab size="small" onClick={() => onAssetDelete(ma)} color="error" sx={{zIndex: 1}}>
                            <ClearIcon />
                          </Fab> 
                        </Box>
                        : 
                        <Box sx={{display: 'flex'}}>
                          {ma.keep == true ? "Keep" : "Delete" } 
                          <Fab size="small" onClick={() => onAssetUndo(ma)} color="primary" sx={{ml:1, zIndex: 1}}>
                            <UndoIcon />
                          </Fab> 
                        </Box>
                      }
                    </TableCell> 
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box> 

      <Box sx={{display: 'flex', mt: 5}}>
        <Button 
          onClick={onContinue}
          variant="contained" 
          disabled={selectedConflictResolutionOption == "resolve" && missingAssets.some(ma => ma.keep == null)}
        >
          Continue
        </Button>
        <Button onClick={onModalClose}>
          Cancel
        </Button>
      </Box>
      {(selectedConflictResolutionOption == "resolve" && missingAssets.some(ma => ma.keep == null)) &&
        <p>You haven&apos;t updated all of your missing assets.</p>}

      <Modal open={openConfirmation} width="33vw">
        <Typography component="h1" variant="h3">
          Are you sure?
        </Typography>

        <Typography component="p" variant="p" mt={3}>
          <Tooltip title={inDB.map((asset) => asset.loanNumber).join(', ')} placement="top">
            <b>{inDB.length} assets</b>
          </Tooltip>
          {" "}will be deleted from the database and
        </Typography>
        
        <Typography component="p" variant="p" mt={3}>
          <Tooltip title={[...new Set(inFile.map((asset) => asset.loanNumber))].join(', ')} placement="top">
            <b>{[...new Set(inFile.map((asset) => asset.loanNumber))].length} assets</b>
          </Tooltip>
          {" "}will be added to the database
        </Typography>

        <Typography>{missingAssets.zIndex}</Typography>
        <Box sx={{display: 'flex', mt: 5}}>
          <Button
            variant="contained" 
            onClick={() => {
              setOpenConfirmation(false);
              history.push(onContinueLink);
            }}>
          Continue
          </Button>
          <Button onClick={onModalClose}>
          Cancel
          </Button>
        </Box>
      </Modal>
    </Modal>
  )
}
import * as steps from "./foreclosureSteps.json";

export const setActiveMilestoneStatus = (milestones, activeStep) => {
  if (!milestones || !activeStep) return;
  if (activeStep === "complete") {
    return milestones.map((milestone) => ({
      ...milestone,
      status: "completed",
    }));
  } else {
    let selectedIndex;

    milestones.forEach((milestone, index) => {
      if (milestone.stepID === activeStep) {
        selectedIndex = index;
      }
    });

    return milestones.map((milestone, index) => {
      const milestoneStep = { ...milestone };

      if (index === selectedIndex) {
        milestoneStep.status = "active";
      } else if (index < selectedIndex) {
        milestoneStep.status = "completed";
      } else {
        milestoneStep.status = "pending";
      }

      return milestoneStep;
    });
  }
};

export const getMilestones = (state, statusValue = "0.10") => {
  const stepsByState = [
    ...steps.transformedRow.filter((step) => {
      return step.state === state && step.borough === null;
    }),
  ][0].steps;

  return setActiveMilestoneStatus(stepsByState, statusValue);
};

import React, { useContext, useState } from "react";
import { fullName } from "../../../helpers";
import { parseCurrency } from "../../../utilities";
import { formatDate } from "../../../helpers";
import "./details-sidebar.scss";
import { formatBooleanToString, currencyToNumber } from "../../../helpers"
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Typography from "../../../styled/Typography/Typography";
import SidebarAssignAsset from "./SidebarAssignAsset";
import { UserContext } from "../../../context/UserContext";
import Button from "../../../styled/Button/Button";
import ReferAssetToLawFirm from "../../Modals/ReferAssetToLawFirm";
import { isForeclosure } from "../../../utilities";
import ColoredDot from "../../ColoredDot/ColoredDot";

const Container = styled(Box)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
   &:after {
    position: abolute;
    content: "";
    background-image: linear-gradient(to bottom, transparent, black);
  }
`

const DetailsContainer = styled(Box)`
  border: ${({ theme, useBg }) => useBg ? `1px solid ${theme.themeColor.sectionStroke}` : 'none'};
  padding: 10px;
  border-radius: 8px;
  background: ${({ theme, useBg }) => useBg ? theme.themeColor.sectionFill : ''};
  color: ${({ theme }) => theme.themeColor.bodySecondary};
  line-height: 18px;
`
const ScrollableContent = styled(Box)`
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  };
`

const H3 = styled(Typography)`
  font-size: 14px;
  margin: 0;
  padding-top: 15px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  margin: 0 0 20px 10px;
`
const DD = styled(Typography)`
  font-size: 14px;
  font-family: ${(props) => props.number ? 'IBM Plex Mono' : 'IBM Plex Sans'}
  font-family: 'IBM Plex Mono';
  color: ${({ theme }) => theme.themeColor.bodyMain};
  line-height: 1.5rem;
`

const getProcessType = (asset) => {
  if (!asset?.activeProcess || asset?.activeProcess?.deleted)
    return false;
  else if (isForeclosure(asset.activeProcess))
    return "FC";
  else return "BK"
};

const DetailsSidebar = ({ asset, sideBarStats, nextStepStats, client, readOnly }) => {
  const [openLawfirmReferral, setOpenLawfirmReferral] = useState(false);
  const processType = getProcessType(asset)

  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { 
    totalDaysInFc, 
    totalDaysInBk,
    totalHoldsDuration,
    cumulativeBudget,
    budgetDaysToSale
  } = sideBarStats
  
  const { 
    stepGrossFcDays, 
    stepDaysOnHold, 
    netDuration,
    netVsBudget,
    grossVsBudget,
    stepBudgetDays,
    daysInDlq,
  } = nextStepStats;

  return (
    <Container 
      spacing={4}
      className='asset-detail-sidebar'
      theme={theme}
    >
      <DetailsContainer theme={theme}>
        <dl>
          <dt>Loan #</dt>
          <DD theme={theme} number component="dd"> {asset.loanNumber}</DD>
          <dt>UPB</dt>
          <DD theme={theme} component="dd">{parseCurrency(asset.upb, 2)}</DD>
          <dt>Value</dt>
          <DD theme={theme} component="dd">
            {asset.currentValue ? parseCurrency(currencyToNumber(asset.currentValue.toString())) : "--"}
          </DD>
          <dt>Watched</dt>
          <DD theme={theme} component="dd" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {user?.watchlist?.includes(asset.id)
              ? <ColoredDot color="green" />
              : <ColoredDot />
            }
          </DD>
        </dl>
      </DetailsContainer>
      {user.userType === "PORTFOLIO_MANAGER" && 
        <DetailsContainer theme={theme}>
          <dt style={{marginBottom: "10px"}}>Assigned Teams:</dt>
          <SidebarAssignAsset asset={asset} client={client} readOnly={readOnly}/>
        </DetailsContainer>
      }
      {(user.userType === "PORTFOLIO_MANAGER" && (asset.referral && !asset?.activeProcess?.deleted)) && !readOnly &&
        <DetailsContainer theme={theme}>
          <Button onClick={() => setOpenLawfirmReferral(true)}>Re-Refer Asset to Law Firm</Button>
          <ReferAssetToLawFirm assets={[asset]} open={openLawfirmReferral} onClose={() => setOpenLawfirmReferral(false)} />
        </DetailsContainer>
      }

      <H3>Borrower Details</H3>
      <DetailsContainer useBg>
        <dl>
          <dt>Borrower Name</dt>
          <DD theme={theme} component="dd">{fullName(asset.borrowerFirstName, asset.borrowerLastName)}</DD>
          <dt>Property Address</dt>
          <DD theme={theme} component="dd">{asset.assetAddress}</DD>
          <dt>City</dt>
          <DD theme={theme} component="dd">{asset.city}</DD>
          <dt>State</dt>
          <DD theme={theme} component="dd">{asset.state && asset.state.toUpperCase()}</DD>
          <dt>Zip</dt>
          <DD theme={theme} component="dd">{asset.postal}</DD>
          <dt>County</dt>
          <DD theme={theme} component="dd">{asset.county && asset.county.toUpperCase()}</DD>
        </dl>
      </DetailsContainer>
      

      <ScrollableContent>
        {processType && (
          <>
            <H3>Days in {processType}</H3>
            <DetailsContainer theme={theme} useBg>
              <dl>
                <dt>Total Gross Days in {processType}</dt>
                <DD theme={theme} number component="dd">{isForeclosure(asset.activeProcess) ? totalDaysInFc : totalDaysInBk}</DD>
                <dt>Total Hold Days</dt>
                <DD theme={theme} number component="dd">{totalHoldsDuration}</DD>
                <dt>Total Net Days in {processType}</dt>
                <DD theme={theme} number component="dd">{totalDaysInFc - totalHoldsDuration}</DD>
                <dt>Total {processType} Day Budget</dt>
                <DD theme={theme} number component="dd">{cumulativeBudget}</DD>
                <dt>Total Net Days In/Out.</dt>
                <DD theme={theme} number component="dd">{totalDaysInFc - cumulativeBudget}</DD>
              </dl>
            </DetailsContainer>
          </>
        )}
        
        <H3>Next Step Statistics</H3>
        <DetailsContainer useBg>
          <dl>
            {asset.saleScheduledDate && (
              <>
                <dt>Sale Scheduled Date</dt>
                <DD theme={theme} number component="dd">{new Date(`${asset.saleScheduledDate}T00:00:00`).toLocaleString(undefined, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour12: false,
                })}</DD>
              </>
            )}
            <dt>Gross Days</dt>
            <DD theme={theme} number component="dd">{stepGrossFcDays}</DD>
            <dt>Hold Days</dt>
            <DD theme={theme} number component="dd">{stepDaysOnHold}</DD>
            <dt>Net Days</dt>
            <DD theme={theme} number component="dd">{netDuration}</DD>
            <dt>Budget Days</dt>
            <DD theme={theme} number component="dd">{stepBudgetDays ? stepBudgetDays : "N/A"}</DD>
            <dt>Net Days In/Out</dt>
            <DD theme={theme} number component="dd">{isNaN(netVsBudget) ? "N/A" : netVsBudget}</DD>
            <dt>Gross Days In/Out</dt>
            <DD theme={theme} number component="dd">{isNaN(grossVsBudget) ? "N/A" : grossVsBudget}</DD>
          </dl>
        </DetailsContainer>

        <DetailsContainer>
          <dl>
            <dt>Loss Mit Activity</dt>
            <DD theme={theme} component="dd">{formatBooleanToString(asset.lossMitActivity)}</DD>
          </dl>
        </DetailsContainer>
        
        <H3>Loan Details</H3>
        <DetailsContainer useBg>
          <dl>
            <dt>Days Delinquent</dt>
            <DD theme={theme} number component="dd">{isNaN(daysInDlq) ? "N/A" : daysInDlq}</DD>
            <dt>Loan Status</dt>
            <DD theme={theme} component="dd">
              {asset?.activeProcess?.status?.length > 0
                ? asset.activeProcess.status
                : asset.billingStatus
              }
            </DD>
            <dt>BK Status</dt>
            <DD theme={theme} component="dd">{formatBooleanToString(asset.inBankruptcy)}</DD>
            <dt>FC Start Date</dt>
            <DD theme={theme} component="dd">{asset?.activeProcess ? formatDate(asset.activeProcess.startDate) : "N/A"}</DD>
            <dt>Days in FC</dt>
            <DD theme={theme} number component="dd">{totalDaysInFc}</DD>
            <dt>Budget Days to Sale: (incl. med. if appl.)</dt>
            <DD theme={theme} number component="dd">{budgetDaysToSale}</DD>
            <dt>NOD Date</dt>
            <DD theme={theme} component="dd">{asset.nodDate ? formatDate(asset.nodDate) : "N/A"}</DD>
            <dt>Paid Through Date</dt>
            <DD theme={theme} component="dd">{ asset.paidThroughDate ? formatDate(asset.paidThroughDate) : "N/A"}</DD>
            <dt>Lien Position</dt>
            <DD theme={theme} number component="dd">{asset.lienPosition}</DD>
            <dt>UPB</dt>
            <DD theme={theme} number component="dd">{parseCurrency(asset.upb, 2)}</DD>
            <dt>Interest Rate</dt>
            <DD theme={theme} number component="dd">{asset.interestRate}</DD>
            <dt>Servicer</dt>
            <DD theme={theme} component="dd">{asset.servicer}</DD>
          </dl>
        </DetailsContainer>

        <H3>Valuation</H3>
        <DetailsContainer useBg>
          <dl>
            <dt>Most Recent Value</dt>
            <DD theme={theme} component="dd">
              {asset.currentValue ? parseCurrency(parseInt(asset.currentValue)) : "--"}
            </DD>
            <dt>Value Date</dt>
            <DD theme={theme} component="dd">{asset.asOf}</DD>
            <dt>LTV</dt>
            <DD theme={theme} component="dd">{Math.round(asset.ltv * 100)}%</DD>
          </dl>
        </DetailsContainer>

        {/* <DetailsContainer>
          <h3>ATTORNEY/TRUSTEE INFO</h3>
          <dl>
            <dt>Name</dt>
            <dd theme={theme}>{asset.attorneyName ? asset.attorneyName : "N/A"}</dd>
            <dt>Phone</dt>
            <dd theme={theme}>{asset.attorneyPhone ? asset.attorneyPhone : "N/A"}</dd>
            <dt>HAMP?</dt>
            <dd theme={theme}>{formatBooleanToString(asset.hampObligation)}</dd>
          </dl>
        </DetailsContainer> */}
      </ScrollableContent>
    </Container>
  )
}

export default DetailsSidebar;
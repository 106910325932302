import React from "react";
import styled from "@emotion/styled";
import { rgba } from 'emotion-rgba';

const CELL_TRANSPARENCY = 0.8;

const OverdueDate = (props) => {
  const { value } = props;

  if (!value) {
    return <span />
  } 

  const Container = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: black;
    background-color: ${(props) => {
    if (value > 0) {
      return rgba(props.theme.themeColor.brandPrimaryRed, CELL_TRANSPARENCY);
    } else if (value <= 0 && value > -8) {
      return rgba(props.theme.themeColor.brandPrimaryOrange, CELL_TRANSPARENCY);
    } else if (value <= -8) {
      return rgba(props.theme.themeColor.brandPrimaryGreen, CELL_TRANSPARENCY);
    }
  }};
  `

  return (
    <Container>
      { value }
    </Container>
  )
};

export default OverdueDate;
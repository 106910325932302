import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { rgba } from "emotion-rgba";
import React from "react";
import { fullName, getInitials } from "../../helpers";
import InitialsCircle from "../../styled/InitialsCircle/InitialsCircle";
import Typography from "../../styled/Typography/Typography";

const Container = styled(Box)`
  position: absolute;
  right: 10px;
  top: 60px;
  background: ${({theme}) => rgba(theme.themeColor.bodyMain, 0.9)};
  width: 300px;
  z-index: 10;
  color: ${({theme}) => theme.themeColor.sectionStroke};
  font-size: 12px;
`

const TitleRow = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2px 0px;
  background: ${({theme}) => theme.themeColor.bodyMain};
  color: ${({theme}) => theme.themeColor.bodyBackground};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
`

const DetailContent = styled(Box)`
  padding: 10px;
`

const HoldDetails = ({ type, createdBy, description, closeDate, startDate }) => {
  const theme = useTheme();

  const userFullName = fullName(createdBy.firstName, createdBy.lastName);
  const initials = getInitials(userFullName);
  
  return (
    <Container theme={theme}>
      <TitleRow theme={theme}>Hold</TitleRow>
      <DetailContent>
        <Typography fontWeight="500" sx={{mb: 1}}>{name}</Typography>
        {type}
      </DetailContent>
      <TitleRow theme={theme}>Created By</TitleRow>
      <DetailContent>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {userFullName}
          <InitialsCircle fontSize="10px" size="35px" variant="light" initials={initials} fullName={userFullName} />
        </Box>
      </DetailContent>
      <TitleRow theme={theme}>Comment</TitleRow>
      <DetailContent>{description}</DetailContent>
      <TitleRow theme={theme}>Created at</TitleRow>
      <DetailContent>{new Date(+startDate).toLocaleDateString()}</DetailContent>
      <TitleRow theme={theme}>Due by date</TitleRow>
      <DetailContent>{new Date(+closeDate).toLocaleDateString()}</DetailContent>
    </Container>
  )
}

export default HoldDetails;
import { gql } from "@apollo/client";

export const MANAGER_TASKS_QUERY = gql`
  query Query {
    managerTasks {
      tasks {
        id
        title
        activity {
          deleted
          id
          name
          description
          asset {
            id
            loanNumber
          }
        }
        assignments {
          id
          firstName
          lastName
        }
        process {
          deleted
          id
          processType
          asset {
            id
            loanNumber
            activeProcess {
              id
            }
          }
        }
        blocking
        createdAt
        completedAt
        description
        deadlineAt
        milestone
        dueByMilestone
        owner {
          id
          firstName
          lastName
        }
      }

      taskFlows {
        id
        name
        assignments {
          id
          firstName
          lastName
        }
        process {
          deleted
          id
          processType
          asset {
            id
            loanNumber
            activeProcess {
              id
            }
          }
        }
        blocking
        startDate
        completedAt
        dueDate
        milestoneID
        owner {
          id
          firstName
          lastName
        }
        subtasks {
          id
          name
          assignments {
            id
            firstName
            lastName
          }
          process {
            deleted
            id
            asset {
              id
              loanNumber
              activeProcess {
                id
              }
            }
          }
          completedAt
          dueDate
        }
      }
    }
  }
`;

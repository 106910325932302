import { gql } from "@apollo/client";

export const FIND_TEAM_USERS = gql`
  query FindTeamUsers($teamID: ID) {
    findTeamUsers(teamID: $teamID) {
      id
      createdAt
      type
      team {
        name
      }
      user {
        id
        firstName
        lastName
        email
        userType
        blocked
        memberStatus
        dateAccepted
      }
    }
  }
`
import React from 'react';
import { useTheme } from '@emotion/react';
import { Backdrop, CircularProgress } from '@mui/material';
import Button from '../../styled/Button/Button';

export default function ProcessingScreen({ open, label }) {
  const theme = useTheme();

  return (
    <Backdrop
      open={open}
      sx={{
        backdropFilter: "blur(10px)",
        background: `rgba(249, 249, 249, 0.6)`,
        zIndex: '5',
      }}
    >
      <Button
        iconPosition="left"
        disabled
        icon={<CircularProgress size={15} thickness={8} sx={{ color: 'white' }}/>}
        sx={{
          width: '150px',
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          "&.Mui-disabled": {
            background: theme.themeColor.brandPrimaryBlue,
            color: 'white !important',
          }
        }}
      >
        { label ? label : 'Processing' }
      </Button>
    </Backdrop>
  )
}
import { Box, styled } from "@mui/material";
import { rgba } from "emotion-rgba";
import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import TaskDetails from "../MilestoneTasks/TaskDetails";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 71px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
  font-weight: 500;
  font-size: 24px;
`

const Header = ({
  taskName,
  taskDescription,
  dueByMilestone,
  createdAt
}) => {
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  
  return (
    <Container>
      {taskName}
      <FaQuestionCircle style={{cursor: 'pointer'}} onClick={() => setShowTaskDetails(!showTaskDetails)}/>
      {showTaskDetails && (
        <TaskDetails
          name={taskName}
          description={taskDescription}
          dueByMilestone={dueByMilestone}
          createdAt={createdAt}
        />
      )}
    </Container>
  )
}

export default Header;
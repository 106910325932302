import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_MEMBERSHIP } from "../../apollo/mutations/updateMembership";
import { REMOVE_MEMBERSHIP } from "../../apollo/mutations/removeMembership";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import { MessageContext } from "../../context/MessageContext";
import SplitButton from "../../styled/SplitButton/SplitButton";
import Button from "../../styled/Button/Button";

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
`

export default function ActionColumn({ value, onEdit, onSave, onCancel, ...rest }) {
  const [editing, setEditing] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { addMessage } = useContext(MessageContext);

  const [updateMembership] = useMutation(UPDATE_MEMBERSHIP, {
    refetchQueries: "active",
    awaitRefetchQueries: true
  });
  const [removeMembership] = useMutation(REMOVE_MEMBERSHIP, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  })

  const { id, memberID } = rest.data;
  //! TODO REFRESH THE GRID AFTER REMOVING A MEMBER
  const removeMember = async () => {
    try {
      const deleteResponse = await removeMembership({
        variables: { 
          memberID,
          membershipID: id
        },
      });
      if (deleteResponse.errors){
        throw deleteResponse.errors[0].message;
      } else {
        addMessage({ message: "Member removed!" });
        rest.api.refreshCells();
        rest.api.refreshClientSideRowModel();
      }
    } catch(error) {
      addMessage({ message: error, type: "error" })
    }
    setOpenConfirmation(false);
  }

  if (value === 'fr-create') {
    return <Button type="submit" sx={{width: '150px', height: '85%'}}>Add</Button>
  }

  const updateStyles = (editing, finished) => {
    const { data } = rest;

    rest.api.forEachNode((rowNode) => {
      if (data == rowNode.data) {
        if (finished)
          rowNode.setData({ ...data, isEditing: false, needsSave: false })
        else 
          rowNode.setData({ ...data, isEditing: editing });
      }
    })
  }

  const getOptions = () => {
    if (editing) {
      return [
        { label: 'Save', onClick: () => {setEditing(false); onSave(updateMembership, id, rest.data.type); updateStyles(false, true)} },
        { label: 'Cancel', onClick: () => {onCancel(); setEditing(false); updateStyles(false)} },
        { label: 'Remove', onClick: () => setOpenConfirmation(true), color: 'red'},
      ]
    }

    return [
      { label: 'Edit', onClick: () => {setEditing(true); onEdit(rest.data.id); updateStyles(true);} },
      { label: 'Remove', onClick: () => setOpenConfirmation(true), color: 'red'},
    ]
  }

  const labelGenerator = (value) => {
    if (value === 'ACCEPTED') return 'Accepted';
    else if (value === 'PENDING') return 'Pending';
    else if (value === 'REJECTED') return 'Rejected';
    else return "Accepted";
  }
  const colorGenerator = (value) => {
    if (value === 'ACCEPTED') return 'black';
    else if (value === 'PENDING') return 'ltGray';
    else return 'black';
  }

  return (
    <Box>
      <SplitButton
        label={labelGenerator(value)}
        color={colorGenerator(value)}
        items={getOptions(value)}
      />
      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <Typography>Are you sure you want to remove this member?</Typography>

        <ButtonBox>
          <Button onClick={removeMember} sx={{ width: "33%", marginRight: "30px" }}>
            Confirm
          </Button>

          <Button
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
            sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
          >
          Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </Box>
  );
}
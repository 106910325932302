import React from 'react';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
// import { useTheme } from '@emotion/react';

const StyledButton = styled(Button)`
  background-color: gray;
  padding: auto;
  max-width: 35px;
  min-width: 35px;
`

export default function SubscribeButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <NotificationsIcon sx={{color: 'black'}}/>
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Subscribe</MenuItem>
        <MenuItem onClick={handleClose}>Unsubscribe</MenuItem>
      </Menu>
    </div>
  );
}
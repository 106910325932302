import { gql } from "@apollo/client";

export const CLOSE_BK_PROCESS = gql`
  mutation closeBKProcess(
    $processID: ID
    $assetID: ID
    $destinationProcess: DestinationProcess
    $bkStatus: String
    $closeDate: String
  ) {
    closeBKProcess(
      processID:$processID
      assetID:$assetID
      destinationProcess:$destinationProcess
      closeDate:$closeDate
      bkStatus:$bkStatus
    ) {
      id
    }
  }
`
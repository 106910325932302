import React, { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { Timeline, TimelineOppositeContent, TimelineItem, TimelineConnector, TimelineSeparator, TimelineDot, TimelineContent } from '@mui/lab';
import { useQuery } from '@apollo/client';
import { GET_PROCESSES } from '../../apollo/queries/getProcessesFromAsset';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import { useTheme } from '@emotion/react';
import ReferAssetToLawFirm from './ReferAssetToLawFirm';
import { formatProcessType } from '../../utilities';
import AssetDetailsReadOnly from './AssetDetailsReadOnly';
import styled from "@emotion/styled";

const TimelineDate = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color:  ${({theme}) => theme.themeColor.bodyMain};
  font-size: 16px;
`
const Content = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export default function HasDeletedProcess({ open, onClose, asset, setAsset, setActiveProcess }) {
  const [processes, setProcesses] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openReadOnly, setOpenReadOnly] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const theme = useTheme();
  const res = useQuery(GET_PROCESSES, {
    variables: { assetID: asset.id },
  });

  useEffect(() => {
    if (res?.data) {
      const prevProcesses = res.data.getAllProcessesFromAsset;
      setProcesses(prevProcesses.filter((process) => process.stepID !== null && process.deleted));
    }
  }, [res])

  const handleReopenProcess = (process) => {
    setSelectedProcess(process);
    setOpenConfirmation(true);
  }

  const handleViewProcess = (process) => {
    setSelectedProcess(process)
    setOpenReadOnly(true)
  }

  const formatProcesses = (processes) => {
    const sorted = processes.sort((a, b) => b.id - a.id);

    return sorted.map((process, i) => {
      return (
        <TimelineItem id={`closed-process-${process.id}`} key={`process-${i}`}>
          <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ pr: 0 }}>
            {
              process.closeDate
                ? <TimelineDate theme={theme}>Closed on {new Date(+process.closeDate).toLocaleDateString()}</TimelineDate>
                : <TimelineDate theme={theme}>Closed</TimelineDate>
            }
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "75%", pr: 2 }}>
                <Content>
                  <Typography>Process Type:</Typography>
                  <Typography>{formatProcessType(process.processType)}</Typography>
                </Content>
                {process.referral && (
                  <Content>
                    <Typography>Referred to:</Typography>
                    <Typography>{process.referral.team.name}</Typography>
                  </Content>
                )}
                <Content>
                  <Typography>Last milestone completed:</Typography>
                  <Typography number>{process.stepID}</Typography>
                </Content>
                <Content>
                  <Typography>Number of Tasks:</Typography>
                  <Typography number>{process.tasks.length}</Typography>
                </Content>
                {process.holds.length > 0 && (
                  <Content>
                    <Typography>Last Hold Opened:</Typography>
                    <Typography>
                      {process.holds[0].holdType} on {new Date(+process.holds[0].startDate).toLocaleDateString()}
                    </Typography>
                  </Content>
                )}
              </Box>
              <Box sx={{width: "25%", display: 'flex', justifyContent: "flex-end"}}>
                <Button
                  onClick={() => handleViewProcess(process)}
                  sx={{width: '40%', margin: '15px auto'}}
                >
                  View
                </Button>
                <Button
                  onClick={() => handleReopenProcess(process)}
                  sx={{width: '40%', margin: '15px auto'}}
                >
                  Re-Open
                </Button>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
      )
    })
  }

  if (res?.loading) return <CircularProgress />;

  return (
    <Box open={open} onClose={onClose} sx={{pt: 12}}>
      <Box sx={{ display: "flex", justifyContent: "space-between"}}>
        <Typography className="prev-process-notifier" component="h1" variant="h5">
        This asset has previously closed processes.
        </Typography>
        <ArrowDownwardIcon sx={{ mt: 1, verticalAlign: "middle" }}/>
      </Box>

      <Timeline position="right" sx={{padding: 0}}>
        { formatProcesses(processes) }
      </Timeline>

      <ReferAssetToLawFirm
        assets={[{ ...asset, activeProcess: selectedProcess }]}
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        prevProcess={selectedProcess}
        setAsset={setAsset}
        setActiveProcess={setActiveProcess}
      />

      {openReadOnly && 
        <AssetDetailsReadOnly 
          open={openReadOnly}
          onClose={() => setOpenReadOnly(false)}
          asset={asset}
          process={selectedProcess}
        />
      }
    </Box>
  )
}
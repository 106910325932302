import { gql } from "@apollo/client";

export const UPDATE_SUBTASK_MUTATION = gql`
  mutation UpdateSubtask(
    $subtask: updateSubtaskInput!
  ) {
    updateSubtask(input: $subtask) {
      id
    }
  }
`;

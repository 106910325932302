import { gql } from "@apollo/client";

export const REGISTER_PORTFOLIO_USER = gql`
  mutation RegisterPortfolioUser($input: registerPortfolioUserInput!) {
    registerPortfolioUser(input: $input) {
      jwt
      refresh
      user {
        id
      }
    }
  }
`;

import React from "react";
import newLogo from '../../images/newlogo.svg';

const Logo = () => {
  return (
    <div className="logo">
      <img src={newLogo} alt="Logo" width={20} height={20} />
    </div>
  );
};

export default Logo;

import { gql } from "@apollo/client";

export const GET_PORTFOLIO_API_TOKENS = gql`
  query GetPortfolioApiTokens {
    getPortfolioApiTokens {
      createdBy {
        firstName
        lastName
      }
      enabled
      id
      token
      createdAt
      lastUsedAt
    }
  }
`;
  
import React from 'react';
// import Button from '../../styled/Button/Button';
// import TopicIcon from '@mui/icons-material/Topic';
import { FaFolder } from 'react-icons/fa';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
`

const StyledButton = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;

  width: 65px;
  height: 28px;

  background: ${({theme}) => theme.themeColor.backgroundBody};
  color: ${({theme}) => theme.themeColor.bodySecondary};
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  border-radius: 20px;
`

// const StyledIcon = styled(TopicIcon)`
// `

const StyledValue = styled('span')`
  padding-left: 10px;
  font-family: 'IBM Plex Mono';
`

export default function DocumentsButton({ value }) {
  const theme = useTheme();
  
  return (
    <ButtonContainer>
      <StyledButton theme={theme}>
        <FaFolder size="16px" color={theme.themeColor.bodySecondary} /> <StyledValue>{value}</StyledValue>
      </StyledButton>
    </ButtonContainer>
  )
}
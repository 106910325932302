import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const Dot = styled('div')`
  border: ${({theme, color}) => {
  if (!color) return `1px dashed ${theme.themeColor.bodySecondary}`;
  return 'none';
}};
  border-radius: 8px;
  width: 8px;
  height: 8px;

  background: ${(props) => {
  switch (props.color) {
  case 'green':
    return props.theme.themeColor.brandPrimaryGreen;
  case 'red':
    return props.theme.themeColor.brandPrimaryRed;
  default:
    return 'transparent';
  }
}};
  
`

const IsBlocking = ({ value }) => {  
  return (
    <Box sx={{height: '100%', width: "100%", display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
      {value === true && (
        <Dot color="red"/>
      )}
    </Box>
  )
}

export default IsBlocking;
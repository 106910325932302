import React, { memo, useContext } from "react";
import DropFileZone from "./DropFileZone";
import { MessageContext } from "../../context/MessageContext";

async function readFileContent(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = ({ target: { result } }) => resolve(result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

const FORMATS = [
  { name: "csv", mimeType: "text/csv", ext: ".csv" },
  { name: "csv-excel", mimeType: "application/vnd.ms-excel", ext: ".csv" },
];

function AnalyzeAssetsImport({
  analyzeImports,
  isLoading,
  file,
  setData,
  setFile,
  otherSubmit
}) {
  const { addMessage } = useContext(MessageContext);

  const handleFileUpload = async (file) => {
    try {
      const content = await readFileContent(file);
      setData(content);
      setFile(file);
    } catch (err) {
      addMessage({ message: "Unable to read the contents of this file.", type: "error" })
    }
  };

  return (
    <DropFileZone
      style={{margin: '0'}}
      analyzeImports={analyzeImports}
      acceptMimeTypes={FORMATS.map(({ mimeType }) => mimeType)}
      acceptFilesTypes={FORMATS.map(({ ext }) => ext)}
      file={file}
      otherSubmit={otherSubmit}
      isLoading={isLoading}
      onUploadFile={handleFileUpload}
      onUploadError={
        () => addMessage({ message: "Unable to upload this file.", type: "error" })
      }
    />
  )
}

export default memo(AnalyzeAssetsImport);

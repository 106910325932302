import { FaLandmark, FaUsers } from "react-icons/fa";
import AssetDetails from "./pages/AssetDetails";
import Documents from "./pages/Documents";
import Lawfirms from "./pages/Lawfirms";
import Teams from "./pages/Teams";
import Users from "./pages/Users";

const appRoutes = [
  {
    path: "/",
    navbarName: "Assets",
    icon: "search",
    component: AssetDetails,
  },
  {
    path: "/documents",
    navbarName: "Documents",
    reactIcon: FaUsers,
    component: Documents,
    permissions: ["PORTFOLIO_MANAGER", "TEAM_MANAGER", "TEAM_MEMBER"],
  },
  {
    path: "/teams",
    navbarName: "Teams",
    reactIcon: FaLandmark,
    component: Teams,
    permissions: ["PORTFOLIO_MANAGER"],
  },
  {
    path: "/lawfirms",
    navbarName: "Law Firms",
    reactIcon: FaLandmark,
    component: Lawfirms,
    permissions: ["PORTFOLIO_MANAGER"],
  },
  {
    path: "/users",
    navbarName: "Users",
    reactIcon: FaLandmark,
    component: Users,
    permissions: ["TEAM_MANAGER"],
  },
];
export default appRoutes;

import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import Typography from '../../../styled/Typography/Typography';
// import { FaCrosshairs } from 'react-icons/fa';

const StyledContainer = styled(Box)`
  position: absolute;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
`

const DetailsContainer = styled(Box)`
  border-left: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  padding-left: 10px;
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
`

const DetailColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 35%;
`
const DetailRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailNumber = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.themeColor.bodyMain};
`

export default function Overview({
  subStepStats, sideBarStats
}) {
  const theme = useTheme();
  const { cumulativeBudget } = subStepStats;
  const { stepGrossFcDays, netDuration, stepBudgetDays, netVsBudget } = sideBarStats;

  return (
    <StyledContainer theme={theme}>
      <Box sx={{display: 'flex', alignItems: 'center', width: '70%'}}>
        <span style={{marginRight: '24px', fontSize: '14px', fontWeight: '500'}}>Total</span>
        <Typography number sx={{
          fontSize: "16px",
          color: theme.themeColor.bodySecondary,
          lineHeight: "42px",
          fontWeight: "500"
        }}>{cumulativeBudget}</Typography>
      </Box>
      <DetailsContainer theme={theme}>
        <DetailColumn>
          <DetailRow theme={theme}>Gross <DetailNumber number theme={theme}>{stepGrossFcDays}</DetailNumber></DetailRow>
          <DetailRow theme={theme}>Net <DetailNumber number theme={theme}>{netDuration}</DetailNumber></DetailRow>
        </DetailColumn>
        <DetailColumn>
          <DetailRow theme={theme}><DetailNumber number theme={theme}>{stepBudgetDays}</DetailNumber> Budget</DetailRow>
          <DetailRow theme={theme}><DetailNumber number theme={theme}>{netVsBudget}</DetailNumber> I/O</DetailRow>
        </DetailColumn>
      </DetailsContainer>
    </StyledContainer>
  )
}

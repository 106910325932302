import React, { useState, useRef, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USERS_BY_PORTFOLIO_AND_TEAM } from "../../apollo/queries/getUsersByPortfolioAndTeam";
import { UPDATE_TASK_MUTATION } from "../../apollo/mutations/updateTask";
import { UPDATE_TASK_FLOW_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateTaskFlowAndEventsMutation";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import { TextInput } from "../../styled/TextInput/TextInput";
import AssignmentsBadge from "../AssignmentsBadge/AssignmentsBadge";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { useTheme } from "@emotion/react";

const TaskAssigned = ({ value, ...rest }) => {
  const theme = useTheme();
  const { fullTask } = rest.data;
  const anchorRef = useRef(null);
  const { addMessage } = useContext(MessageContext);
  const { portfolioID, teamID, user } = useContext(UserContext);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => {
    setSelectValue(value);
  }, [value])

  const dropdownToggle = (e) => {
    e.stopPropagation();
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const { loading, data, error: loadError } = useQuery(GET_USERS_BY_PORTFOLIO_AND_TEAM, {
    variables: { portfolioID, teamID, assetID: fullTask?.process?.asset?.id || fullTask?.activity?.asset?.id },
  });
  const [updateTask] = useMutation(UPDATE_TASK_MUTATION, {
    refetchQueries: "active"
  });
  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: "active"
  });
  const [updateTaskFlow] = useMutation(UPDATE_TASK_FLOW_AND_EVENTS_MUTATION, {
    refetchQueries: "active"
  });

  const handleTaskUpdate = async (_event, newValue) => {
    setSelectValue([
      ...newValue,
    ]);

    if (newValue.lenght === 0) {
      addMessage({ message: "Please select someone to reassign the task to.", type: "error" });
      return;
    }

    const names = newValue.map(a => `${a.firstName} ${a.lastName}`).join(', ');
    
    const event = {
      type: "task-reassigned",
      dueBy: fullTask.deadlineAt,
      createdDate: new Date(),
      milestoneID: fullTask.milestone,
      process: fullTask.process?.id,
      dueByMilestone: fullTask.dueByMilestone,
      description: `Task ${fullTask.title || fullTask.name} reassigned to ${names}`,
      createdBy: user.id,
    }
    const task = {
      assignments: newValue.map((a) => a.id),
    }
    try {
      if (fullTask["__typename"] === "Task") {
        event.task = fullTask.id;
        const resp = await updateTask({
          variables: {
            task: { where: { id: fullTask.id }, data: task },
          },
        });

        if ('errors' in resp) {
          addMessage({ message: "Something went wrong while updating the task.", type: "error"});
        } else {
          await createEvent({
            variables: {
              event: { data: event }
            }
          });
        }

      } else if (fullTask["__typename"] === 'TaskFlow') {
        const resp = await updateTaskFlow({
          variables: {
            taskFlow: { where: { id: fullTask.id }, data: task },
            event: { data: event },
          },
        });

        if ('errors' in resp) {
          addMessage({ message: "Something went wrong while updating the task.", type: "error"});
        }
      }
    } catch (error) {
      addMessage({ message: 'Unable to reassign task. Please refresh and try again.', type: 'error' })
    }

  }

  if (loading) return <CircularProgress />;
  if (loadError) return <p>There was an error loading some resources. Please refresh the page or try again later.</p>;

  const portfolioUsers = data?.getUsersByPortfolioAndTeam?.length > 0 ? data.getUsersByPortfolioAndTeam : [];

  if (value) {
    return (
      openDropdown
        ? (
          <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
            <Box sx={{ backgroundColor: theme.themeColor.backgroundBody }}>
              <Autocomplete
                multiple
                popupIcon={""}
                sx={{ width: 'auto', height: '80%' }}
                size="small"
                options={portfolioUsers}
                disablePortal
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                onChange={handleTaskUpdate}
                value={selectValue}
                renderInput={(params) => <TextInput {...params}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    "data-testid": "assign-task-to",
                  }}
                  label="Reassign Task"
                />}
              />
            </Box>
          </ClickAwayListener>
        )
        : (
          <Box sx={{display: 'flex', alignItems: 'center', height: '100%', width: "100%"}}>
            <AssignmentsBadge ref={anchorRef} onClick={dropdownToggle} assignments={value} />
          </Box>
        )
    )
  }

  return ''
}

export default TaskAssigned;